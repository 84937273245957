import { PATHS, SUB_PATHS } from '@foxeet/n-core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem, SideMenuItemType } from './top-side-menu.config';

export const userWithCredentialsMenuConfig: MenuItem[] = [
  {
    id: 'users',
    label: 'USERS',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'move_to_inbox',
    link: `/${PATHS.USERS_CREDENTIALS}/${PATHS.USERS}`,
    subMenuId: 'users',
    parentId: 'users',
    authoritiesRoute: 'users-credentials.users',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.USERS_CREDENTIALS}/${PATHS.USERS}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'users',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.USERS_CREDENTIALS}/${PATHS.USERS}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'users',
      },
    ],
  },
  {
    id: 'incompatibilities',
    label: 'incompatibilities',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'move_to_inbox',
    link: `/${PATHS.USERS_CREDENTIALS}/${PATHS.INCOMPATIBILITIES}`,
    subMenuId: 'incompatibilities',
    parentId: 'users',
    authoritiesRoute: 'users-credentials.incompatibilities',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.USERS_CREDENTIALS}/${PATHS.INCOMPATIBILITIES}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'incompatibilities',
        authoritiesRoute: 'incompatibilities',
      },
    ],
  },
];
