import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToDistancePipe } from '../pipes-module/pipes/to-distance.pipe';

@Directive({ selector: '[utilsExtTooltip]' })
export class ExtendedTooltipDirective implements AfterViewInit {
  @Input('utilsExtTooltip') control!: FormControl;

  @Input() stickTo!: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private _distancePipe: ToDistancePipe) {}

  ngAfterViewInit(): void {
    const div = document.createElement('div');
    div.innerHTML = this._distancePipe.transform(this.control.value, '.0-0');
    div.className = 'inner-value';
    this.control.valueChanges.subscribe((value) => {
      const innerDiv = document.getElementsByClassName('inner-value');
      innerDiv[0].innerHTML = this._distancePipe.transform(value, '.0-0');
    });
    this.el = new ElementRef(this.el.nativeElement.querySelector(this.stickTo));
    this.renderer?.appendChild(this.el.nativeElement, div);
  }
}
