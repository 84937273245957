import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { MergedRouteReducerState } from './state';

export interface State {
  router: MergedRouteReducerState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
};

export * from './router.selectors';
export * from './serializer';
