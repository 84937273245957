<div class="container">
  <div class="title-container">
    <h2>
      {{ 'config' | translate }}
    </h2>
  </div>
  <div class="body-container">
    <mat-tab-group>
      <mat-tab [label]="'language' | translate" *ngIf="languages">
        <h3>{{ 'select_language_title' | translate }}</h3>

        <div class="row" id="AT_languages_container">
          <div
            class="col-3 lang"
            [ngClass]="{ selected: item.cultureCodeLanguage === formGroup.get('cultureCodeLanguage')?.value }"
            *ngFor="let item of languages"
            (click)="selectLanguage(item)"
          >
            <mat-icon class="flag" svgIcon="{{ item.img }}"></mat-icon>
            <p>{{ item.translateKey | translate }}</p>
          </div>
        </div>
      </mat-tab>

      <mat-tab [label]="'currency' | translate" *ngIf="currencies">
        <p *ngIf="showWarning" class="span-error">{{ 'select_currency_warning' | translate }}</p>
        <h3>{{ 'select_currency' | translate }}</h3>

        <div class="row" id="AT_currencies_container">
          <div
            class="col-3 currency"
            [ngClass]="{ selected: item.cultureCodeCurrency === formGroup.get('cultureCodeCurrency')?.value }"
            *ngFor="let item of currencies"
            (click)="selectCurrency(item)"
          >
            <p>{{ item.value }}</p>
            <p>{{ item.symbol }} - {{ item.code }}</p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="footer-container">
    <div class="p-grid">
      <ui-async-button
        componentId="AT_closeButton"
        class="p-col"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        [size]="'md'"
        [label]="'close' | translate"
        [loadingLabel]="'closing' | translate"
        (clickEvent)="closeEventTrigger()"
      ></ui-async-button>

      <ui-async-button
        componentId="AT_confirmButton"
        class="p-col"
        [asyncButton]="false"
        [buttonClassByType]="'primary'"
        [size]="'md'"
        [label]="'save' | translate"
        [loadingLabel]="'confirming' | translate"
        (clickEvent)="save()"
        [inputState]="stateButton"
      ></ui-async-button>
    </div>
  </div>
</div>
