import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LicenseeDashboardFilterModel } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AdminService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'SuperAdmin', cache);
  }

  public getLicenseesListPaged<T>(page: number, size: number, body?: any): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    body = removeNullsAndUndefined(body);
    return this.post<typeof body, T>(`LicenseesListPaged`, body, { observe: 'response', params });
  }

  public getLicenseesGroupByPlan<T>(page: number, size: number, body?: any): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    body = removeNullsAndUndefined(body);
    return this.post<typeof body, T>(`LicenseesGroupByPlan`, body, {
      observe: 'response',
      params,
    });
  }

  public getAppraisalLicenseesGroupByPlan<T>(page: number, size: number, body?: any): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    body = removeNullsAndUndefined(body);
    return this.post<typeof body, T>(`AppraisalLicenseesGroupByPlan`, body, {
      observe: 'response',
      params,
    });
  }

  licenseesExcelSummary(body: LicenseeDashboardFilterModel) {
    return this.post(`LicenseesExcelSummary`, body, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  licenseesAppraisalsExcelSummary() {
    return this.post(`LicenseesAppraisalsExcelSummary`, null, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
