import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MenuItem, mobileMenuConfig, topMenuConfig } from '../domain/top-side-menu.config';
import { userWithCredentialsMenuConfig } from '../domain/user-with-credentials-menu.config';

@Injectable({ providedIn: 'root' })
export class TopSideMenuService {
  private _parentModuleId: string | undefined;
  public parentModuleId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>('');

  private _parentSubModuleId: string | undefined;
  public parentSubModuleId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>('');

  public sideMenuActiveId$: BehaviorSubject<string> = new BehaviorSubject('');

  public topMenuList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(topMenuConfig);

  public userWithCredentialsMenuConfig$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(userWithCredentialsMenuConfig);

  public activeSideMenu$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  public itemListForMobile$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(mobileMenuConfig);

  constructor(private _router: Router) {}

  setActiveSection(item: MenuItem) {
    if (item.link) {
      if (item.parentId) {
        if (this._parentModuleId !== item.parentId || this._parentSubModuleId !== item.id) {
          this.replaceIdsAndNavigate(item.parentId, item.id, item.link);
        }
      } else {
        if (this._parentModuleId !== item.id || this._parentSubModuleId !== undefined) {
          this.replaceIdsAndNavigate(item.id, undefined, item.link);
        }
      }
    }
  }

  replaceIdsAndNavigate(parentModuleId: string, parentSubModuleId: string | undefined, link: string) {
    this._router.navigateByUrl(link).then((canNavigate) => {
      // TODO: review. It returns null when canActivate is not implemented
      if (canNavigate !== false) {
        this.parentModuleId$.next(parentModuleId);
        this.parentSubModuleId$.next(parentSubModuleId);
        this._parentModuleId = parentModuleId;
        this._parentSubModuleId = parentSubModuleId;
      }
    });
  }

  setSectionList(parentId: string, sectionId: string, items: MenuItem[]) {
    this._parentModuleId = parentId;
    this._parentSubModuleId = sectionId;
    this.activeSideMenu$.next(items);
  }

  clearActiveIndex() {
    this._parentModuleId = undefined;
    this.parentModuleId$.next(this._parentModuleId);
    this._parentSubModuleId = undefined;
    this.parentSubModuleId$.next(this._parentSubModuleId);
  }

  clearMenuEntries() {
    this.activeSideMenu$.next([]);
  }
}
