import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { BaseService, HttpConfig } from './base.service';
import { CacheParams, CacheService } from './cache.service';
import { OrNullOrUndef } from '@foxeet/domain';
import { isNilOrEmptyString } from '../to-delete/bucket';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { Observable } from 'rxjs';

export abstract class ApiBaseService2 extends BaseService {
  protected apiBranch = '';

  protected constructor(http: HttpClient, apiBranch: string, cache?: CacheService) {
    super(http, cache);
    this.apiBranch = apiBranch;
  }

  protected setUrl(url?: string): string {
    return !isNilOrEmptyString(url) ? `${this.apiBranch}/${url}` : this.apiBranch;
  }

  protected setParams(values: { [param: string]: OrNullOrUndef<string | number | boolean | ReadonlyArray<string | number | boolean>> }): HttpParams {
    return new HttpParams({ fromObject: removeNullsAndUndefined(values) as any });
  }

  override get<T>(url: string, config?: HttpConfig, cache?: CacheParams) {
    return this.simpleCallRequest<T>('GET', this.setUrl(url), config, cache);
  }

  override post<A, B>(url: string, body?: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('POST', this.setUrl(url), body, config, cache);
  }

  postMultipartFile<T>(url: string, body: FormData, params?: HttpParams) {
    return this.settingCallRequest<FormData, T>('POST', this.setUrl(url), body, {
      params,
      responseType: 'json',
      reportProgress: true,
    });
  }

  putMultipartFile<T>(url: string, body: FormData, params?: HttpParams): Observable<HttpEvent<T>> {
    const req = new HttpRequest('PUT', url, body, { params, responseType: 'json', reportProgress: true });
    return this.http.request<T>(req);
  }

  override put<A, B>(url: string, body?: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('PUT', this.setUrl(url), body, config, cache);
  }

  override patch<A, B>(url: string, body?: A, config?: HttpConfig, cache?: CacheParams) {
    return this.settingCallRequest<A, B>('PATCH', this.setUrl(url), body, config, cache);
  }

  override delete<T>(url: string, config?: HttpConfig, cache?: CacheParams) {
    return this.simpleCallRequest<T>('DELETE', this.setUrl(url), config, cache);
  }
}
