import {
  ExpenseTypes,
  AssetSubTypologies,
  AssetSurfaceIds,
  AssetTypologies,
  AssetUses,
  DynamicResidualFreeRateTypes,
  PeriodTypes,
  ResidenceTypes,
  TerrainExpenseCalculationTypes,
  ValorationMethods,
  ValidationTypes,
  OfferTypes,
  CashflowPeriodActionType,
  GroundRepercussionCalculateMethods,
} from '../enums';
import { ValorationComparisonMethodModel } from './valoration-comparable-method.model';
import { ValorationOtherMethodModel } from './valoration-other-method.model';
import { OrNull } from '../interfaces';
import { AppraisalReportMarketStudyItemModel } from './market-study.model';
import { FoxeetKeyValueModel } from './extra-information.model';

export interface ValorationCahsflowCacheDto {
  tempId: string;
  periods: ValorationCashflowPeriodDto[];
  semesterPeriods: ValorationCashflowPeriodDto[];
  annualPeriods: ValorationCashflowPeriodDto[];
}

export interface TerrainExpenseDto {
  id: string;
  isManual: boolean;
  expenseType: ExpenseTypes;
  expenseTypeDescription: string;
  calculationType: TerrainExpenseCalculationTypes;
  terrainExpenseCalculationValue: number;
  totalConstructionCost: number;
  totalNetIncome: number;
  description: string;
  calculatedValue: number;
  updatedValue: number;
  cashflowPercentTotalValue: number;
  cashflowUnitTotalValue: number;
}

export interface TerrainIncomeDto {
  id: string;
  buildingElementTempId: string;
  use: AssetUses;
  typology: AssetTypologies;
  subtypology: AssetSubTypologies;
  residenceType: ResidenceTypes;
  buildeable: number;
  valorationUnitValue: number;
  calculatedValue: number;
  updatedValue: number;
  developerProfitPercentage: number;
  developerProfitPercent: number;
  description: string;
  incomeType: string;
  value: number;
  cashflowPercentTotalValue: number;
  cashflowUnitTotalValue: number;
}

export interface ValorationCashflowPeriodDto {
  id: string;
  periodId: number;
  periodStartDateTime: string;
  periodFinishDateTime: string;
  description: string;
  periodType: PeriodTypes;
  periodTotalNetIncome: number;
  periodTotalNetExpense: number;
  periodTotalNetExpenseDiff: number;
  periodUpdateMonthlyRatePercentage: number;
  periodUpdateMonthlyRatePercent: number;
  periodTotalUpdatedIncome: number;
  periodTotalUpdatedExpense: number;
  _periodTotalUpdatedExpenseDiff: number;
  periodTotalUpdatedExpenseDiff: number;
  incomesDistribution: ValorationCashflowPeriodDistributionDto[];
  expensesDistribution: ValorationCashflowPeriodDistributionDto[];
}

export interface ChangesValorationCashflowPeriodDto extends ValorationCashflowPeriodDto {
  actionType: CashflowPeriodActionType;
}

export interface ValorationDynamicResidualMethodModel {
  tempId: string;
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  isFutureValoration: boolean;
  buildingElements: BuildingElementItemResidualModel[];
  terrainIncomesObservations: string;
  terrainExpensesObservations: string;
  buildingElementsObservations: string;
  terrainIncomes: TerrainIncomeDto[];
  terrainExpenses: TerrainExpenseDto[];
  totalNetIncome: number;
  totalNetExpense: number;
  totalIncomeExpenseDiff: number;
  riskFreePercent: number;
  riskPercentage: number;
  appliedPremiumPercent: number;
  appliedPremiumPercentage: number;
  ipcPercent: number;
  ipcPercentage: number;
  freeRateType: DynamicResidualFreeRateTypes;
  freeRateConstantPercent: number;
  riskPremiumWeighted: number;
  externalFinancingIncreasePercent: number;
  externalFinancingIncreaseJustification: string;
  updateRatePercent: number;
  updateRatePercentage: number;
  updateMonthlyRatePercent: number;
  updateMonthlyRatePercentage: number;
  startDatetime: string;
  finishDatetime: string;
  constructionStartDate: string;
  constructionFinishDate: string;
  salesStartDate: string;
  salesFinishDate: string;
  periods: ValorationCashflowPeriodDto[];
  semesterPeriods: ValorationCashflowPeriodDto[];
  annualPeriods: ValorationCashflowPeriodDto[];
  periodModificated: PeriodTypes;
  periodsTotalNetIncome: number;
  periodsTotalNetExpense: number;
  periodsTotalIncomeExpenseDiff: number;
  totalUpdateIncome: number;
  totalUpdateExpense: number;
  totalUpdateIncomeExpenseDiff: number;
  totalIncomesUpdatedValue: number;
  totalExpensesUpdatedValue: number;
  isWorkOnHold: boolean;
  distributionValues: AssetWorkPlanStateDistributionValuesDto[];
  dynamicResidualUnitValue: number;
  lastChangesInPeriods: ChangesValorationCashflowPeriodDto[];
  lastChangesInSemesterPeriods: ChangesValorationCashflowPeriodDto[];
  lastChangesInAnnualPeriods: ChangesValorationCashflowPeriodDto[];
  groundRepercussionCalculateMethod: GroundRepercussionCalculateMethods;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}

export interface BuildingElementItemResidualModel {
  id: number;
  tempId: string;
  valorationId: number;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  offerType?: OfferTypes;
  registeredPropertyNumber: string;
  name: string;
  residenceType: ResidenceTypes;
  isVPO: OrNull<boolean>;
  riskPremiumPercentage: number;
  riskPremiumPercent: number;
  developerProfitPercentage: number;
  developerProfitPercent: number;
  changeObservations: string;
  isManualEditing: boolean;
  buildeable: number;
  surfaceId: AssetSurfaceIds;
  constructionCostsUnitPrice: number;
  weightPercent: number;
  weightPercentage: number;
  repercussion: number;
  valorationData_DepreciationFunctionalTotalValue: number;
  valorationData_GroundRepercussionUnitValue: number;
  valorationData_SumGroundTotalPrice: number;
  valorationData_SumLegalMaximumLimitationGroundTotalValue: number;
  valorationsTotalCompletedPercentage: number;
  valorationsRequiredCompletedPercentage: number;
  valorationsTotalCompletedPercent: number;
  isValorationsTotalCompleted: boolean;
  valorationsRequiredCompletedPercent: number;
  isValorationsRequiredCompleted: boolean;
  valorationMethodAdopted: ValorationMethods;
  valorationMethodAdoptedString: string;
  valorationValue: number;
  valorationTotalAdoptedSurface: number;
  valorationUnitValue: number;
  insuranceValorationValueByRoyalDecree: number;
  insuranceValorationValueByEco: number;
  isRevalorationNeeded: boolean;
  latitude: number;
  longitude: number;
  valorationMethodAdoptedId: number;
  workPlanStateDistributionId: number;
  isWorkOnHold: boolean;
  valorationComparisonMethod: OrNull<ValorationComparisonMethodModel>;
  valorationOtherMethod: OrNull<ValorationOtherMethodModel>;
  appraisalWithEco8052003?: boolean;
  marketStudy: AppraisalReportMarketStudyItemModel;
  canModifyMarketStudy: boolean;
}

export interface ValorationCashflowPeriodDistributionDto {
  id: string;
  periodId: number;
  notMonthlyPeriodId: number;
  totalValueOfElement: number;
  isPercentageValue: boolean;
  value: number;
  calculatedPercentageValue: number;
  calculatedPercentValue: number;
  calculatedUnitValue: number;
  description: string;
  isRecurrent: boolean;
}

export interface AssetWorkPlanStateDistributionValuesDto {
  name: string;
  valorationValue: number;
  valorationData_GroundRepercussionUnitValue: number;
  residualDynamicRelatedValue: number;
}
