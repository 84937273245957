import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AssetCreateModel, CadastralResponse, CatastroAssetLocalizationModel } from '@foxeet/domain';
import { CacheService } from '../services/cache.service';
import { ApiBaseService2 } from '../services/api-base.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';

@Injectable()
export class CadastralService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Catastro', cache);
  }

  getAssetDataByCadastralReference(cadastralReference: string): Observable<HttpResponse<AssetCreateModel>> {
    const params = new HttpParams().append('cadastralReference', cadastralReference);
    return this.http.get<AssetCreateModel>(`${this.apiBranch}/InformationByCadastralReference`, {
      observe: 'response',
      params,
    });
  }

  getAssetDataByLocation(location: CatastroAssetLocalizationModel): Observable<HttpResponse<CadastralResponse>> {
    const body = removeNullsAndUndefined(location);
    return this.http.post<CadastralResponse>(`${this.apiBranch}/GetCadastralReferencesByDirection`, body, { observe: 'response' });
  }

  getAssetDataByBuildingCadastralReference(buildingCadastralRef: string): Observable<HttpResponse<CadastralResponse>> {
    const params = new HttpParams().append('buildingCadastralReference', buildingCadastralRef);
    return this.http.get<CadastralResponse>(`${this.apiBranch}/CadastralReferencesByBuildingCadastralReference`, {
      observe: 'response',
      params,
    });
  }
}
