export enum InfoTypes {
  innerHTML = 0,
  simpleMessage = 1,
}

export enum InfoAlignment {
  default = 0,
  center = 1,
}

export interface InfoModalModel {
  name: string;
  title?: string;
  message: string;
  infoType?: InfoTypes;
  alignment?: InfoAlignment;
  copy?: boolean;
}
