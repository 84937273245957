import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-stars-raiting',
  templateUrl: './stars-raiting.component.html',
  styleUrls: ['./stars-raiting.component.scss'],
})
export class StartsRaitingCellComponent implements OnInit {
  public itemTooltip: string;
  public id: number;

  @Input() data: any = {};
  @Input() header: any = {};

  ngOnInit(): void {
    this.id = this.data.id + this.header.id;
  }
}
