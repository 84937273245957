import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIBaseService } from '@foxeet/utils/services/API/api-base.service';

@Injectable({ providedIn: 'root' })
export class PlatformInterceptor implements HttpInterceptor {
  constructor(private _apiBaseService: APIBaseService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this._addPlatform(request));
  }

  private _addPlatform(request: HttpRequest<any>): HttpRequest<any> {
    return this._apiBaseService ? request.clone({ headers: request.headers.set('X-LoginPlatform', `${this._apiBaseService.config.platform}`) }) : request;
  }
}
