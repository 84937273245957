/**
 * @todo review these models
 */
export interface OrderRequestVisitProposalItemModel {
  AllDay: boolean;
  VisitProposalFromDate: Date;
  VisitProposalFromTime: string;
  VisitProposalUntilTime: string;
}

export interface OrderVisitDataModel {
  Name: string;
  PhoneNumber: string;
  Email: string;
  Observation: string;
  VisitProposals: OrderRequestVisitProposalItemModel[];
}

export interface VisitProposalDataModel {
  visitProposalFromDateTime: string;
  visitProposalUntilDateTime: string;
  allDay: boolean;
}
