import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PATHS } from '@foxeet/n-core';
import { License } from '@foxeet/domain';
import { MyWorkModal, ProfileMenuItem, TopSideMenuService } from '@foxeet/ui';
import { AuthService } from '../../providers/auth.service';
import { UnsuscriptionHandler, getFileFromPath, getURI } from '@foxeet/utils/*';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'feature-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
// TODO: Make it generic passing as Input a config object with label, icon and callback (Review every project)
export class ProfileMenuComponent extends UnsuscriptionHandler implements OnInit {
  @Input() userName!: string;
  @Input() avatar!: string;
  @Input() menuItems: ProfileMenuItem[] = [];
  @Input() licenseList: License[] = [];
  @Output() logout: EventEmitter<any> = new EventEmitter();

  constructor(private _authService: AuthService, private _router: Router, private _topSideMenuService: TopSideMenuService, public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    if (this.avatar) {
      getFileFromPath(this.avatar)
        .pipe(takeUntil(this._componentDestroyed))
        .subscribe((val) => {
          this.avatar = getURI(val);
        });
    }
  }
  public onLogout() {
    this.logout.emit();
  }

  public selectLicense(license: License) {
    if (this._authService.isOnlyLicenseeAppraiserById(license.id)) {
      this.openDialog(license.id);
    } else {
      this._authService.selectLicense(license.id, () => this._topSideMenuService.parentModuleId$.next('orders'));
    }
  }

  openDialog(licenseeId: number) {
    this.dialog.open(MyWorkModal, {
      minHeight: '250px',
      width: '700px',
      data: { licenseeId: licenseeId },
    });
  }

  public goToAccountManagement(license: License) {
    this._topSideMenuService.clearActiveIndex();
    this._authService.selectLicense(license.id, () => this._topSideMenuService.parentModuleId$.next('orders'), `/${PATHS.ACCOUNT_MANAGEMENT}`);
  }
}
