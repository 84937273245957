import { AssetSurfaceIds, AssetTypologies, AssetUses, ValorationMethods } from '../enums';
import { AssetSurfaceData } from './assets.model';
import { BuildingElementItemResidualModel, TerrainExpenseDto, TerrainIncomeDto, ValorationCashflowPeriodDto } from './valoration-dynamic-residual-method.model';

export interface WorkflowItemReportAssetValorationStaticResidualMethodModel {
  buildingElements: BuildingElementItemResidualModel[];
  terrainIncomes: TerrainIncomeDto[];
  terrainExpenses: TerrainExpenseDto[];
  totalNetIncome: number;
  totalNetExpense: number;
  totalIncomeExpenseDiff: number;
  staticResidualTotalValue: number;
  valorationId: number;
  workflowItemAssetId: number;
  workflowItemAssetClusterId: number;
  workflowItemAssetValorationBuildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  periods: ValorationCashflowPeriodDto[];
  totalUpdateIncome: number;
  totalUpdateExpense: number;
  totalUpdateIncomeExpenseDiff: number;
  periodsTotalNetIncome: number;
  periodsTotalNetExpense: number;
  periodsTotalIncomeExpenseDiff: number;

  // ADDED ONLY FOR RESIDUAL DYNAMIC - NECESSARY FOR TERRAIN?
  totalIncomesUpdatedValue: number;
  totalExpensesUpdatedValue: number;
}

export interface AppraisalReportAssetClusterTerrainInitialDetailsModel {
  id: number;
  name: string;
  isRevalorationNeeded: boolean;
  valorationMethodAdopted: ValorationMethods;
  valorationId: number;
  latitude: number;
  longitude: number;
}

export interface AssetInTerrainClusterIndexModel {
  id: number;
  use: AssetUses;
  typology: AssetTypologies;
  valorationValue: number;
  valorationUnitValue: number;
  valorationTotalAdoptedSurface: number;
  valorationAdoptedSurfaceId: AssetSurfaceIds;

  clusterId: number;
  cadastralSurfaces: AssetSurfaceData;
  registeredSurfaces: AssetSurfaceData;
  checkedSurfaces: AssetSurfaceData;
  assetIdentification: number;
}

export interface AppraisalReportAssetClusterTerrainEditModel {
  id: number;
  name: string;
  valorationId: number;
  valorationMethodAdopted: ValorationMethods;
  valorationValue: number;
  isTerrainCluster: boolean;
  assets: AssetInTerrainClusterIndexModel[];
}

export interface AppraisalReportAssetClusterTerrainModel extends AppraisalReportAssetClusterTerrainEditModel {
  isRevalorationNeeded: boolean;
  latitude: number;
  longitude: number;
}

// Nuevos modelos para cluster
export interface WorkflowItemAssetClusterTerrainEditModel {
  id: number;
  name: string;
  workflowItenAssetIds: number[];
}
