import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ExpandablePanel } from '../../../domain/model';

@Component({
  selector: 'ui-expandable-filters-button',
  templateUrl: './expandable-filters-button.component.html',
  styleUrls: ['./expandable-filters-button.component.scss'],
})
export class ExpandableFiltersButtonComponent implements OnInit {
  public isOpen = false;
  public showFiltersLabel = '';
  public hideFiltersLabel = '';
  public faFilter = faFilter;

  @Input() expandablePanel!: ExpandablePanel;
  @Output() toggleDropwdown: EventEmitter<void> = new EventEmitter();

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.showFiltersLabel = this.translate.instant('show_filters');
    this.hideFiltersLabel = this.translate.instant('hide_filters');
  }

  toggle() {
    this.expandablePanel.opened = !this.expandablePanel.opened;
  }
}
