import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputTripleSwitchComponent } from './form-input-triple-switch.component';

@NgModule({
  declarations: [FormInputTripleSwitchComponent],
  imports: [CommonModule],
  exports: [FormInputTripleSwitchComponent],
})
export class FormInputTripleSwitchModule {}
