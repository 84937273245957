import { EntityTypes } from '../enums';

// TODO: replace every reference by RequesterCompanyIndexModel
// and check if it's correct
export interface PrescriptorIndexModel {
  Code: string;
  PrescriptorType: EntityTypes;
  RequesterCompanyId: number;
  Name: string;
  ThumbnailFileName: string;
  ThumbnailFileWebPath: string;
  NationalIdentityDocument: string;
}
