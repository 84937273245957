export interface AppraisalSummary {
  orderType: number;
  purpose: number;
  visitType: number;
  reportType: number;
  vinculationType?: number;
  withEco: boolean;
  totalValue: number;
  visitDateTime: Date;
  requesterName: string;
  hasAntecedents: boolean;
  customerName: string;
  editPuposeText: boolean;
  purposeText: string;
  executionGrade?: number;
  showGradeModal: boolean;
}
