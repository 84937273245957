<p-treeTable
  [value]="dataSource?.entitySubject | async"
  [columns]="tableConfig?.columns"
  [selectionMode]="selectionMode"
  [(selection)]="selectedNodes"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect($event)"
>
  <ng-template pTemplate="header" let-columns let-rowNode>
    <th *ngFor="let col of columns; let i = index">
      <p-treeTableHeaderCheckbox (click)="selectAllNodes($event)" *ngIf="i === 0 && selectionMode === 'checkbox'"></p-treeTableHeaderCheckbox>
      {{ col.name | translate }}
    </th>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr [ttSelectableRow]="rowNode">
      <td *ngFor="let col of columns; let i = index" [class]="col?.columnId">
        <!-- NO ELIMINAR POR SI DA FALLO -->
        <!-- <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0" [ngClass]="{ disabled: !canCollapse }"></p-treeTableToggler> -->
        <p-treeTableCheckbox
          [value]="rowNode"
          *ngIf="i === 0 && selectionMode === 'checkbox'"
          [disabled]="!!rowNode.parent"
          [ngClass]="{ hidden: !!rowNode.parent }"
        ></p-treeTableCheckbox>
        <ng-container [ngSwitch]="col?.cellType">
          <ng-container *ngSwitchCase="tableCellType.PIPE">
            {{ rowData[col.columnId] | dynamicPipe: col.pipe:col.keysPipe | translate: col.internationalizationType }}
          </ng-container>
          <ng-container *ngSwitchCase="tableCellType.SPAN"> {{ rowData[col.columnId] | translate: col.internationalizationType | capitalize }} </ng-container>
          <ng-container *ngSwitchCase="tableCellType.BOOLEAN">
            <fa-icon *ngIf="rowData[col.columnId]" class="success" [icon]="faCheck" size="md"></fa-icon>
            <fa-icon *ngIf="!rowData[col.columnId]" class="error" [icon]="faTimes" size="md"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'ERROR_LIST'">
            <span class="error-list">
              <ul>
                <li *ngFor="let error of rowNode.node[col.columnId]">{{ error.label | translate }}</li>
              </ul>
            </span>
          </ng-container>
          <ng-container *ngSwitchDefault> {{ rowNode.node | json }} </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
