import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-multiselect',
  templateUrl: './form-input-multiselect.component.html',
  styleUrls: ['./form-input-multiselect.component.scss'],
})
export class FormInputMultiselectComponent extends BaseFormComponent<void> implements OnInit {
  public opened = false;
  public selected: string[] = [];
  public filterBy = '';

  public optionList: SelectItem[] = [];
  public labelResume = '';

  @Input() options = new BehaviorSubject<unknown[]>([]);

  @Input() labelKeys: string[] = [];
  @Input() indexBy = 'value';

  @Input() appendToBody = false;
  @Input() shouldDisplayFilter = true;
  @Input() shouldVirtualScroll = true;

  constructor(private _translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    // TODO: No sé si esto es necesario
    // this.filterBy = this.labelKeys ? (this.labelKeys.includes('translateKey') ? 'label' : this.labelKeys.map((el) => `fullItem.${el}`).join(',')) : '';
    this.options.pipe(takeUntil(this._componentDestroyed)).subscribe((options: any[]) => {
      this.optionList = this._toMultiselectorModel(options, this.indexBy, this.labelKeys);
      this.checkControlValueToUpdateLabel();
    });
  }

  private _toMultiselectorModel(options: any[] = [], indexBy: string, labelKeysToJoin: string[] = []) {
    return options.map((option) => ({
      label: labelKeysToJoin.map((key) => this._translateService.instant(option[key])).join(' '),
      value: option[indexBy],
      fullItem: option,
    }));
  }

  private checkControlValueToUpdateLabel() {
    this.form
      ?.get(this.controlName)
      ?.valueChanges.pipe(
        takeUntil(this._componentDestroyed),
        filter((selectedValues) => !!selectedValues),
      )
      .subscribe((selectedValues) => this.getLabelResume(selectedValues));

    const value = this.form?.get(this.controlName)?.value;
    if (value) {
      this.getLabelResume(value);
    }
  }

  public getLabelResume(selectedItems: string) {
    this.labelResume = this.optionList
      .filter((el) => selectedItems.includes(el.value))
      .map((el) => this._translateService.instant(el.label ?? ''))
      .join(', ');
  }
}
