import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CultureSelectors, CultureSetted } from '@foxeet/chests/culture';
import { InternationalizationTypes } from '@foxeet/domain';
import { Store } from '@ngrx/store';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { isNilOrEmptyString } from '@foxeet/utils/functions';

@Pipe({ name: 'translate', pure: false })
export class InternationalizationPipe extends TranslatePipe implements PipeTransform, OnDestroy {
  private subs = new Subscription();
  private _cultureSetted: CultureSetted | null = null;

  constructor(protected _translateService: TranslateService, protected _changeDetectorRef: ChangeDetectorRef, private store: Store) {
    super(_translateService, _changeDetectorRef);
    this.subs.add(
      this.store.select(CultureSelectors.CultureSetted).subscribe((values) => {
        this._cultureSetted = values;
      }),
    );
  }

  public override transform(value: string, type?: InternationalizationTypes, args = {}): string {
    let customArg = {};
    switch (type) {
      case InternationalizationTypes.Currency:
        customArg = { currency: isNilOrEmptyString(this._cultureSetted?.reportCurrency) ? this._cultureSetted?.currency : this._cultureSetted?.reportCurrency };
        break;
      case InternationalizationTypes.UnitLength:
        customArg = { unitLength: this._cultureSetted?.unitLength };
        break;
      case InternationalizationTypes.SurfaceUnit:
        customArg = { surfaceUnit: this._cultureSetted?.surfaceUnit };
        break;
      case InternationalizationTypes.CurrencyPerSurfaceUnit:
        customArg = {
          currency_per_surfaceUnit: isNilOrEmptyString(this._cultureSetted?.reportCurrencyPerSurfaceUnit)
            ? this._cultureSetted?.currencyPerSurfaceUnit
            : this._cultureSetted?.reportCurrencyPerSurfaceUnit,
        };
        break;
    }
    return super.transform(value, { ...args, ...customArg });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subs.unsubscribe();
  }
}
