import { TranslateService } from '@ngx-translate/core';
import { dynamicInjector, isNil, isNilOrEmptyString } from '@foxeet/utils/functions';
import { CellClassParams, HeaderValueGetterParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

const translateService: TranslateService = dynamicInjector()?.get(TranslateService);

const getCellStatusClass = (params: CellClassParams, checkStatusFrom?: string[]) => {
  const { colDef, node, context, data } = params;
  if (colDef && node && !isNil(node.id)) {
    const controlName = colDef?.field;

    const isEditable: boolean | undefined = typeof colDef?.editable === 'function' ? colDef?.editable(params as any) : colDef?.editable;
    const form = <FormGroup>context.formArray.controls[node?.id];

    const formGroup = form.get('data') ? form.get('data') : form.get('valorationData');

    if (controlName === 'surface') {
      return AgGridFunctions.getAssetSurfaceStatusClass(params);
    } else if (['groundRepercussionAdoptedUnitValue'].includes(controlName) && !formGroup?.get('marketStudy')) {
      return AgGridFunctions.setCellStatusAsReadOnly(params, true);
    } else if (controlName === 'file') {
      return AgGridFunctions.setCellStatusAsReadOnlyButton(params, true);
    } else {
      if (controlName) {
        let someControlInvalid = formGroup?.get(controlName)?.invalid;
        let someControlDisabled = formGroup?.get(controlName)?.disabled;
        if (checkStatusFrom?.length) {
          someControlInvalid = checkStatusFrom.some((controlName) => formGroup?.get(controlName)?.invalid);
          someControlDisabled = checkStatusFrom.some((controlName) => formGroup?.get(controlName)?.disabled);
        }

        const hasError = data?.rowErrors?.errors && data?.rowErrors?.errors.length && data?.rowErrors?.errors.find((e) => e.columnName === controlName);

        return someControlDisabled || !isEditable ? 'disabled' : someControlInvalid || hasError ? 'invalid' : '';
      } else {
        return !isEditable && formGroup?.disabled ? 'disabled' : '';
      }
    }
  }

  return '';
};

const hasParent = ({ data }: CellClassParams) => {
  return data?.parentId ? 'hasParent' : '';
};

export class AgGridFunctions {
  static translateKey = (key: string) => {
    const translateService: TranslateService = dynamicInjector()?.get(TranslateService);
    return key ? translateService.instant(key) : key;
  };
  static getTranslatedHeader = ({ colDef }: HeaderValueGetterParams): string => {
    const translateService: TranslateService = dynamicInjector()?.get(TranslateService);

    return colDef.headerName ? translateService.instant(colDef.headerName) : colDef.headerName;
  };

  static dropdownFilterValueFormatter = (params: ValueFormatterParams, options$: BehaviorSubject<any[]> | Observable<any[]>) => {
    const { colDef, value } = params;
    const enumName: string | undefined =
      typeof colDef?.cellRendererParams === 'function' ? colDef?.cellRendererParams({ data: null })?.enumName : colDef?.cellEditorParams?.enumName;

    let text = '';
    options$.subscribe((options) => {
      text = options.find((el) => +el.value === +value)?.label;
    });

    return enumName ? translateService.instant(`${enumName}_${value}`) : !isNil(value) ? translateService.instant(`${text}`) : translateService.instant('emptyValue');
  };

  static hashValueGetter(params: ValueGetterParams) {
    return params.node ? params.node.id : null;
  }

  static getClasses(params: any) {
    return `${getCellStatusClass(params)} ${hasParent(params)}`;
  }

  static getCellStatusClass(params: CellClassParams, checkStatusFrom?: string[]) {
    return getCellStatusClass(params, checkStatusFrom);
  }

  static getAssetSurfaceStatusClass = (params: CellClassParams) => {
    const { context, node } = params;

    const parentFormGroup = <FormGroup>context.formArray.controls[node?.id];
    const formGroup = parentFormGroup.get('valorationData') as FormGroup;

    return formGroup.get('assetSurfaceId')?.disabled ? 'disabled' : formGroup.get('assetSurfaceId')?.enabled && isNil(formGroup.get('assetSurfaceId')?.value) ? 'invalid' : '';
  };

  static setCellStatusAsReadOnly = (params, checkValue = false) => {
    const { context, node, colDef } = params;
    const controlName = colDef?.field;

    const parentFormGroup = <FormGroup>context.formArray.controls[node?.id];
    const formGroup = parentFormGroup.get('valorationData') as FormGroup;

    const invalid = checkValue ? isNilOrEmptyString(formGroup.get(controlName)?.value) : formGroup.get(controlName)?.invalid;

    return formGroup.disabled ? 'disabled' : invalid ? 'invalid' : '';
  };
  static setCellStatusAsReadOnlyButton = (params, checkValue = false) => {
    const { context, node, colDef } = params;
    const controlName = colDef?.field;

    const parentFormGroup = <FormGroup>context.formArray.controls[node?.id];
    const formGroup = parentFormGroup.get('valorationData') as FormGroup;

    const invalid = formGroup.get(controlName)?.invalid;

    return formGroup.disabled ? 'disabled' : invalid ? 'invalid' : '';
  };

  static errorSetter = (params: ValueSetterParams) => {
    const name = params.colDef.field;
    const errors = params.data.errors;

    if (!isNil(name) && !isNil(errors)) {
      if (params.oldValue !== params.newValue && !isNil(params.data.errors[name])) {
        params.data.errors[name] = '';
      }
    }
    return params.newValue;
  };
}
