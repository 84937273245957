export enum DatePipeFormats {
  SPANISH = 'es',
  ENGLISH = 'en',
  PORTUGUESE = 'pt',
}

export enum DatePipeFormatValue {
  SPANISH = 'yyyy-MM-dd HH:mm',
  ENGLISH = 'yyyy-MM-dd HH:mm',
  PORTUGUESE = 'yyyy-MM-dd HH:mm',
}

export enum SanitizerTypes {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCE_URL = 'resourceUrl',
}
