import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';
import { setValue } from '../form-input-triple-switch.utils';

@Component({
  selector: 'ui-form-input-triple-switch',
  templateUrl: './form-input-triple-switch.component.html',
  styleUrls: ['./form-input-triple-switch.component.scss'],
})
export class FormInputTripleSwitchComponent extends BaseFormComponent<boolean | null> implements OnInit {
  setValue = setValue;
}
