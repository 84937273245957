import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

export enum Modules {
  MANAGEMENT_ORDERS = 'management-orders',
  SIMPLE_NOTES = 'simple-notes',
  APPRAISALS = 'appraisals',
  QUALITY_CONTROL = 'quality-control',
  FINANCIAL = 'financial',
  PAYMENT_SUMMARY = 'payment-summary',
  CODE_MANAGEMENT = 'code-management',
  CUSTOMER = 'customers',
  PROFESSIONALS = 'professionals',
  PROCESS_MANAGER_USER = 'process-manager-user',
  TECHNICAL_MANAGER_USER = 'technical-manager-user',
  COMMERCIAL_MANAGER_USER = 'commercial-manager-user',
  REQUESTER_COMPANIES = 'requester-companies',
  WIKI = 'wiki',
  ACTIVES = 'actives',
  INCOMPATIBILITIES = 'incompatibilities',
  MY_PROFILE = 'my-profile',
  ACADEMY = 'academy',
  REQUESTS = 'requests',
  ACCOUNT_MANAGEMENT = 'account-management',
  USERS_CREDENTIALS = 'users-credentials',
  PARTICIPANTS = 'participants',
  ORDERS = 'orders',
  ENTITIES = 'entities',
  USERS = 'users',
  ADMIN = 'admin',
  MY_WORK = 'my-work',
  MARKET_STUDIES = 'market-studies',
  GLOBAL_SEARCH = 'global-search',
}

@Injectable({ providedIn: 'root' })
export class AuthoritiesMiddlewareService {
  private _authoritiesDefinition!: any;
  public relatedToAppraisalDetail$ = new BehaviorSubject<boolean>(false);

  constructor(private _authService: AuthService) {}

  protected _recursiveActionSearch(actionPath: string, currentObj: any, property: string): any {
    const [currentPath, ...rest] = actionPath.split('.');
    const newObj = currentObj[currentPath];
    return rest?.length ? this._recursiveActionSearch(rest.join('.'), newObj, property) : Array.isArray(newObj) ? newObj : newObj[property] || [];
  }

  /**
   * @param actionPath example: 'simple_notes.pages.list.access'
   */
  public canAccess(actionPath: string): boolean {
    return this.checkIfHasAllowedRoles(actionPath, 'access');
  }

  public canEnable(actionPath: string): boolean {
    return this.checkIfHasAllowedRoles(actionPath, 'enable');
  }

  public checkIfHasAllowedRoles(actionPath: string, property: string): boolean {
    const allowedRoles = this._recursiveActionSearch(actionPath, this._authoritiesDefinition, property);
    const rolesToCheck = this.getRolesArray();
    const isAllowed = rolesToCheck.some((r) => allowedRoles.includes(r));
    return isAllowed;
  }

  public getCurrentPathAuthorities(pathId: string) {
    const paths = pathId.split('.');
    return paths.reduce((acc, curr) => ({ ...acc[curr] }), this._authoritiesDefinition);
  }

  public canActivateModuleById(pathId: string): boolean {
    const currentPathAuthorities = this.getCurrentPathAuthorities(pathId);
    const rolesToCheck = this.getRolesArray();
    const canAccess = rolesToCheck.some((r) => currentPathAuthorities.access.includes(r));
    return canAccess;
    // return true;
  }

  getRolesArray() {
    /* @todo provisional. relatedToAppraisalDetail$ -> Observable para saber si se está navegando dentro del detalle de la tasación y coger las authorities del modelo AppraisalModel
     * Motivo: que las authorities de este modelo no afecten a las authorities de otros módulos a los que se quiere navegar desde appraisal.
     */
    const hasAppraisalAuthorities = !!this._authService.authoritiesByCurrentAppraisal$.value?.length && this.relatedToAppraisalDetail$.value;
    return hasAppraisalAuthorities ? this._authService.authoritiesByCurrentAppraisal$.value : this._authService.authorities$.value;
  }

  public setAuthoritiesObject(authoritiesObj: any) {
    this._authoritiesDefinition = authoritiesObj;
  }

  public getAuthoritiesObject() {
    return this._authoritiesDefinition;
  }
}
