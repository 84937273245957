import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailMenuImageComponent } from './detail-menu-image.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DetailMenuImageComponent],
  imports: [CommonModule, MatIconModule],
  exports: [DetailMenuImageComponent],
})
export class DetailMenuImageModule {}
