import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ui-plan-container',
  templateUrl: './plan-container.component.html',
  styles: [
    `
      .disabled-item {
        opacity: 0.65;
        pointer-events: none;
      }
      .badge {
        background-color: var(--primary-color);
        color: white;
        padding: 0 0.5rem;
        border-radius: 10px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
      }
    `,
  ],
})
export class PlanContainerComponent {
  @Input() authorityRoute: string;
  @Input() template: TemplateRef<any>;
  @Input() label: string;
}
