import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import { AppraiserInvoiceModel } from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraiserInvoicesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraiserInvoices', cache);
  }

  // TODO: Maybe not should be PUT method.
  confirmById(id: number, invoiceNumberId: string) {
    const body = { id, invoiceNumberId };
    return this.put<typeof body, void>(`${id}/Confirm`, body);
  }

  // TODO: Maybe not should be PUT method.
  rejectById(id: number, reasonMessage: string) {
    const body = { id, reasonMessage };
    return this.put<typeof body, void>(`${id}/Reject`, body);
  }

  // TODO: Maybe not should be PUT method.
  sendToRevisionById(id: number) {
    return this.put<null, void>(`${id}/SendToRevision`, null);
  }

  // TODO: Maybe not should be PUT method.
  resetByIdWithParams(id: number, year: number, month: number, keepState: boolean, userId: number) {
    return this.put<null, AppraiserInvoiceModel>(`${id}/Reset`, null, {
      params: this.setParams({
        year,
        month,
        userId,
        keepState,
      }),
    });
  }

  // TODO: Maybe not should be PUT method.
  resetAllInMonthAndYear(year: number, month: number, keepState: boolean, userId: number): Observable<AppraiserInvoiceModel> {
    return this.put<null, AppraiserInvoiceModel>(`ResetAllInMonthAndYear`, null, {
      params: this.setParams({
        year,
        month,
        userId,
        keepState,
      }),
    });
  }

  // TODO: Maybe not should be PUT method.
  sendToRevisionInMonthAndYear(year: number, month: number) {
    return this.put<null, AppraiserInvoiceModel>(`SendToRevisionInMonthAndYear`, null, {
      params: this.setParams({
        year,
        month,
      }),
    });
  }

  getPdfByInvoiceId(id: number) {
    return this.http.get(`${this.apiBranch}/${id}/pdf`, { observe: 'response', responseType: 'blob' });
  }

  getZipConfirmedInvoices(year: number, month: number) {
    let params: HttpParams = new HttpParams();
    params = params.append('year', `${year}`);
    params = params.append('month', `${month}`);
    return this.get<Blob>(`ZipConfirmedInvoices`, {
      params,
      responseType: 'blob',
    });
  }

  getExcelSummaryInvoices(year: number, month: number) {
    return this.get<Blob>(`ExcelSummaryInvoices`, {
      params: this.setParams({
        year,
        month,
      }),
      responseType: 'blob',
    });
  }
}
