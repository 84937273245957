import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ASYNC_BUTTON_STATE } from '@foxeet/ui';
import { PASSWORD } from '@foxeet/utils/forms';
import { checkIfFieldsAreEqual, customRegexValidator } from '@foxeet/utils/validators';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AuthService } from '../../providers';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { PATHS, SUB_PATHS } from '@foxeet/n-core';

@Component({
  selector: 'feature-change-temporal-password',
  templateUrl: './change-temporal-password.component.html',
  styleUrls: ['./change-temporal-password.component.scss'],
})
export class ChangeTemporalPasswordComponent implements OnInit, OnDestroy {
  private readonly _mobileQueryListener: () => void;
  public mobileQuery: MediaQueryList;

  public temporalPasswordChangeForm!: FormGroup;
  public changeTemporalPasswordButtonState: Subject<ASYNC_BUTTON_STATE> = new Subject();

  getErrorMessage = (formControl: FormControl | FormGroup | AbstractControl): string => {
    return formControl.hasError('required')
      ? this._translateService.instant('required')
      : formControl.hasError('notSame')
      ? this._translateService.instant('CHANGE_TEMPORAL_PASSWORD_passwordsNotMatch')
      : formControl.hasError(PASSWORD.errorKey)
      ? this._translateService.instant('FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH')
      : '';
  };

  constructor(
    private _router: Router,
    private _media: MediaMatcher,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastrWrapperService: ToastrWrapperService,
    private _translateService: TranslateService,
  ) {
    this.mobileQuery = this._media.matchMedia('(max-width: 780px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }

  ngOnInit() {
    this.changeTemporalPasswordButtonState.next(ASYNC_BUTTON_STATE.IDLE);

    this.temporalPasswordChangeForm = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, customRegexValidator(PASSWORD.pattern, PASSWORD.errorKey)]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      checkIfFieldsAreEqual('password', 'confirmPassword'),
    );
  }

  public changeTemporalPassword() {
    if (this.temporalPasswordChangeForm.valid) {
      this.changeTemporalPasswordButtonState.next(ASYNC_BUTTON_STATE.SENDING);

      const password = this.temporalPasswordChangeForm.get('password')?.value;
      const confirmPassword = this.temporalPasswordChangeForm.get('confirmPassword')?.value;

      this._authService
        .changeTemporalPassword(password, confirmPassword)
        .pipe(
          take(1),
          finalize(() => this.changeTemporalPasswordButtonState.next(ASYNC_BUTTON_STATE.IDLE)),
        )
        .subscribe(() => {
          if (this._authService.isAuthenticated()) {
            const isCustomer = this._authService.isCustomer();
            const redirectionUrl = isCustomer ? `${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.LIST}` : this._authService.getAndClearFromUrl() || '/';
            this._router.navigate([redirectionUrl]);
          }
        }, this._toastrWrapperService.errorHandler);
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }
}
