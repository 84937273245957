// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

import { InjectionToken } from '@angular/core';
import { ConfigLoaderServiceOptions } from '../config-loader.service';

// I define the shape of the optional configuration data passed to the forRoot() method.
export interface ModuleOptions {
  [key: string]: unknown;
}

export interface ConfigLoaderModuleOptions<T = any, K = any> extends ModuleOptions {
  environment?: any;
  languagesConfig?: any;
  authorities?: { [prop: string]: any };
  data?: K;
}

// I am the token that makes the raw options available to the following factory function.
// --
// NOTE: This value has to be exported otherwise the AoT compiler won't see it.
export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<ConfigLoaderModuleOptions>('forRoot() MyService configuration.');

// I translate the given raw OPTIONS into an instance of the MyServiceOptions TYPE. This
// will allows the MyService class to be instantiated and injected with a fully-formed
// configuration class instead of having to deal with the Inject() meta-data and a half-
// baked set of configuration options.
// --
// NOTE: This value has to be exported otherwise the AoT compiler won't see it.
export function provideMyServiceOptions<T = any, K = any>(options?: ConfigLoaderModuleOptions<T, K>): ConfigLoaderServiceOptions<T, K> {
  return new ConfigLoaderServiceOptions<T, K>(options ?? ({} as ConfigLoaderModuleOptions<T, K>));
}
