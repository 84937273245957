import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthoritiesMiddlewareService } from '../providers/authorities.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[utilsShowIfHasAuthorities]' })
export class ShowIfHasAuthoritiesDirective implements OnInit {
  @Input() utilsShowIfHasAuthorities!: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _authoritiesMiddlewareService: AuthoritiesMiddlewareService) {}

  ngOnInit() {
    if (this._authoritiesMiddlewareService.canAccess(this.utilsShowIfHasAuthorities)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
