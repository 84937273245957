import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ASYNC_BUTTON_STATE } from '@foxeet/ui';
import { PASSWORD } from '@foxeet/utils/forms';
import { checkIfFieldsAreEqual, customRegexValidator } from '@foxeet/utils/validators';
import { Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ToastrWrapperService } from '@foxeet/utils/services/toastr-wrapper.service';
import { AuthService } from '../../providers';
import { OrNull } from '@foxeet/domain';
import { isNil } from '@foxeet/utils/functions';

@Component({
  selector: 'feature-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.scss'],
})
export class PasswordRestoreComponent implements OnInit {
  private _mobileQueryListener: () => void;
  public mobileQuery: MediaQueryList;

  public passwordChangeForm!: FormGroup;
  public restorePasswordButtonState: Subject<ASYNC_BUTTON_STATE> = new Subject();

  private _paramEmail: OrNull<string> = '';
  private _paramCode: OrNull<string> = '';

  constructor(
    private _router: Router,
    private _media: MediaMatcher,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastrWrapperService: ToastrWrapperService,
  ) {
    this.mobileQuery = this._media.matchMedia('(max-width: 780px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }

  ngOnInit() {
    this.passwordChangeForm = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, customRegexValidator(PASSWORD.pattern, PASSWORD.errorKey)]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      checkIfFieldsAreEqual('password', 'confirmPassword'),
    );

    this._route.queryParamMap.subscribe((queryParams) => {
      this._paramCode = queryParams.get('code');
      this._paramEmail = queryParams.get('email');
    });
  }

  public restoreThePassword() {
    if (this.passwordChangeForm.valid) {
      this.restorePasswordButtonState.next(ASYNC_BUTTON_STATE.SENDING);

      const password = this.passwordChangeForm.get('password')?.value;
      const confirmPassword = this.passwordChangeForm.get('confirmPassword')?.value;

      if (isNil(this._paramEmail) || isNil(this._paramCode)) {
        console.error('_paramEmail:', this._paramEmail, '_paramCode:', this._paramCode);
        return;
      }

      this._authService
        .restoreThePassword(this._paramEmail, this._paramCode, password, confirmPassword)
        .pipe(
          take(1),
          finalize(() => this.restorePasswordButtonState.next(ASYNC_BUTTON_STATE.IDLE)),
        )
        .subscribe((result) => {
          if (result.ok) {
            this._router.navigate(['/login']);
          }
        }, this._toastrWrapperService.errorHandler);
    }
  }
}
