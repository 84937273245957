import { APPRAISAL_REPORT_ASSET_PATHS, APPRAISAL_REPORT_CLUSTER_PATHS, SUB_PATHS } from '@foxeet/n-core';
import { AssetTabs } from '../../enums/backend.enums';
import { AppId } from '../../enums/frontend.enums';
import { BehaviorSubject } from 'rxjs';

// TODO: import from core
interface ReportTabItem {
  link: string;

  completedKey?: string;
}

export const generateMobileRoutesByAssetTabsEnum = (app?: AppId): Map<AssetTabs, ReportTabItem> => {
  const defaultMap = new Map([
    [AssetTabs.CommonData_LocalityEnvironmentMarket, { link: APPRAISAL_REPORT_ASSET_PATHS.LOCALITYENVIRONMENT }],
    [AssetTabs.CommonData_BuildingInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDINGFEATURES }],
    [AssetTabs.CommonData_TerrainInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.TERRAINFEATURES }],
    [AssetTabs.CommonData_UrbanisticSituation, { link: APPRAISAL_REPORT_ASSET_PATHS.URBANISTICSITUATION }],
    [AssetTabs.CommonData_TerrainUrbanisticSituation, { link: APPRAISAL_REPORT_ASSET_PATHS.URBANISTICSITUATION_TERRAIN }],
    [AssetTabs.CommonData_Checks, { link: APPRAISAL_REPORT_ASSET_PATHS.CHECKS }],
    [AssetTabs.CommonData_ConditionalsWarningsObservations, { link: APPRAISAL_REPORT_ASSET_PATHS.CONDITIONINGFACTORS }],
    [AssetTabs.CommonData_Workstate, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDING_STATE }],
    [AssetTabs.CommonData_ExploitationDescription, { link: APPRAISAL_REPORT_ASSET_PATHS.EXPLOITATION_DESCRIPTION }],
    [AssetTabs.CommonData_RusticEstateInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.RUSTIC_ESTATE_FEATURES }],
    [AssetTabs.CommonData_TerrainSurfacesAndDescription, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDINGTERRAIN }],

    [AssetTabs.Report_Identification, { link: APPRAISAL_REPORT_ASSET_PATHS.IDENTIFICATION }],
    [AssetTabs.Report_Localization, { link: APPRAISAL_REPORT_ASSET_PATHS.LOCATION }],
    [AssetTabs.Report_Typology, { link: APPRAISAL_REPORT_ASSET_PATHS.TYPOLOGY }],
    [AssetTabs.Report_Surfaces, { link: APPRAISAL_REPORT_ASSET_PATHS.SURFACES }],
    [AssetTabs.Report_Workstate, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDING_STATE }],
    [AssetTabs.Report_LocalityEnvironmentMarket, { link: APPRAISAL_REPORT_ASSET_PATHS.LOCALITYENVIRONMENT }],
    [AssetTabs.Report_TerrainSurfacesAndDescription, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDINGTERRAIN }],
    [AssetTabs.Report_BuildingInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.BUILDINGFEATURES }],
    [AssetTabs.Report_StayAndFacilitiesInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.PROPERTYFEATURES }],
    [AssetTabs.Report_UrbanisticSituation, { link: APPRAISAL_REPORT_ASSET_PATHS.URBANISTICSITUATION }],
    [AssetTabs.Report_TerrainUrbanisticSituation, { link: APPRAISAL_REPORT_ASSET_PATHS.URBANISTICSITUATION_TERRAIN }],
    [AssetTabs.Report_Tenures, { link: APPRAISAL_REPORT_ASSET_PATHS.POSSESSION }],
    [AssetTabs.Report_Checks, { link: APPRAISAL_REPORT_ASSET_PATHS.CHECKS }],
    [AssetTabs.Report_Valorations, { link: APPRAISAL_REPORT_ASSET_PATHS.VALUATIONS }],
    [AssetTabs.Report_ConditionalsWarningsAndObservations, { link: APPRAISAL_REPORT_ASSET_PATHS.CONDITIONINGFACTORS }],
    [AssetTabs.Report_Photos, { link: APPRAISAL_REPORT_ASSET_PATHS.PHOTOS }],
    [AssetTabs.Report_Documents, { link: APPRAISAL_REPORT_ASSET_PATHS.DOCUMENTATION }],
    [AssetTabs.Report_TerrainInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.TERRAINFEATURES }],
    [AssetTabs.Report_ExploitationDescription, { link: APPRAISAL_REPORT_ASSET_PATHS.EXPLOITATION_DESCRIPTION }],
    [AssetTabs.Report_RusticEstateInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.RUSTIC_ESTATE_FEATURES }],

    [AssetTabs.CommonDataVisit_LocalityEnvironmentMarket, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_ENVIRONMENT }],
    [AssetTabs.CommonDataVisit_BuildingInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_BUILDING_INFO }],
    [AssetTabs.CommonDataVisit_TerrainInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.TERRAINFEATURES }],
    [AssetTabs.CommonDataVisit_RusticEstateInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.RUSTIC_ESTATE_FEATURES }],

    [AssetTabs.Visit_Typology, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_TYPOLOGY }],
    [AssetTabs.Visit_Surfaces, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_SURFACE_FACILITIES_STAYS }],
    [AssetTabs.Visit_TerrainInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_TERRAIN_FEATURES }],
    [AssetTabs.Visit_BuildingInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_BUILDING_INFO }],
    [AssetTabs.Visit_Photos, { link: APPRAISAL_REPORT_ASSET_PATHS.PHOTOS }],
    [AssetTabs.Visit_Documents, { link: APPRAISAL_REPORT_ASSET_PATHS.DOCUMENTATION }],
    [AssetTabs.Visit_LocalityEnvironmentMarket, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_ENVIRONMENT }],
    [AssetTabs.Visit_SurfacesAndPropertyFeatures, { link: APPRAISAL_REPORT_ASSET_PATHS.VISIT_SURFACE_FACILITIES_STAYS }],
    [AssetTabs.Visit_RusticEstateInformation, { link: APPRAISAL_REPORT_ASSET_PATHS.RUSTIC_ESTATE_FEATURES }],
    [AssetTabs.CommonData_MarketStudies, { link: APPRAISAL_REPORT_ASSET_PATHS.MARKET_STUDIES }],
  ]);

  return defaultMap;
};

export const mobileRoutesClusterTabsEnum: Map<AssetTabs, ReportTabItem> = new Map([
  [AssetTabs.Cluster_Details, { link: APPRAISAL_REPORT_CLUSTER_PATHS.DETAIL }],
  [AssetTabs.Cluster_Workstate, { link: SUB_PATHS.BUILDING_STATE }],
  [AssetTabs.Cluster_Valorations, { link: APPRAISAL_REPORT_CLUSTER_PATHS.VALUATIONS }],
]);

export interface StepPercentage {
  label: string;
  key: string;
}

export interface StepExtra {
  icon: string;
  key: string;
  count?: number;
}

export interface Step {
  id: string;
  tabId: AssetTabs;
  order: number;
  name: string;
  link: string;
  isActive: boolean;
  isVisible: BehaviorSubject<boolean>;
  isDisabled: BehaviorSubject<boolean>;
  isComplete: boolean;
  completedPercentage: number;
  isRequiredComplete: boolean;
  requiredCompletedPercentage: number;
  existAlerts?: boolean;
  hasErrorAlerts?: boolean;
  extras?: StepExtra[];
}
