import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicConfigLoaderService, ErrorDialogService } from '@foxeet/utils-modules';
import { WebAsyncButtonModule } from '../../../async-button';
import { OrUndef } from '@foxeet/domain';
import { takeUntil } from 'rxjs/operators';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { BACK_CONFIG, BackConfig } from '@foxeet/data-access';

@Component({
  selector: 'ui-debug-modal',
  templateUrl: './debug-modal.component.html',
  styleUrls: ['./debug-modal.component.scss'],
})
export class DebugModalComponent extends UnsuscriptionHandler implements OnInit {
  public formGroup: FormGroup;
  public defaultEndpoint: string;
  public newEndpoint: OrUndef<string>;
  public isProd = true;

  @ViewChild('closeButton', { static: true }) closeButton!: WebAsyncButtonModule;
  @ViewChild('confirmButton', { static: true }) confirmButton!: WebAsyncButtonModule;

  constructor(
    public dialogRef: MatDialogRef<DebugModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown,
    @Inject(BACK_CONFIG) private backConfig: BackConfig,
    private _dynamicConfigLoaderService: DynamicConfigLoaderService,
    private _errorDialogService: ErrorDialogService,
    private _formBuilder: FormBuilder,
  ) {
    super();
    this.defaultEndpoint = this._dynamicConfigLoaderService.Options.environment.defaultEndpoint;
    this.isProd = this._dynamicConfigLoaderService.Options.environment.production;

    const localStorageItem = localStorage.getItem('checkEnableModalError') ?? 'true';
    this.formGroup = this._formBuilder.group({
      newEndpoint: this._formBuilder.control(null, [Validators.required, Validators.minLength(1)]),
      checkEnableModalError: new FormControl(!this.isProd && localStorageItem && JSON.parse(localStorageItem)),
    });
  }

  ngOnInit() {
    this.formGroup
      .get('checkEnableModalError')
      ?.valueChanges.pipe(takeUntil(this._componentDestroyed))
      .subscribe((checkEnableModalError) => (this._errorDialogService.enabled = checkEnableModalError));
  }

  public setEndpoint(id: string) {
    switch (id) {
      case 'dev':
        this.newEndpoint = this._dynamicConfigLoaderService.Options.environment.devEndpoint;
        break;
      case 'qa':
        this.newEndpoint = this._dynamicConfigLoaderService.Options.environment.qaEndpoint;
        break;
      case 'prod':
        this.newEndpoint = this._dynamicConfigLoaderService.Options.environment.prodEndpoint;
        break;
      default:
        this.newEndpoint = id;
        break;
    }
  }

  closeEventTrigger() {
    this.dialogRef.close();
  }

  confirmEventTrigger() {
    if (this.newEndpoint) {
      this._dynamicConfigLoaderService.Options.environment.defaultEndpoint = this.newEndpoint;
      this.backConfig.baseUrl.next(this.newEndpoint);
    }
    localStorage.setItem('checkEnableModalError', JSON.stringify(this.formGroup.get('checkEnableModalError')?.value));
    this.dialogRef.close();
  }
}
