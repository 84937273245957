import { BillingData } from './billing-data.model';

// // TODO: Import user.model.ts and extend this models with the user.model.ts ones

export interface ExternalWorkerEditBillingDataModel extends BillingData {
  id?: number;
  ivaPercentage?: number;
  irpfPercentage: number;
  billingTaxType: number;
  automaticInvoicing: boolean;
}
