import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingDataModel, RequesterCompanyEditPartnerDataModel, RequesterCompanyModel } from '@foxeet/domain';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { CacheService } from '../services/cache.service';

@Injectable()
export class RequesterCompaniesService extends WorkflowService {
  constructor(http: HttpClient, cache?: CacheService) {
    super(http, 'RequesterCompanies', cache);
  }

  getPartnerData(userId: number): Observable<RequesterCompanyEditPartnerDataModel> {
    return this.get<RequesterCompanyEditPartnerDataModel>(`${userId}/PartnerData`);
  }

  editPartnerData(body: RequesterCompanyEditPartnerDataModel): Observable<RequesterCompanyEditPartnerDataModel> {
    return this.put<typeof body, RequesterCompanyEditPartnerDataModel>(`${body.id}/PartnerData`, body);
  }

  usersListPagedByRequesterCompanyId<T>(customerId: string, page: number, pagesize: number, body: any): Observable<T> {
    return this.post<typeof body, T>(`${customerId}/Users/ListPaged`, body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  current(): Observable<RequesterCompanyModel> {
    return this.get<RequesterCompanyModel>(`Current`);
  }

  getBillingData(id: any): Observable<BillingDataModel> {
    return this.get<BillingDataModel>(`${id}/BillingData`);
  }
}
