import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PatternsErrorKeys } from '@foxeet/domain';

@Component({ template: '' })
export abstract class BaseFormValidationMessagesComponent {
  @Input() control!: FormControl;

  public inputValidationErrors = PatternsErrorKeys;

  public getErrorValue(key: string): any {
    return this.control.getError(key);
  }

  displayEmail = (): boolean => this.generalDisplayError(PatternsErrorKeys.EMAIL);
  displayEmailValidation = (): boolean => this.generalDisplayError(PatternsErrorKeys.EMAIL_VALIDATION_ERROR);
  displayEmailAltValidation = (): boolean => this.generalDisplayError(PatternsErrorKeys.EMAIL_VALIDATION_ERROR_ALT);
  displayPatternValidation = (): boolean => this.generalDisplayError(PatternsErrorKeys.PATTERN);
  displayBudgetNumberValidation = (): boolean => this.generalDisplayError(PatternsErrorKeys.BUDGET_NUMBER_MASK_ERROR);
  displayMaxInputError = (): boolean => this.generalDisplayError(PatternsErrorKeys.MAX);
  displayMinInputError = (): boolean => this.generalDisplayError(PatternsErrorKeys.MIN);
  displayMaxLengthError = (): boolean => this.generalDisplayError(PatternsErrorKeys.MAX_LENGTH);
  displayMinLengthError = (): boolean => this.generalDisplayError(PatternsErrorKeys.MIN_LENGTH);
  displayPatternError = (): boolean => this.generalDisplayError(PatternsErrorKeys.PATTERN);
  displayRequiredError = (): boolean => this.generalDisplayError(PatternsErrorKeys.REQUIRED);
  displayCustomerAlreadyExistError = (): boolean => this.generalDisplayError(PatternsErrorKeys.CUSTOMER_ALREADY_EXISTS);
  displayNotSameError = (): boolean => this.generalDisplayError(PatternsErrorKeys.NOT_SAME);
  displayMaxAdditionError = (): boolean => this.generalDisplayError(PatternsErrorKeys.MAX_ADDITION);
  displayAphanumericError = (): boolean => this.generalDisplayError(PatternsErrorKeys.ALPHANUMERIC);
  displayPassNotMatchError = (): boolean => this.generalDisplayError(PatternsErrorKeys.FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH);
  displayMinAndMaxError = (): boolean => this.generalDisplayError(PatternsErrorKeys.NUMERIC_RANGE);

  protected generalDisplayError(key: PatternsErrorKeys | string): boolean {
    return this.control?.getError(key);
  }
}
