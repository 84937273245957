<div>
  <ng-container *ngIf="header.columnId === 'executionGrade'">
    <div *ngIf="data.executionGrade >= 0; else iconContainer" class="d-flex align-items-center">
      <p>{{ data.executionGrade | DecimalFormatterPipe : null : null : '.1-2' }}</p>
      <div class="stars-container">
        <ui-stars-rating [id]="id" [score]="data.executionGrade"></ui-stars-rating>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="header.columnId === 'deadlineGrade'">
    <div *ngIf="data.deadlineGrade >= 0; else iconContainer" class="d-flex align-items-center">
      <p>{{ data.deadlineGrade | DecimalFormatterPipe : null : null : '.1-2' }}</p>
      <div class="stars-container">
        <ui-stars-rating [id]="id" [score]="data.deadlineGrade"></ui-stars-rating>
      </div>
    </div>
  </ng-container>
  <ng-template #iconContainer>
    <mat-icon> remove </mat-icon>
  </ng-template>
</div>
