import { AssetSurfaceIds, AssetUses, DynamicResidualFreeRateTypes, LegalSituationTypes, PeriodTypes, ValorationMethods } from '../enums';
import { ValorationCashflowPeriodDto } from './valoration-dynamic-residual-method.model';
import { EconomicExploitationExpenseDto, EconomicExploitationIncomeDto } from './valoration-economic-exploitation-method.model';

export interface ValorationRusticEstateMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  isFutureValoration: boolean;
  incomesObservations: string;
  expensesObservations: string;
  incomes: EconomicExploitationIncomeDto[];
  expenses: EconomicExploitationExpenseDto[];
  riskFreePercent: number;
  riskPercentage: number;
  appliedPremiumPercent: number;
  appliedPremiumPercentage: number;
  ipcPercent: number;
  ipcPercentage: number;
  freeRateType: DynamicResidualFreeRateTypes;
  freeRateConstantPercent: number;
  externalFinancingIncreasePercent: number;
  externalFinancingIncreaseJustification: string;
  updateRatePercent: number;
  updateRatePercentage: number;
  updateMonthlyRatePercent: number;
  updateMonthlyRatePercentage: number;
  startDatetime: string;
  finishDatetime: string;
  assetUse: AssetUses;
  legalSituation: LegalSituationTypes;

  //Periods
  periods: ValorationCashflowPeriodDto[];
  semesterPeriods: ValorationCashflowPeriodDto[];
  annualPeriods: ValorationCashflowPeriodDto[];
  periodModificated: PeriodTypes;

  // Totals summary table
  periodsTotalNetIncome: number;
  periodsTotalNetExpense: number;
  periodsTotalIncomeExpenseDiff: number;
  totalUpdateIncome: number;
  totalUpdateExpense: number;
  totalUpdateIncomeExpenseDiff: number;

  // Totals incomes and expenses tables
  totalNetIncome: number;
  totalNetExpense: number;
  totalIncomesUpdatedValue: number;
  totalExpensesUpdatedValue: number;

  isWorkOnHold: boolean;
  costMethod_ConstructionTotalCost: number;
  costMethod_NecessaryConstructionExpensesTotalPrice: number;
  costMethod_GroundTotalPrice: number;
  capitalGainPercent: number;
  reversionValue: number;
  updatedReversionValue: number;
  totalValue: number;

  isStartDateTimeEdited: boolean;
  datesObservations: string;
  isCapitalGainPercentEdited: boolean;
  capitalGainObservations: string;
  isReversionValueEdited: boolean;
  reversionValueObservations: string;

  selectedValorationValue: number;
  selectedValorationMethod: ValorationMethods;
  selectedValorationId: number;
  totalIncomeExpenseDiff: number;
  capitalGainPercentage: number;

  valorationDatetime: string;
}
