import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNil } from '@foxeet/utils/functions';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ActionsTypes, ImageCropperOptions, ImageCropperTransformConfig } from '../domain/image-cropper-customizer.model';
import { isUndefined } from 'lodash';

export const defaultCropperOptions: ImageCropperOptions = {
  containWithinAspectRatio: false,
  maintainAspectRatio: true,
  resizeToWidth: 256,
  cropperMinWidth: 128,
  onlyScaleDown: true,
};

export const defaultImageCropperTransformConfig: ImageCropperTransformConfig = {
  scale: 1,
  minScale: 0.1,
  maxScale: 3,
  stepScale: 0.1,
  minRotate: -45,
  maxRotate: 45,
  stepRotate: 1,
  rotate: 0,
  flipH: false,
  flipV: false,
  canvasRotation: 0,
};

@Component({
  selector: 'ui-image-cropper-customizer',
  templateUrl: './image-cropper-customizer.component.html',
  styleUrls: ['./image-cropper-customizer.component.scss'],
})
export class ImageCropperCustomizerComponent implements OnInit {
  private _defaultTransformValue!: ImageTransform;

  public actionsTypes = ActionsTypes;
  public transform: ImageTransform = {};
  public showCropper = false;
  public canvasRotation!: number;

  public preview!: string;

  @Input() imageEvent!: Event & { target: EventTarget & { files: FileList } };
  @Input() aspectRatio = 4 / 4;
  @Input() roundCropper = true;
  @Input() showPreview = false;
  @Input() photo = 'assets/images/report-preview.png';
  @Input() previewDetail = '';
  @Input() imageCropperOptions: ImageCropperOptions = defaultCropperOptions;
  @Input() imageCropperTransformConfig: ImageCropperTransformConfig = defaultImageCropperTransformConfig;
  @Output() imageCroppedEmitter: EventEmitter<File> = new EventEmitter();

  ngOnInit() {
    this._setDefaultTransformValue();
    this.canvasRotation = this.imageCropperTransformConfig.canvasRotation;
    this.transform = this._defaultTransformValue;
  }

  private _setDefaultTransformValue() {
    this._defaultTransformValue = {
      scale: this.imageCropperTransformConfig.scale,
      rotate: this.imageCropperTransformConfig.rotate,
      flipH: this.imageCropperTransformConfig.flipH,
      flipV: this.imageCropperTransformConfig.flipV,
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    if (event?.base64) {
      this.preview = event.base64;
      this.imageCroppedEmitter.emit(new File([base64ToFile(event.base64)], `${this.imageEvent.target.files[0].name}`, { type: 'image/png' }));
    }
  }

  // Cropper status
  imageLoaded() {
    this.showCropper = true;
  }

  // Actions
  private _getZoomByType(actionsTypes: ActionsTypes): number | undefined {
    return actionsTypes === ActionsTypes.ZoomIn ? this._zoomIn() : this._zoomOut();
  }

  private _getRotateByType(actionsTypes: ActionsTypes): number | undefined {
    return actionsTypes === ActionsTypes.RotateRight ? this._rotateRigth() : this._rotateLeft();
  }

  private _rotateRigth(): number | undefined {
    const { maxRotate, stepRotate } = this.imageCropperTransformConfig;
    return !isUndefined(this.transform?.rotate) && this.transform?.rotate < maxRotate ? this.transform.rotate + stepRotate : this.transform?.rotate;
  }

  private _rotateLeft(): number | undefined {
    const { maxRotate, stepRotate } = this.imageCropperTransformConfig;
    return !isUndefined(this.transform?.rotate) && this.transform?.rotate < maxRotate ? this.transform.rotate - stepRotate : this.transform?.rotate;
  }

  private _zoomIn(): number | undefined {
    const { maxScale, stepScale } = this.imageCropperTransformConfig;
    return this.transform?.scale && this.transform?.scale < maxScale ? this.transform.scale + stepScale : this.transform?.scale;
  }

  private _zoomOut(): number | undefined {
    const { minScale, stepScale } = this.imageCropperTransformConfig;
    return this.transform?.scale && this.transform?.scale > minScale ? this.transform.scale - stepScale : this.transform.scale;
  }

  public transformCropper(actionsTypes: ActionsTypes, $event) {
    this.transform = {
      scale:
        actionsTypes === ActionsTypes.Zoom && !isNil($event?.target.value)
          ? $event?.target.valueAsNumber
          : actionsTypes === ActionsTypes.ZoomIn || actionsTypes === ActionsTypes.ZoomOut
          ? this._getZoomByType(actionsTypes)
          : this.transform.scale,
      rotate:
        actionsTypes === ActionsTypes.Rotate && $event?.target.valueAsNumber
          ? $event.target.valueAsNumber
          : actionsTypes === ActionsTypes.RotateRight || actionsTypes === ActionsTypes.RotateLeft
          ? this._getRotateByType(actionsTypes)
          : this.transform.rotate,
      flipH: actionsTypes === ActionsTypes.FlipH ? !this.transform.flipH : this.transform.flipH,
      flipV: actionsTypes === ActionsTypes.FlipV ? !this.transform.flipV : this.transform.flipV,
    };
  }

  public rotateLeft() {
    this.canvasRotation--;
  }

  public rotateRight() {
    this.canvasRotation++;
  }

  resetImage() {
    this.canvasRotation = this.imageCropperTransformConfig.canvasRotation;
    this.transform = this._defaultTransformValue;
  }
}
