import { isNil, ObjectTS } from '../to-delete/bucket';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const removeNullsAndUndefined = <T>(body: T): Partial<T> => {
  if (body) {
    ObjectTS.keys(body)
      .filter((k) => isNil(body[k]))
      .forEach((k) => delete body[k]);
  }
  return body;
};

/**
 *
 * @param url$ Observable que lleve el dominio del back
 * @param lang$ Observable que lleve el lenguage configurado
 */
export const defaultBaseUrlFactory = (url$: Observable<string>, lang$?: Observable<string>): Observable<string> =>
  lang$ ? combineLatest([url$, lang$]).pipe(map(([url, lang]) => `${url}/${lang}/`)) : url$;
