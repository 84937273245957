import { AppraisalDocumentTypologies, AppraisalImplementationFileDocumentTypes, AppraisalVinculations, FileTypes } from '../enums';
import { TreeNode } from 'primeng/api';
import { AssetTreeNodeExtendedIndexModel } from './appraisal-report-asset.model';

export interface AppraisalReportAssetFileWithAllSizeModel {
  files: AppraisalReportAssetFileIndexModel[];
  totalMBSize: number;
}

// TODO: move to new file
export interface AppraisalReportAssetFileIndexModel {
  mainAssetId: number;
  id: number;
  stayId: number;
  fileName: string;
  fileWebPath: string;
  fileWebPathUri?: string;
  addedDateTime: string;
  name: string;
  description: string;
  isPrintable: boolean;
  fileType: FileTypes;
  isMainImageInReport: boolean;
  obtainedDatetime: string;
  order: number;
  appraisalReportAssetDocumentType: AppraisalImplementationFileDocumentTypes;
  appraisalReportAssetDocumentTypology: AppraisalDocumentTypologies;
  sizeInMB?: string;
  parentRecordId?: string;
  parentVinculationType?: AppraisalVinculations;
}

// AppraisalAsset
// TODO: move to new file
export interface WorkflowItemAssetFileModelBase {
  assetId: number;
  id: number;
  fileType: FileTypes;
  stayId: number;
  fileName: string;
  fileWebPath: string;
  addedDateTime: Date;
  name: string;
  description: string;
  isPrintable: boolean;
  obtainedDatetime: Date;
}

/**
 * @todo Extraer en un futuro
 */
export type AssetTreeNode = TreeNode<AssetTreeNodeExtendedIndexModel>;

export interface AssetTreeNodeError {
  id: string;
  label: string;
}

//////////////////////////////
// Nuevos modelos

export interface AppraisalFileFilterModel {
  activatedFiltersCount?: number;
  id?: number;
  ids?: number[];
  orderFieldAsc?: boolean;
  orderFieldName?: string;
  possibleOrderFieldNames?: string[];
  summary?: string;
  workflowItemId?: number;
  workflowItemIds?: number[];
}

export interface AppraisalFileWithAllSizeModel {
  files: AppraisalFileIndexModel[];
  totalMBSize: number;
}
export interface AppraisalFileDataPickSizeModel {
  recordId: string;
  filesWithSize: AppraisalFileDocument;
}

export interface AppraisalFileDocument {
  totalMBSize: number;
  files: AppraisalFileIndexModel[];
}

export interface AppraisalFileIndexModel {
  id: number;
  fileName: string;
  fileWebPath: string;
  addedDateTime: string;
  name: string;
  isPrintable: boolean;
  appraisalDocumentType: AppraisalImplementationFileDocumentTypes;
  fileType: FileTypes;
  obtainedDatetime: string;
  sizeInMB?: string;
  selected?: boolean;
  parentRecordId?: string;
  parentVinculationType?: AppraisalVinculations;
}

export interface AppraisalFileEditModel {
  id: number;
  workflowItemId: number;
  name: string;
  description: string;
  fileType: FileTypes;
  obtainedDatetime: string;
  appraisalDocumentType: AppraisalImplementationFileDocumentTypes;
}

export interface WorkflowItemReportAssetFileChangeOrderModel {
  fileIds: number[];
}

export interface AppraisalAssetToAssignDocument {
  id: number;
  isPrintable: boolean;
}

export interface AppraisalReportAssetFileFilterModel {
  appraisalReportId?: number;
  assetId?: number;
  assetIds?: number[];
  id?: number;
  ids?: number[];
}
