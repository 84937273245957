import { Facilities, FacilityLocations } from '../enums';

export interface AssetFacilityIndexModel {
  AssetId: number;
  FacilityId: number;
  WorkflowItemAssetId: number;
  Facility: Facilities;
  FacilityOther: string;
  Location: FacilityLocations;
  Description: string;
}

/////////////////////////////
