<form id="password-change" class="example-container" [formGroup]="passwordChangeForm" (ngSubmit)="restoreThePassword()">
  <a class="logo-container" [routerLink]="['/']">
    <img src="/assets/images/logo.png" />
  </a>

  <h1>{{ 'RESTORE_PASSWORD_changePassword' | translate }}</h1>

  <span [ngStyle]="{ width: !mobileQuery.matches ? '25%' : '100%' }">{{ 'RESTORE_PASSWORD_changePasswordLabel' | translate }}</span>

  <div class="central-container mt-3">
    <div class="input-container">
      <ui-form-input-password [label]="'RESTORE_PASSWORD_newPassword' | translate" [form]="passwordChangeForm" [controlName]="'password'"></ui-form-input-password>
    </div>

    <div class="input-container">
      <ui-form-input-password
        [label]="'CHANGE_TEMPORAL_PASSWORD_repeatPassword' | translate"
        [form]="passwordChangeForm"
        [controlName]="'confirmPassword'"
      ></ui-form-input-password>
    </div>
  </div>

  <div class="login-input-button">
    <ui-async-button
      [inputState]="restorePasswordButtonState"
      [rounded]="true"
      [type]="'submit'"
      [asyncButton]="true"
      [buttonClassByType]="'primary'"
      [size]="'lg'"
      componentId="AT_CHANGE_TEMPORAL_PASSWORD_change"
      [label]="'CHANGE_TEMPORAL_PASSWORD_change' | translate"
      [loadingLabel]="'CHANGE_TEMPORAL_PASSWORD_change' | translate"
      [disabled]="passwordChangeForm.invalid"
      (clickEvent)="restoreThePassword()"
    ></ui-async-button>
  </div>
</form>
