import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternationalizationTypes } from '@foxeet/domain';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-number',
  templateUrl: './form-input-number.component.html',
  styleUrls: ['./form-input-number.component.scss'],
})
export class FormInputNumberComponent extends BaseFormComponent<string> {
  @Input() suffix = '';
  @Input() digitsInfo = '.2-2';
  @Input() enableFormat = true;
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: number;
  @Input() hintLabel?: string;
  /** @deprecated Se deberia pasar el valor traducido por el suffix */
  @Input() suffixType?: InternationalizationTypes;

  @Output() blurEvent = new EventEmitter<void>();
}
