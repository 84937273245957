import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { FormValidationMessagesComponent } from './form-validation-messages.component';

@NgModule({
  declarations: [FormValidationMessagesComponent],
  imports: [CommonModule, ReactiveFormsModule, I18nModule.forChild()],
  exports: [FormValidationMessagesComponent],
})
export class FormValidationMessagesModule {}
