import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { AppraisalPortfolioEditAppraisalsModel } from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class PortfolioService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalPortfolios', cache);
  }

  addAppraisalsPortfolio(data: AppraisalPortfolioEditAppraisalsModel) {
    return this.put(`${data.id}/AddAppraisals`, data);
  }

  removeAppraisalsPortfolio(data: AppraisalPortfolioEditAppraisalsModel) {
    return this.put(`${data.id}/RemoveAppraisals`, data);
  }
}
