import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppraisalFilterModel } from '@foxeet/domain';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { CacheService } from '../services/cache.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';

@Injectable({
  providedIn: 'root',
})
export class UnicajaService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'UnicajaFilesManager', cache);
  }

  appraisalsListPagedByFilter<T>(page: number, size: number, unicajaCentro: string, unicajaHash: string, unicajaHora: string, body: AppraisalFilterModel): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    params = params.set('unicajaCentro', `${unicajaCentro}`);
    params = params.set('unicajaHash', `${unicajaHash}`);
    params = params.set('unicajaHora', `${unicajaHora}`);

    return this.post<typeof body, T>(`Appraisals/ListPaged`, body, { params });
  }

  appraisalsDocumentsListByFilter<T>(unicajaCentro: string, unicajaHash: string, unicajaHora: string, body: AppraisalFilterModel): Observable<T> {
    let params = new HttpParams();
    params = params.set('unicajaCentro', `${unicajaCentro}`);
    params = params.set('unicajaHash', `${unicajaHash}`);
    params = params.set('unicajaHora', `${unicajaHora}`);
    return this.post<typeof body, T>(`AppraisalFiles/ListAll`, body, { params });
  }

  getCustomers<T>(body: any, unicajaCentro: string, unicajaHash: string, unicajaHora: string): Observable<T> {
    body = removeNullsAndUndefined(body);
    let params = new HttpParams();
    params = params.set('unicajaCentro', `${unicajaCentro}`);
    params = params.set('unicajaHash', `${unicajaHash}`);
    params = params.set('unicajaHora', `${unicajaHora}`);
    return this.post<typeof body, T>('Licensees/ListAll', body, { params });
  }
}
