import { Component } from '@angular/core';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ui-download-file-button',
  templateUrl: './download-file-button.component.html',
  styleUrls: ['./download-file-button.component.scss'],
})
export class DownloadFileButtonComponent {
  public readonly faFileDownload = faFileDownload;
  public readonly downloading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public startDownload(): void {
    this.downloading$.next(true);
  }

  public finishDownload(): void {
    this.downloading$.next(false);
  }
}
