import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getCountryConfigurations, getCurrencies, setCountryConfigurations, setCurrencies } from './culture.actions';
import { EnumsService } from '@foxeet/data-access';
import { of } from 'rxjs';

@Injectable()
export class CultureEffects {
  getCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrencies),
      mergeMap(() => this.getCurrencies()),
    ),
  );

  getCountryConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountryConfigurations),
      mergeMap(() => this.getCountryConfigurations()),
    ),
  );

  constructor(private actions$: Actions, private enumsService: EnumsService) {}

  getCountryConfigurations() {
    return this.enumsService.getCountryConfigurations().pipe(
      catchError(() => of(null)),
      map((configs) => setCountryConfigurations({ configs })),
    );
  }

  getCurrencies() {
    return this.enumsService.getCurrencies().pipe(
      catchError(() => of(null)),
      map((configs) => setCurrencies({ configs })),
    );
  }
}
