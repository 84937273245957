export interface AssetRusticEstateInformationModel {
  /// Morfología
  morphology: string;
  /// Topología y relieve
  topologyAndSlope: string;
  /// Relieve
  setOff: string;
  /// Textura del terreno
  terrainTexture: string;
  /// Clases agrológicas
  agrologicalClasses: string;
  /// Pluviometría
  pluviometry: string;
  /// Temperaturas
  temperatures: string;
  /// Microclimas destacables
  remarkableMicroclimates: string;
  /// Altitud media
  averageAltitude: string;
  /// Régimen de humedad
  humidityRegime: string;
  /// Procedencia del agua y caudal
  sourceOfWaterAndFlow: string;
  /// Sistema de extracción y distribución
  extractionAndDistributionSystem: string;
  /// Horas de frío / Horas de sol
  coldHoursSunnyHours: string;
  /// Enería eléctrica
  electricalEnergy: boolean;
  /// Acceso a infraestructuras viales
  accessToRoadInfrastructure: boolean;
  /// Agua de regadío legalizada
  legalizedIrrigationWater: boolean;
  /// Aprovechamiento esperado
  expectedLeverage: string;
}
