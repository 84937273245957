import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class RequestBatchesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'OrderRequestBatch', cache);
  }

  getTemplate(): Observable<any> {
    return this.get(`Template`, { observe: 'response', responseType: 'text' });
  }

  uploadNewBatch(document: Blob) {
    const body = new FormData();
    body.append('model', document);
    return this.post<FormData, HttpEvent<Observable<number>>>('', body, {
      observe: 'events',
      responseType: 'json',
      reportProgress: true,
    });
  }
}
