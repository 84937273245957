import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../providers';

@Injectable({ providedIn: 'root' })
export class LicenseInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addCurrentLicenseId(request)); // .pipe(catchError((error) => {}));
  }

  private addCurrentLicenseId(request: HttpRequest<any>): HttpRequest<any> {
    const currentLicense = this._authService.getCurrentLicense();
    if (currentLicense && currentLicense.id) {
      request = request.clone({ headers: request.headers.set('CurrentLicenseId', `${currentLicense.id}`) });
    }
    return request;
  }
}
