import { Observable } from 'rxjs';
import { ValorationKeyValueTypes } from '../enums';

export interface FoxeetKeyValueModel {
  id: number;
  relatedId: number;
  key: string;
  value: string;
  name: string;
  groupKey: string;
  groupName: string;
  isManualAdded: boolean;
  addedDateTime: string;
  addedUserFullName: string;
  valorationKeyValueType: ValorationKeyValueTypes;
  listData: listDataKeyValueModel[];
}

export interface listDataKeyValueModel {
  key: string;
  value: string | number;
}

export interface KeyValueModel {
  key: string;
  name: string;
  groupKey: string;
  groupName: string;
}

export interface ExtraInformationTabModel {
  cadastralReference: string;
  name: string;
  id: number;
  allowedKeyValues: (id?: number) => Observable<KeyValueModel[]>;
  addNewData: (data: FoxeetKeyValueModel, id?: number) => Observable<number>;
  removeData: (keyId: number, id?: number) => Observable<unknown>;
}
