import { FamilyRelationships } from '../enums';

////////////////////////////////////
// Nuevos modelos

export interface UserFamiliarEditModel {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  familyRelationship: FamilyRelationships;
}

export interface UserFamiliarModel {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  familyRelationship: FamilyRelationships;
  addedDateTime: string;
  addedUserId: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
}

export interface UserFamiliarIndexModel {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  familyRelationship: FamilyRelationships;
  addedDateTime: string;
  addedUserId: number;
  userFirstName: string;
  userLastName: string;
  isEditable: boolean;
}
