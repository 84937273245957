import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterInputDatepickerTypes, FilterInputTypes, IFormDataModel } from '@foxeet/domain';

@Component({
  selector: 'ui-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent {
  // Enums
  public FilterInputTypes = FilterInputTypes;
  public FilterInputDatepickerTypes = FilterInputDatepickerTypes;

  @Input() title = '';

  @Input() model: IFormDataModel[][] = [];

  @Input() modelFormGroup!: FormGroup;

  @Input() disabled = false;

  public getCalculatedClasses(element: IFormDataModel) {
    if (element.size) {
      return (Object.keys(element.size) as (keyof IFormDataModel['size'])[])
        .map((key) => {
          key === 'xs' ? `col-${element.size[key]}` : `col-${key}-${element.size[key]}`;
          switch (key) {
            case 'xs':
              return `col-${element.size[key]}`;
            case 'auto':
              return `col`;
            default:
              return `col-${key}-${element.size[key]}`;
          }
        })
        .join(' ');
    } else {
      console.error(`This element don't have size prop:`, element);
      return 'col';
    }
  }
}
