import { Component } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-time',
  templateUrl: './form-input-time.component.html',
  styleUrls: ['./form-input-time.component.scss'],
})
export class FormInputTimeComponent extends BaseFormComponent<any> {
  constructor() {
    super();
  }

  onValueChange(value: Date) {
    this.control.markAsDirty();
    this.control.setValue(`${value.getHours()}:${value.getMinutes()}`);
  }
}
