import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumItem } from '../domain/breadcrum.types';
import { BreadcrumService } from '../providers/breadcrum.service';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss'],
})
export class BreadcrumComponent {
  public items$: BehaviorSubject<BreadcrumItem[]>;
  public charSeparator = '>';

  constructor(private _breadcrumService: BreadcrumService) {
    this.items$ = this._breadcrumService.items$;
  }
}
