import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { defaultBaseUrlFactory } from '../../functions';
import { BACK_CONFIG, BackConfig } from '@foxeet/data-access';

export interface URLParameter {
  key: string;
  value: any;
}

export interface URLParams {
  key: string;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class APIBaseService implements OnDestroy {
  private domainAddress!: string;
  private baseServerURL!: string; // TODO: Rename to API_ADDRESS
  private subs$ = new Subscription();
  private ID: number;

  constructor(private _http: HttpClient, @Inject(BACK_CONFIG) public config: BackConfig, private _translateService: TranslateService) {
    this.ID = Math.floor(Math.random() * 10);

    this.subs$.add(defaultBaseUrlFactory(config.baseUrl, config.lang).subscribe((formatedUrl) => (this.baseServerURL = formatedUrl)));
    this.subs$.add(config.baseUrl.subscribe((domain) => (this.domainAddress = domain)));
    // Set the corresponding endpoint of the current environment
  }

  public getEndpointAddress(): string {
    return this.domainAddress;
  }

  public getAPIAddress(): string {
    return this.baseServerURL;
  }

  // HttpClient functions

  /**
   * Construct a POST request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  post<T>(url: string, body: any, parameters?: URLParameter[]): Observable<HttpResponse<T>> {
    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
    }

    // TODO: Create a function and call it here
    // Clean the body from empty strings, undefineds and nulls
    if (body != null) {
      Object.keys(body).forEach((k) => !body[k] && body[k] !== 0 && body[k] !== false /*&& body[k] !== undefined*/ && delete body[k]);
    }

    // console.log("body after cleaning: ", body);
    const bodyJSON = JSON.stringify(body);

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : '') + '\nBody: ' + bodyJSON);

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.post<T>(url, bodyJSON, {
      headers,
      observe: 'response',
      responseType: 'json',
      params: httpParams,
    });
    // TODO: Add timeout and test if it triggers the catch block
  }

  postWithStringResponse(url: string, body: any, parameters?: URLParameter[]): Observable<HttpResponse<string>> {
    // : Observable<HttpResponse<T>> {

    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // TODO: Create a function and call it here
    // Clean the body from empty strings, undefineds and nulls
    if (body != null) {
      Object.keys(body).forEach((k) => !body[k] && body[k] !== 0 && body[k] !== false /*&& body[k] !== undefined*/ && delete body[k]);
    }

    // console.log("body after cleaning: ", body);
    const bodyJSON = JSON.stringify(body);

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : '') + '\nBody: ' + bodyJSON);

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.post(url, bodyJSON, { headers, observe: 'response', responseType: 'text', params: httpParams });
    // TODO: Add timeout and test if it triggers the catch block
  }

  postWithBodyObservable<T>(url: string, body: any, parameters?: URLParameter[]): Observable<T> {
    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // TODO: Create a function and call it here
    // Clean the body from empty strings, undefineds and nulls
    if (body != null) {
      Object.keys(body).forEach((k) => !body[k] && body[k] !== 0 && body[k] !== false /*&& body[k] !== undefined*/ && delete body[k]);
    }

    // console.log("body after cleaning: ", body);
    const bodyJSON = JSON.stringify(body);

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : '') + '\nBody: ' + bodyJSON);

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.post<T>(url, bodyJSON, { headers, observe: 'body', params: httpParams });
    // TODO: Add timeout and test if it triggers the catch block
  }

  postForm(url: string, parameters?: URLParameter[], headers?: HttpHeaders): Observable<HttpResponse<string>> {
    // Set options
    if (!headers) {
      headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlenconded', charset: 'UTF-8' });
    }

    // Use the params to fill the body
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }
    const body = httpParams.toString();

    // DEBUG logging
    // console.log('POST request: ' + url + '\nBody: ' + body);

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.post(url, body, { headers, observe: 'response', responseType: 'text' });
    // TODO: Add timeout and test if it triggers the catch block
  }

  postFile<T>(url: string, blob: Blob, parameters?: URLParameter[]): Observable<HttpEvent<T>> {
    // Set options
    // var headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' }); // For this request, setting the Content-Type manually would produce an error on the backend
    let httpParams: HttpParams = new HttpParams();
    const body = new FormData();
    body.append('file', blob);

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : ''));
    // console.log('BODY', body);

    const req = new HttpRequest('POST', url, body, { params: httpParams, responseType: 'json', reportProgress: true });
    return this._http.request<T>(req);
  }

  postMultipartFile<T>(url: string, body: FormData, parameters?: URLParameter[]) {
    // Set options
    // var headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' }); // For this request, setting the Content-Type manually would produce an error on the backend
    let httpParams: HttpParams = new HttpParams();
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }
    const req = new HttpRequest('POST', url, body, { params: httpParams, responseType: 'json', reportProgress: true });
    return this._http.request<T>(req);
  }

  postFileBoundary(url: string, blobs: Blob[], bodyParameters: any, parameters?: URLParameter[]) {
    // Set options
    // var headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' }); // For this request, setting the Content-Type manually would produce an error on the backend
    let httpParams: HttpParams = new HttpParams();
    const body = new FormData();
    blobs.map((blob, index) => {
      body.append('file' + index, blob);
    });
    Object.keys(bodyParameters).forEach((key) => {
      body.append(key, bodyParameters[key]);
    });

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
    }

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : ''));
    // console.log('BODY', body);

    return this._http.post(url, body, { params: httpParams, responseType: 'json', reportProgress: true });
  }

  get<T>(url: string, parameters: URLParameter[] = []): Observable<HttpResponse<T>> {
    // // Set options
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // let httpParams: HttpParams = new HttpParams();

    // // TODO: Create a function and call it here
    // // Add query string params, if any
    // if (parameters) {
    //   for (let i = 0; i < parameters.length; i++) {
    //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
    //   }
    // }

    // // DEBUG logging
    // //  console.log("GET request: " + url + (parameters ? "?" + httpParams.toString() : ""));

    // return this._http.get<T>(url, { headers: headers, observe: 'response', params: httpParams }); // .retryWhen( // TODO: Configure retries. Get retries from parameter
    // // TODO: Add timeout and test if it triggers the catch block
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();
    if (parameters) {
      parameters.forEach((param) => {
        // Add only the query params that have value
        if (param.value) {
          httpParams = httpParams.append(param.key, param.value);
        }
      });
    }
    // .retryWhen( // TODO: Configure retries. Get retries from parameter
    return this._http.get<T>(url, { headers, observe: 'response', params: httpParams });
  }

  getWithStringResponse(url: string, parameters?: URLParameter[]): Observable<HttpResponse<string>> {
    // : Observable<HttpResponse<T>> {

    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // DEBUG logging
    // console.log("GET request: " + url + (parameters ? "?" + httpParams.toString() : ""));

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.get(url, { headers, observe: 'response', responseType: 'text', params: httpParams });
    // TODO: Add timeout and test if it triggers the catch block
  }

  getBlob(url: string, parameters?: URLParameter[], body: any = null) {
    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : ''));

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    // return this._http.post(url, body, { headers, observe: 'response', responseType: 'blob', params: httpParams });
    return this._http.get(url, { headers, observe: 'response', responseType: 'blob', params: httpParams });
    // TODO: Add timeout and test if it triggers the catch block
  }

  getBlobWithFilter(url: string, parameters?: URLParameter[], body: any = null) {
    // Set options
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    // TODO: Create a function and call it here
    // Add query string params, if any
    if (parameters) {
      //   for (let i = 0; i < parameters.length; i++) {
      //     httpParams = httpParams.append(parameters[i].key, parameters[i].value);
      //   }
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }

    // DEBUG logging
    // console.log('POST request: ' + url + (parameters ? '?' + httpParams.toString() : ''));

    // .retryWhen( // TODO: Configure retries. Get number of retries from parameter
    return this._http.post(url, body, { headers, observe: 'response', responseType: 'blob', params: httpParams });
    // return this._http.get(url, { headers, observe: 'response', responseType: 'blob', params: httpParams });
    // TODO: Add timeout and test if it triggers the catch block
  }

  // TODO: Create an addParameters function

  // Helper functions

  // TODO: Test if this function modifies the original object or just a copy of it that is returned then
  removeEmpty = (obj: any) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== 0 && obj[k] !== false && obj[k] !== undefined && delete obj[k]);
    return obj;
  };

  // ES5 compatible
  // removeEmpty(obj) {
  //   Object.keys(obj).forEach(function(key) {
  //     (obj[key] && typeof obj[key] === 'object') && this.removeEmpty(obj[key]) ||
  //     (obj[key] === '' || obj[key] === null) && delete obj[key]
  //   });
  //   return obj;
  // }

  postMultiPartForm(url: string, body: any) {
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    return this._http.post(url, body, { reportProgress: true });
  }

  put<T>(url: string, body: any = null, parameters?: URLParameter[]) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let httpParams: HttpParams = new HttpParams();

    if (parameters) {
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }
    if (body != null) {
      Object.keys(body).forEach((k) => !body[k] && body[k] !== 0 && body[k] !== false /*&& body[k] !== undefined*/ && delete body[k]);
    }
    return this._http.put<T>(url, body, { headers, observe: 'response', responseType: 'json', params: httpParams });
  }

  putMultipartFile<T>(url: string, body: FormData, parameters?: URLParameter[]): Observable<HttpEvent<T>> {
    let httpParams: HttpParams = new HttpParams();
    if (parameters) {
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    const req = new HttpRequest('PUT', url, body, { params: httpParams, responseType: 'json', reportProgress: true });
    return this._http.request<T>(req);
  }

  putFormWithStringResponse<T>(url: string, body: FormData, parameters?: URLParameter[]): Observable<HttpEvent<T>> {
    let httpParams: HttpParams = new HttpParams();
    if (parameters) {
      parameters.forEach((el) => (httpParams = httpParams.append(el.key, el.value)));
    }
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    const req = new HttpRequest('PUT', url, body, { params: httpParams, responseType: 'text' });
    return this._http.request<T>(req);
  }

  delete(url: string) {
    return this._http.delete(url);
  }

  public ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
