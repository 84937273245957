<div *ngIf="control" class="wrapper my-2" [id]="componentId" [ngClass]="{ error: control.invalid, readonly: readonly }">
  <div class="switch" [ngClass]="{ center: control?.value === null, start: control?.value === false, end: control?.value === true, disabled: control?.disabled }">
    <div class="slider"></div>
    <div class="false-value" (click)="setValue(control, false)" matTooltip="No"></div>
    <div class="null-value" (click)="setValue(control, null)" matTooltip="N/A"></div>
    <div class="true-value" (click)="setValue(control, true)" matTooltip="Si"></div>
  </div>

  <div *ngIf="label" class="label">{{ label }}</div>
</div>
