<div class="custom-container p-3">
  <div class="p-grid">
    <div class="p-col">
      <h1>{{ 'existFormChanges' | translate }}</h1>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col">
      <p>{{ data.message || 'sureWantToExit' | translate }}</p>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-3">
      <ui-async-button
        size="md"
        componentId="AT_close_modal"
        [label]="'close' | translate"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        (clickEvent)="cancel()"
      ></ui-async-button>
    </div>
    <div class="p-col">
      <ui-async-button
        size="md"
        componentId="AT_yesWithoutSaving_modal"
        [label]="'yesWithoutSaving' | translate"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        (clickEvent)="exit()"
      >
      </ui-async-button>
    </div>
    <div class="p-col">
      <ui-async-button
        size="md"
        componentId="AT_yesSaving_modal"
        [label]="'yesSaving' | translate"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        (clickEvent)="exitSaving()"
      >
      </ui-async-button>
    </div>
  </div>
</div>
