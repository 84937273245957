import { CommonModule, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DecimalFormatterPipe } from './pipes/decimal-formatter.pipe';
import { DynamicPipe } from './pipes/dynamic-pipes.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { NormalizeNumberPipe } from './pipes/normalize-number.pipe';
import { SafePipe } from './pipes/sanitizer.pipe';
import { SuffixBySuffixTypeAndUsePipe } from './pipes/suffix-by-suffix-type-and-use.pipe';
import { TimeLeftPipe } from './pipes/time-left.pipe';
import { ToDatePipeByLocale } from './pipes/to-datepipe-by-locale.pipe';
import { ToDistancePipe } from './pipes/to-distance.pipe';
import { ToMonthNamePipe } from './pipes/to-month-name.pipe';
import { ToPendingState } from './pipes/to-pending.pipe';

export const PIPES = [
  CapitalizePipe,
  DecimalFormatterPipe,
  DynamicPipe,
  EnumToStringPipe,
  NormalizeNumberPipe,
  SafePipe,
  TimeLeftPipe,
  ToDatePipeByLocale,
  ToDistancePipe,
  ToMonthNamePipe,
  ToPendingState,
  SuffixBySuffixTypeAndUsePipe,
];
@NgModule({
  declarations: PIPES,
  imports: [CommonModule],
  providers: PIPES,
  exports: PIPES,
})
export class PipesModule {}
