import { BillingTaxTypes, EntityTypes, PaymentOptions, PaymentStates, LicensePlans } from '../enums';
import { BillingData } from './billing-data.model';

export interface LicenseeLoginResponseModel {
  id: number;
  name: string;
  licenseExpiryDatetime: string;
  trialLicenseExpiryDatetime: string;
  isTrialLicense: boolean;
  activeLicensePlan: LicensePlans;
  contractedLicensePlan: LicensePlans;
  isAdmin: boolean;
  isDefault: boolean;
  roles: string[];
  profileImagePath: string;
  sharingId: string;
  hasQualityControlDashboardPermissions: boolean;
}
export interface License extends LicenseeLoginResponseModel {
  isCurrent: boolean;
  isAdmin: boolean;
}

export interface EditLicenseePlanModel {
  plan: LicensePlans;
}

/**
 * ENTITIES MODELS entities.module.ts
 */

export interface LicenseeIndexModel {
  id: number;
  isCompany: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  nationalIdentityDocument: string;
  email: string;
  phone: string;
  addedDateTime: string;
  lastUpdatedDateTime: string;
  addedUserId: number;
  lastUpdatedUserId: number;
  code: string;
  entityType: EntityTypes;
  automaticInvoicing: boolean;
}

export interface LicenseeFilterModel {
  isCompany: boolean;
  hasUsers: boolean;
  orderFieldName: string;
  orderFieldAsc: boolean;
  id: number;
  ids: number[];
  addedMaxDateTime: string;
  addedMinDateTime: string;
  nationalIdentityDocument: string;
  nationalIdentityDocumentContains: string;
  email: string;
  emailContains: string;
  firstName: string;
  firstNameContains: string;
  lastName: string;
  lastNameContains: string;
  fullName: string;
  fullNameContains: string;
  licenseeTypes: number[];
  entityType: EntityTypes;
  entityTypes: EntityTypes[];
  excludedEntityTypes: EntityTypes[];
  hasRelatedAsPrescritor: boolean;
  hasRelatedAsCustomer: boolean;
}

export interface LicenseeEditModel {
  id: number;
  isCompany: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  observations: string;
  email: string;
  phone: string;
  nationalIdentityDocument: string;
  description: string;
  latitude: number;
  longitude: number;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
  code: string;
  entityType: EntityTypes;
  isAppraiserSociety: boolean;
  bankOfSpainCode: number;
}

export interface LicenseeModel {
  id: number;
  isCompany: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  fullNameSearchPattern: string;
  nationalIdentityDocument: string;
  email: string;
  phone: string;
  observations: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  description: string;
  addedDateTime: string;
  addedUserId: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  billingData: BillingData;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
  code: string;
  entityType: EntityTypes;
}

export interface LicenseeEditPartnerDataModel {
  id: number;
  paymentOption: PaymentOptions;
}

export interface LicenseeUserModel {
  id: number;
  licenseeId: number;
  userId: number;
  roles: LicenseeUserRoleModel[];
}

export interface LicenseeUserRoleModel {
  id: number;
  licenseeUserId: number;
  licenseeUserRoleValue: number;
}

export interface ValidateCanChangePlanModel {
  hasValidDefaultPayment: boolean;
  message: string;
}

export interface LicenseeAllowedRoleEditModel {
  roleId: number;
  allowed: boolean;
}

export interface LicenseePaymentEstimationModel {
  activeUsers: number;
  estimatedPrice: number;
}

export interface LicenseePaymentIndexModel {
  id: number;
  relatedId: number;
  state: PaymentStates;
  amount: number;
  addedDateTime: string;
  stateLastUpdateDatetime: string;
  redsysPaymentId: string;
  redsysResponseState: number;
}

export interface LicensePlansModel {
  title: string;
  info: string;
  isActive: boolean;
  image: string;
  id: number;
  deprecated: boolean;

  // FRONT
  textInfoPayment: string[];
  price: number;
}
