import { Injectable } from '@angular/core';
import { OrNull } from '@foxeet/domain';
import { isNil, isValidJSON, ObjectTS } from '../functions/javascript.functions';

/**
 *
 * @constructor Servicio de Storage
 * @param storage Almacenaje a instanciar
 *
 * @example Providing
 * providers: [
 * {
 *   provide: StorageService,
 *   useFactory: FactoryStorageService,
 *   deps: [window.localStorage]
 * }
 * ]
 */

export function FactoryStorageService(storage: Storage): StorageService {
  return new StorageService(storage);
}

export function FactoryLocalStorageService(): StorageService {
  return new StorageService(window.localStorage);
}

export function FactorySessionStorageService(): StorageService {
  return new StorageService(window.sessionStorage);
}

@Injectable()
export class StorageService {
  get length() {
    return this.storage.length;
  }

  constructor(protected readonly storage: Storage) {}

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): OrNull<string> {
    return this.storage.getItem(key);
  }

  public key(index: number): OrNull<string> {
    return this.storage.key(index);
  }

  public removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  public setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }
}

export function FactoryLocalDataStorageService(): DataStorageService {
  return new DataStorageService(window.localStorage);
}

export function FactoryDataStorageService(storage: Storage): DataStorageService {
  return new DataStorageService(storage);
}

@Injectable()
export class DataStorageService extends StorageService {
  /**
   * @description Obtienes objetos complejos y no utilizar variables simples
   * @param key
   */
  public getTItem<T>(key: string): OrNull<T> {
    const raw = this.storage.getItem(key);
    if (isNil(raw)) {
      return null;
    }
    return isValidJSON(raw) ? JSON.parse(raw) : raw;
  }

  public getPropertyItem<T, K extends keyof T>(key: string, property: K): OrNull<T[K]> {
    const item = this.getTItem<T>(key);
    return isNil(item) ? null : ObjectTS.getProperty(item, property);
  }

  public setItem<T>(key: string, value: T): void {
    const raw = JSON.stringify(value);
    return this.storage.setItem(key, raw);
  }

  public setPropertyItem<T, K extends keyof T>(key: string, property: K, value: T[K]): void {
    const item = this.getTItem<T>(key);
    if (isNil(item)) {
      return;
    }
    item[property] = value;
    this.setItem(key, item);
  }
}

/**
 * @example Uso del DataStorage para que devuelva el valor correctamente tipado
 * type check = {
 *   a: string;
 *   b: number;
 *   c: boolean;
 * };
 * @BUILDIND
 * const a = new DataStorageService(window.localStorage);
 *
 * @MODE_A
 * const b: keyof check = 'c';
 * const c = a.getPropertyItem<check, typeof b>("a", b); => Devuelve un OrNull<boolean> - lo identifica correctamente
 * @MODE_B
 * const c = a.getPropertyItem<check, 'c'>("a", 'c');
 */
