import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService2 } from '../services/api-base.service';
import { CacheService } from '../services/cache.service';
import { LicenseeLoginResponseModel, LicensePlansModel } from '@foxeet/domain';
import { BackUrlService } from '../services/back-url.service';

@Injectable()
export class AccountService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService, private backUrlService: BackUrlService) {
    super(http, 'Account', cache);
  }

  getProfilePhoto(id: number): string {
    return `${this.backUrlService.getFullUrl()}/${this.apiBranch}/${id}`;
  }

  putImageProfile = (
    id: number,
    image: Blob,
    body: { Id: number; ProfileImageFile: string; ProfileImageWidth: number; ProfileImageHeight: number; FileName: string },
  ): Observable<HttpEvent<string>> => {
    const formData = new FormData();
    formData.append('Id', `${id}`);
    body?.FileName ? formData.append('ProfileImageFile', image, body.FileName) : formData.append('ProfileImageFile', image);
    formData.append('ProfileImageWidth', `${body?.ProfileImageWidth || 150}`);
    formData.append('ProfileImageHeight', `${body?.ProfileImageHeight || 150}`);

    return this.http.put(`${this.apiBranch}/ImageProfile`, formData, {
      observe: 'events',
      responseType: 'text',
    });
  };

  getTestError(): Observable<void | null> {
    return this.get<void>('TestError');
  }

  getIsPartnerAutomaticInvoicing(): Observable<boolean | null> {
    return this.get<boolean>('IsPartnerAutomaticInvoicing');
  }

  changeTemporalPassword(password: string, confirmPassword: string): Observable<HttpResponse<string | null>> {
    const body = { password, confirmPassword };
    return this.http.post<string>(`${this.apiBranch}/ChangeTemporalPassword`, body, { observe: 'response' });
  }

  sendMailToRestoreThePassword(email: string) {
    const body = { email };
    return this.post<typeof body, string>('ForgotPassword', body, { observe: 'response' });
  }

  restoreThePassword(email: string, code: string, password: string, confirmPassword: string) {
    const body = { email, code, password, confirmPassword };
    return this.http.post<void>(`${this.apiBranch}/ResetPassword`, body, { observe: 'response' });
  }

  accountActivation(email: string, code: string) {
    const body = { email, code };
    return this.post<typeof body, string>('Activate', body, { observe: 'response' });
  }

  public resendActivationEmail(userName: string): Observable<string> {
    return this.post<null, string>('ResendActivationEmail', null, { params: this.setParams({ userName }) });
  }

  getLicenses(): Observable<LicenseeLoginResponseModel[]> {
    return this.get<LicenseeLoginResponseModel[]>('Licensees');
  }

  public getAvailableLicensePlans(): Observable<LicensePlansModel[]> {
    return this.get<LicensePlansModel[]>('AvailableLicensePlans');
  }
}
