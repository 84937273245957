import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CultureActions, CultureSelectors } from '@foxeet/chests/culture';
import { AppraisalModel, CultureAndLanguage, License, LicenseeLoginResponseModel, LicenseeUserRoles, LoginResponseModel, OrNull, OrUndef } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { isNilOrEmptyString } from '@foxeet/utils/functions';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { map, pairwise, take, takeUntil } from 'rxjs/operators';
import { APIBaseService } from '@foxeet/utils/services/API/api-base.service';
import { AccountService } from '@foxeet/data-access';
import { DataStorageService } from '@foxeet/utils/services/storage.service';
import { ObjectTS } from '@foxeet/utils/functions/javascript.functions';
import { PATHS, SUB_PATHS } from '@foxeet/n-core';

const userDataKey = 'userData';

export type ExtendedLoginResponse = LoginResponseModel & {
  isRequesterCompany?: boolean;
  roles?: number[];
  [p: string]: unknown;
};

@Injectable({ providedIn: 'root' })
export class AuthService extends UnsuscriptionHandler {
  public fromUrl: OrNull<string> = null;
  private readonly _serverURL: () => string;
  private _isTemporalPassword = false;

  public userLocalData$ = new BehaviorSubject<OrNull<ExtendedLoginResponse>>(null);

  // review
  public userProfile$ = new BehaviorSubject<any>(null);
  public userLicenses$ = new BehaviorSubject<License[]>([]);
  public authorities$ = new BehaviorSubject<string[]>([]);
  public authoritiesByCurrentAppraisal$ = new BehaviorSubject<string[]>([]);

  public onSameUrlReloadPage$ = new BehaviorSubject<boolean>(false);

  private userCultureAndLanguage!: CultureAndLanguage;
  private _defaultUrl = '/';

  constructor(
    private storage: DataStorageService,
    private _router: Router,
    private _http: HttpClient,
    private _api: APIBaseService,
    private _accountService: AccountService,
    private readonly store: Store,
  ) {
    super();
    this._serverURL = function () {
      const lang = this.storage.getItem('language') ?? 'es';
      return this._api.getEndpointAddress() + `/${lang}`;
    };

    this.userLicenses$.pipe(pairwise(), takeUntil(this._componentDestroyed)).subscribe(([prev, next]) => {
      const prevActive = prev.find((el) => el.isCurrent)?.id;
      const nextActive = next.find((el) => el.isCurrent)?.id;
      if (prevActive !== nextActive) this.onSameUrlReloadPage$.next(true);
    });

    this.store
      .select(CultureSelectors.CultureSetted)
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe(
        (values) =>
          (this.userCultureAndLanguage = {
            cultureCodeCurrency: values.cultureCodeCurrency,
            cultureCodeLanguage: values.cultureCodeLanguage,
          }),
      );

    this.userLocalData$.next(this.getUserDataFromLocalStore());
  }

  public login(username: string, password: string, firebaseToken: OrNull<string | string[]> = null) {
    const url = this._serverURL() + `/Auth/Login`;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/plain',
      'X-LoginPlatform': `${this._api.config.platform}`, // 0 means Admin, 1 means Mobile, 2 means Customer
    });

    // MOBILE PURPOSES.
    if (firebaseToken) headers = headers.append('X-FirebaseToken', firebaseToken);

    return this._http.post<LoginResponseModel>(url, { username, password }, { headers }).pipe(
      map((result: LoginResponseModel) => {
        if (!result) return undefined;
        this.store.dispatch(
          CultureActions.setLanguageAndCulture({
            cultureCodeCurrency: result.cultureCodeCurrency,
            cultureCodeLanguage: result.cultureCodeLanguage,
          }),
        );
        const userLoginData: LoginResponseModel = { ...result, ...this.userCultureAndLanguage };

        this.setUserDataToLocalStore(userLoginData as ExtendedLoginResponse);
        if (result.isActive) {
          this._isTemporalPassword = userLoginData.isTemporalPassword;
          this.setLicenseList(userLoginData.licenses);
        }
        return userLoginData;
      }),
    );
  }

  public changeTemporalPassword(password: string, confirmPassword: string) {
    return this._accountService.changeTemporalPassword(password, confirmPassword).pipe(
      map((result) => {
        if (result?.ok) this._isTemporalPassword = false;
      }),
    );
  }

  public sendMailToRestoreThePassword(email: string) {
    return this._accountService.sendMailToRestoreThePassword(email);
  }

  public restoreThePassword(paramEmail: string, paramCode: string, password: string, confirmPassword: string) {
    return this._accountService.restoreThePassword(paramEmail, paramCode, password, confirmPassword);
  }

  public logOut(): void {
    localStorage.clear();
    this._http.post(`${this._serverURL()}/Auth/Logout`, null).pipe(take(1)).subscribe();
    this._router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    return this.getToken() !== undefined;
  }

  public getToken(): OrUndef<string> {
    let token = this.getUserDataFromLocalStore()?.accessToken?.token;

    // Standarize no-token value to undefined
    if (token === 'undefined') token = undefined;

    if (isNilOrEmptyString(token)) {
      console.log('AuthService: No token found!');
    }

    return token;
  }

  public requestAccessToken(firebaseToken: OrNull<string | string[]> = null) {
    // TODO: add null checkers
    const localStorageInfo = this.getUserDataFromLocalStore();

    const url = this._serverURL() + `/Auth/RefreshToken`;
    const body = {
      accessToken: localStorageInfo && localStorageInfo.accessToken && localStorageInfo.accessToken.token,
      refreshToken: localStorageInfo && localStorageInfo.refreshToken,
    };

    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/plain',
      'X-LoginPlatform': `${this._api.config.platform}`, // 0 means Admin, 1 means Mobile, 2 means Customer
    });

    // MOBILE PURPOSES.
    if (firebaseToken) headers = headers.append('X-FirebaseToken', firebaseToken);

    // TODO: REVIEW THIS DUPLICATED MAPPER.
    return this._http.post<LoginResponseModel>(url, body, { headers }).pipe(
      map((result: LoginResponseModel) => {
        if (result) {
          const userLoginData: LoginResponseModel = result;
          this._isTemporalPassword = userLoginData.isTemporalPassword;
          this.setUserDataToLocalStore(userLoginData as ExtendedLoginResponse);
          this.setLicenseList(userLoginData.licenses);
        }
      }),
    );
  }

  // TODO: Review. Added for mobile purposes.
  public getUserDataFromLocalStore() {
    return this.storage.getTItem<ExtendedLoginResponse>('userData');
  }

  // TODO: Review. Added for mobile purposes
  public setUserDataToLocalStore(data: ExtendedLoginResponse) {
    this.storage.setItem<ExtendedLoginResponse>(userDataKey, data);
    this.userLocalData$.next(data);
  }

  public setItemToUserDataFromLocalStore(item: any, data: any) {
    const localStorageData = {
      ...this.getUserDataFromLocalStore(),
      [item]: typeof data === 'string' ? data : JSON.stringify(data),
    };
    this.setUserDataToLocalStore(localStorageData as ExtendedLoginResponse);
  }

  private getItemFromLocalstore(item: string): OrNull<any> {
    const data = this.getUserDataFromLocalStore();
    return (data && data[item]) || null;
  }

  public isIndividualCustomer() {
    return this.getItemFromLocalstore('isIndividualCustomer') === true;
  }

  public isRequesterCompany() {
    return this.getItemFromLocalstore('isRequesterCompany') === true;
  }

  public isAdmin(): boolean {
    return this.isAuthenticated() && this.hasRoles([1, 2]);
  }

  public isRoot(): boolean {
    return this.isAuthenticated() && this.hasRoles([1]);
  }

  public hasRoles(roles: number[]): boolean {
    const userRoles = this.getRoles();
    return roles.filter((role) => userRoles.indexOf(role) > -1).length > 0;
  }

  public getRoles(): number[] {
    let roles = this.getItemFromLocalstore('roles');

    if (roles === 'undefined' || isNilOrEmptyString(roles)) {
      roles = [];
    } else {
      roles = JSON.parse(roles);
    }

    return roles;
  }

  public getPropertyFromUserData(property: string) {
    return this.getItemFromLocalstore(property);
  }

  public getUserId() {
    return this.getItemFromLocalstore('userId');
  }

  public getUserEmail() {
    return this.getItemFromLocalstore('email');
  }

  public getAndClearFromUrl() {
    const fromUrl = this.fromUrl;
    this.fromUrl = null;

    return fromUrl;
  }

  public itIsTemporalPassword(): boolean {
    return this._isTemporalPassword;
  }

  // TODO: Review. Added for mobile purposes. //________________________________________________________________________
  public setItemsToLocalStore(items: Partial<LoginResponseModel>) {
    const castItems = items as Partial<ExtendedLoginResponse>;
    const data: ExtendedLoginResponse = { ...this.getUserDataFromLocalStore() } as ExtendedLoginResponse;

    if (castItems && ObjectTS.keys(castItems).length) {
      ObjectTS.keys(castItems).forEach((item) => (data[item] = castItems[item]));
    }

    this.setUserDataToLocalStore(data);
  }

  // TODO: Review. Added for mobile purposes. This mapper should be in mobile.
  public setUserProfile() {
    const userProfile = {
      image: this.getItemFromLocalstore('profileImagePath'),
      title: `${this.getItemFromLocalstore('firstName')} ${this.getItemFromLocalstore('lastName')}`,
      subtitle: this.getItemFromLocalstore('degree'),
      sharingId: this.getItemFromLocalstore('sharingId'),
    };

    this.userProfile$.next(userProfile);
  }

  // TODO: Review. Added for mobile purposes.
  public get userId(): number {
    return parseInt(this.getItemFromLocalstore('userId'), 10);
  }

  public getLicenseId(): OrNull<number> {
    return this.storage.getTItem<number>('currentLicenseId');
  }

  setLicenseList(licenseList: LicenseeLoginResponseModel[] = []) {
    // const currentLicenseId = JSON.parse(localStorage.getItem('currentLicenseId'));
    const currentLicenseId = this.storage.getTItem<number>('currentLicenseId');
    const licenses = licenseList.map((el) => ({ ...el, isCurrent: el.id === currentLicenseId }));
    if (licenses.length) {
      if (!licenses.find((el) => el.isCurrent)) {
        const defaultLicense = this.getDefaultLicense(licenses);
        if (!defaultLicense) {
          licenses[0].isCurrent = true;
        } else {
          defaultLicense.isCurrent = true;
        }
        this.storage.setItem<number>('currentLicenseId', licenses.find((el) => el.isCurrent)?.id as number);
      }
      this.userLicenses$.next(licenses);
      this.updateRoles();
    }
  }

  public setCurrentLicenseByLicenseId(licenseId: number, shouldEmit = false) {
    if (shouldEmit) {
      const licensees = this.userLicenses$.value.map((el) => ({ ...el, isCurrent: el.id === licenseId }));
      this.userLicenses$.next(licensees);
    }
    localStorage.setItem('currentLicenseId', `${licenseId}`);
    this.updateRoles();
  }

  public getLicenseById(licenseId: number): OrUndef<License> {
    return this.userLicenses$.value.find((el) => el.id === licenseId);
  }

  public getLicenseRolesById(licenseId: number) {
    return this.getLicenseById(licenseId)?.roles;
  }

  public getCurrentLicense(): OrUndef<License> {
    return this.userLicenses$.value.find((el) => el.isCurrent);
  }

  public getCurrentLicenseRoles() {
    return this.getCurrentLicense()?.roles;
  }

  public getDefaultLicense(licenses: License[]): OrUndef<License> {
    return licenses.find((el) => el.isDefault);
  }

  public isCustomer(): OrUndef<boolean> {
    const currentLicense = this.getCurrentLicense();
    return currentLicense && currentLicense.roles && (currentLicense.roles.toString().includes('Customer') || currentLicense.roles.toString().includes('LicenseeUserRole_User'));
  }

  public isCustomerAdvanced() {
    const currentLicense = this.getCurrentLicense();
    return !!currentLicense?.roles.toString().includes('CustomerAdvanced');
  }

  public isCustomerBasic() {
    const currentLicense = this.getCurrentLicense();
    return !!currentLicense?.roles.toString().includes('CustomerBasic');
  }

  public isClientBasicLicense() {
    const currentLicense = this.getCurrentLicense();
    return !!currentLicense?.roles.toString().includes('BasicClient');
  }

  public hasQualityControlDashboardPermissions() {
    const currentLicense = this.getCurrentLicense();
    return currentLicense?.hasQualityControlDashboardPermissions;
  }

  private updateRoles() {
    const currentLicense = this.getCurrentLicense();
    this.authorities$.next(currentLicense?.roles ?? []);
    this._setDefaultUrlByRole();
  }

  public selectLicense(licenseId: number, callback: () => void, url: string = '/') {
    this.setCurrentLicenseByLicenseId(licenseId);
    this.requestAccessToken()
      .pipe(take(1))
      .subscribe(() => {
        callback();
        this._router.navigate([url]);
      });
  }

  public isLicenseeAppraiserByLicenseId(licenseId: number) {
    const license = this.userLicenses$.value.find((el) => el.id === licenseId);
    return license && license.roles.some((role) => role.includes(LicenseeUserRoles[LicenseeUserRoles.Appraiser]));
  }

  public isOnlyLicenseeAppraiserById(licenseId: number) {
    return this.getLicenseRolesById(licenseId)?.filter((el) => !el.includes('Module'))?.length === 1 && this.isLicenseeAppraiserByLicenseId(licenseId);
  }

  public reloadLicenses() {
    this._accountService
      .getLicenses()
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe((licenses) => {
        this.setLicenseList(licenses);
      });
  }

  private _setDefaultUrlByRole() {
    // TODO: Esto es provisional para Caja Rural
    if (this.getCurrentLicense()?.id === 30275) {
      this._defaultUrl = `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.LIST}`;
    } else {
      this._defaultUrl = this.isClientBasicLicense()
        ? `/${PATHS.ORDERS}/${PATHS.REQUESTS}/${PATHS.DASHBOARD}`
        : this.isCustomer()
        ? `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.LIST}`
        : `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${PATHS.DASHBOARD}`;
    }
  }

  public getDefaultUrl() {
    return this._defaultUrl;
  }

  setAuthoritiesByAppraisal(appraisal: AppraisalModel) {
    this.authoritiesByCurrentAppraisal$.next(appraisal.licensePlanRoles);
  }

  clearAppraisalAuthorities() {
    this.authoritiesByCurrentAppraisal$.next([]);
  }
}
