import { Component, Input, OnInit } from '@angular/core';

export interface ActionButton {
  icon?: string;
  label?: string;
  class?: string;
  data?: any;
  callback: (data?) => any;
}

@Component({
  selector: 'core-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  @Input() data: any;

  config: ActionButton[];
  constructor() {}

  ngOnInit() {
    this.config = this.data?.actionsConfig;
  }
}
