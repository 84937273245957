import { FormModel } from '@foxeet/domain';
import { AssetCheckInformation } from '@foxeet/domain';
import { groupBy } from 'lodash';

export interface VerificationSection {
  section: string;
  sectionTitle: string;
  items: VerificationItem[];
}

export interface VerificationItem {
  componentId?: string;
  controlName: string;
  label: string;
  value: boolean;
  isVisible: boolean;
}

export const getVerificationDataMapped = (data: AssetCheckInformation[]) => {
  const groupedData = groupBy(data, 'groupKey');
  const formObject = data.reduce(
    (acc: FormModel[], curr) => [
      ...acc,
      {
        name: curr.key,
        defaultValue: curr.value,
        validators: [],
        asyncValidatorFn: [],
      },
    ],
    [],
  );
  const verificationSections = Object.keys(groupedData).reduce((acc: VerificationSection[], section) => {
    const verificationSection = {
      section,
      sectionTitle: section,
      items: groupedData[section].map((item) => ({
        controlName: item.key,
        label: item.translateKey,
        value: item.value,
        isVisible: item.isVisible,
        componentId: 'AT_' + item.key,
      })),
    };
    acc.push(verificationSection);
    return acc;
  }, []);

  return { verificationSections, formObject };
};
