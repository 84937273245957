import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './domain/Typology-chest.config';
import { TypologyReducer } from './redux/typology.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TypologyEffects } from './redux/typology.effects';
import { DataAccessModule } from '@foxeet/data-access';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, TypologyReducer), EffectsModule.forFeature([TypologyEffects]), DataAccessModule],
})
export class TypologyChestModule {}
