import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicContainerComponent } from './basic-container.component';
import { MatIconModule } from '@angular/material/icon';
import { I18nModule } from '@foxeet/utils-modules';

@NgModule({
  declarations: [BasicContainerComponent],
  imports: [CommonModule, MatIconModule, I18nModule.forChild()],
  exports: [BasicContainerComponent],
})
export class BasicContainerModule {}
