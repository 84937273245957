import { AppId, DevelopEnvironment, XPlatform } from '../enums';
/**
 * @todo Cuando se quiera refactorizar los environments
 */
export interface Application {
  id: AppId;
  name: string;
  version: number | string;
  wikiURL: string;
  platform: XPlatform;
}

export interface Environment {
  app: AppId;
  appName: string;
  /**@deprecated */
  appVersion?: string;
  wikiURL: string;
  platform: XPlatform;
  production: boolean;
  version: string;
  wikiURLBancoEsp?: string;
  envName: DevelopEnvironment;
  defaultMobileEndpoint?: string;
  defaultEndpoint: string;
  devEndpoint?: string;
  qaEndpoint?: string;
  prodEndpoint?: string;

  /**
   * THIRD PARTIES KEYS
   */
  mapbox: {
    accessToken: string;
  };
  googleMapsApiKey?: string;
  googleAnalyticsTrackId?: string;
  hotjarId?: string;
}

export interface LanguagesConfig<T> {
  defaultLanguage: string;
  availableLanguages: T;
}
