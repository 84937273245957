import { OcupationStates, OcupationTypes, TenureTypes } from '../enums';

export interface AssetTenureIndexModel {
  AssetId: number;
  WorkflowItemAssetId: number;
  TenureId: number;
  Name: string;
  NationalIdentityDocument: string;
  Percentage: number;
  TenureType: TenureTypes;
}

export interface AssetTenureEditModel {
  id: number;
  name: string;
  nationalIdentityDocument: string;
  percentage: number;
  tenureType: TenureTypes;
}

// REFACTOR
// TODO: Review models when migration finished
export interface AssetTenure {
  id: number;
  name: string;
  nationalIdentityDocument: string;
  percentage: number;
  tenureType: TenureTypes;
}

export interface AssetTenureInformationModel {
  tenures: AssetTenureEditModel[];
  ocupationState: OcupationStates;
  ocupationType: OcupationTypes;
  hasContract: boolean;
  hasNonexistenceLeasesDocument: boolean;
  lesseeNif: string;
}
