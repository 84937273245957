import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { WebFormInputCheckboxModule, WebFormInputPasswordModule, WebFormInputSwitchModule, WebFormInputTextModule } from 'libs/ui/src/lib/form-inputs';
import { ReactiveFormsModule } from '@angular/forms';
import { WebAsyncButtonModule } from '../../../async-button';
import { WebBasicContainerModule } from '../../../basic-container';
import { ConfirmModalComponent } from './confirm-modal.component';
import { MatButtonModule } from '@angular/material/button';

const UI_COMPONENTS = [WebAsyncButtonModule, WebBasicContainerModule, WebFormInputTextModule];

@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [CommonModule, I18nModule.forChild(), ReactiveFormsModule, MatDialogModule, MatButtonModule, ...UI_COMPONENTS],
  exports: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
