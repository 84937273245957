import { Validators } from '@angular/forms';
import { FormModel } from '../form.model';

export enum CadastralLocationFormProps {
  PROVINCE = 'ineCodProv',
  MUNICIPALITY = 'ineCodMun',
  LOCALITY = 'ineCodLocality',
  DGC_CODE_VIA = 'ineCodVia',
  STREET_NUMBER = 'number',
  POLYGON_CODE = 'polygon',
  PLOT_CODE = 'plotCode',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
}

export const cadastralInfoByLocationForm: FormModel[] = [
  {
    name: CadastralLocationFormProps.PROVINCE,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.LOCALITY,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.MUNICIPALITY,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.DGC_CODE_VIA,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.STREET_NUMBER,
    validators: [Validators.required],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.POLYGON_CODE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.PLOT_CODE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.LATITUDE,
    validators: [],
    asyncValidatorFn: [],
  },
  {
    name: CadastralLocationFormProps.LONGITUDE,
    validators: [],
    asyncValidatorFn: [],
  },
];
