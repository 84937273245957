import { AssetTypologies, AssetUses, ConservationStateTypes, HomogeneousCoefficientType, OfferTypes, ValidationTypes, ValorationMethods } from '../enums';
import { ValorationComparableModel } from './valoration-comparable-method.model';

export interface AppraisalReportMarketStudyEditModel {
  id: number;
  reportId: number;
  assetId: number;
  offerType: OfferTypes | undefined;
  isForBuildingElement?: boolean;
  buildingElementSurface?: number;
  commonAssetId: number | null;
  comparables: ValorationComparableModel[];
  name: string;
  use: AssetUses;
  typology: AssetTypologies | undefined;
  homogeneousCoefficientObservations: string;
  homogeneousCoefficientType: HomogeneousCoefficientType;
  homogeneizedUnitValue?: number;
}

export interface AppraisalReportMarketStudyItemModel {
  id: number;
  reportId: number;
  commonAssetId: number;
  valorations: AppraisalReportMarketStudyValorationItemModel[];
  comparables: ValorationComparableModel[];
  name: string;
  use: AssetUses;
  typology: AssetTypologies | undefined;
  homogeneousCoefficientObservations: string;
  homogeneousCoefficientType: HomogeneousCoefficientType;
  homogeneizedUnitValue?: number;
  assetId: number;
  asset: AssetInitialDetailForMarketStudyModel;
  isForBuildingElement: boolean;
  buildingElementSurface: number;
  offerType: OfferTypes | undefined;
}

export interface InitialDataForMarketStudyModel {
  assets: AssetInitialDetailForMarketStudyModel[];
  errors: string;
  validationType: ValidationTypes;
}

export interface AssetInitialDetailForMarketStudyModel {
  use: AssetUses;
  typology: AssetTypologies;
  location: string;
  surface: number;
  height: number;
  latitude: number;
  longitude: number;
  conservationState: ConservationStateTypes;
  constructionYear: number;
  rehabilitationYear: number;
  elevatorCount: number;
  hasGreenArea: boolean;
  hasPool: boolean;
  name: string;
  id: number;
}

export interface AppraisalReportMarketStudyValorationItemModel {
  id: number;
  valorationMethod?: ValorationMethods;
  description: string;
}
