import { CountryModel, LocalityModel, ProvinceModel, StreetModel, ValueOfKey } from '@foxeet/domain';

type CountryKey = ValueOfKey<CountryModel, 'threeLetterIso'>;
type ProvinceKey = ValueOfKey<ProvinceModel, 'ineCod'>;
type LocalityCodeKey = ValueOfKey<LocalityModel, 'ineCodLocality'>;

export type LocalitiesKey = `${CountryKey}-${ProvinceKey}`;
export type ViaKey = `${LocalityCodeKey}`;

export function generateKey(cK: CountryKey, pK: ProvinceKey): LocalitiesKey {
  return `${cK}-${pK}`;
}

export type LocationState = {
  countries: CountryModel[];
  provinces: Record<CountryKey, ProvinceModel[]>;
  localities: { [p: string]: LocalityModel[] };
  vias: Record<ViaKey, StreetModel[]>;
};

export const initialState: LocationState = {
  countries: [],
  provinces: {},
  localities: {},
  vias: {},
};
