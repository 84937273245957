<mat-form-field class="full-width chips-input-container" *ngIf="control">
  <!-- [disabled]="disabled" consider if its necessary to add a disabledInput property -->
  <mat-label>{{ label | translate }}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let selectedItem of selectedItems" [selectable]="selectable" [removable]="removable" (removed)="remove(selectedItem)" [matTooltip]="selectedItem?.label">
      <span>{{ selectedItem?.label | translate }}</span>
      <mat-icon matChipRemove *ngIf="removable" (click)="chipRemove()">cancel</mat-icon>
    </mat-chip-row>
    <input
      #input
      title="{{ componentId }}"
      [id]="componentId"
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let filteredItem of filteredItems | async" [value]="filteredItem">
      <img class="chip-image" *ngIf="showImage && filteredItem?.img" [src]="filteredItem?.img" />
      {{ filteredItem?.label | translate }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
