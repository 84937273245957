import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-calendar',
  templateUrl: './form-input-calendar.component.html',
  styleUrls: ['./form-input-calendar.component.scss'],
})
export class FormInputCalendarComponent extends BaseFormComponent<Date> implements OnInit {
  @Input() showLabel = false;

  @Input() showTime = false;

  @Input() dataType = 'date';

  @Input() minDate?: Date;

  @Input() maxDate?: Date;

  @Input() setTimeToEndOfDay?: boolean;

  @Input() hintLabel?: string;

  @Input() canReset = false;

  @Input() yearRange = `1970:${new Date().getFullYear() + 20}`;

  ngOnInit() {
    this._loaded = true;
    this.setControl();

    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe((el: Date) => {
      this.errors = this.control.errors;
      this.errorKeys = Object.keys(this.control?.errors ?? {});
      if (el && this.setTimeToEndOfDay) {
        const dateWithEndTime = new Date(`${el.toDateString()} 23:59:59`);
        this.control.setValue(dateWithEndTime, { emitEvent: false });
      }
    });
    this.checkDisable();
  }
}
