import { GenericManagerUserService } from '../services/generic-manager-user.service';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ManagerCommercialService extends GenericManagerUserService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'CommercialManager', cache);
  }
}

@Injectable()
export class ManagerTechnicalService extends GenericManagerUserService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'TechnicalManager', cache);
  }
}

@Injectable()
export class ManagerProcessService extends GenericManagerUserService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'ProcessManager', cache);
  }
}
