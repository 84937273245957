import { AssetStates, AssetSubTypologies, AssetSurfaceIds, AssetTypologies, AssetUses, AssetWorkStates, InsuranceValorationTypes, ValorationMethods } from '../enums';
import { ModalModel } from './modal.model';

export interface WorkflowItemAssetValorationIndexModel {
  ValorationId: number;
  AddedUserId: number;
  AddedUserName: string;
  ValorationMethod: ValorationMethods;
  ValorationDatetime: Date;
  ValorationValue: number;
  ValorationMethodTranslateKey: string;
  isMigrationValoration: boolean;
}

//////////////////////////////////////////////////////////////////
// Nuevos modelos

export interface GetAllowedMethodsModel {
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology?: AssetSubTypologies;
  workState?: AssetWorkStates;
}

export interface GetAllowedMethodsResponseModel {
  allowedValorationMethods: ValorationMethods[];
  futureAllowedValorationMethods: ValorationMethods[];
}

export interface ValorationData {
  id: number;
  isClustered: boolean;
  isUnfinished: boolean;
  isRevalorationNeeded: boolean;
  future_IsRevalorationNeeded: boolean;
  clusterId: number;
  valorationValue: number;
  insuranceValorationValueByRoyalDecree: number;
  insuranceValorationValueByEco: number;
  future_ValorationValue: number;
  future_InsuranceValorationValueByRoyalDecree: number;
  future_InsuranceValorationValueByEco: number;
  insuranceValorationType: InsuranceValorationTypes;
  allowedValorationMethods: ValorationMethods[];
  futureAllowedValorationMethods: ValorationMethods[];
  isMigrationValoration: boolean;
  // Obra paralizada
  isWorkOnHold: boolean;
  isTerrain: boolean;
}

export interface AppraisalValorationIndexModel {
  id: number;
  addedUserId: number;
  addedUserName: string;
  valorationMethod: ValorationMethods;
  valorationDatetime: string;
  value: number;
  unitValue: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  valorationMethodTranslateKey: string;
  isMigrationValoration: boolean;
  // Work on hold dynamic residual
  commonAsset_DynamicResidualValorationId: number;
  commonAsset_Id: number;
}

export interface AppraisalReportAssetValorationFilterModel {
  addedMaxDateTime: string;
  addedMinDateTime: string;
  assetId: number;
  assetIds: number[];
  buildingElementId: number;
  buildingElementIds: number[];
  clusterId: number;
  clusterIds: number[];
  excludedAssetId: number;
  excludedAssetIds: number[];
  excludedClusterId: number;
  excludedClusterIds: number[];
  excludedId: number;
  excludedIds: number[];
  excludedValorationMethod: ValorationMethods;
  excludedValorationMethods: ValorationMethods[];
  id: number;
  ids: number[];
  totalAdoptedSurfaceMax: number;
  totalAdoptedSurfaceMin: number;
  valorationMaxDatetime: string;
  valorationMethod: ValorationMethods;
  valorationMethods: ValorationMethods[];
  valorationMinDatetime: string;
  valorationValueMax: number;
  valorationValueMin: number;
}

export interface ValorationModalModel extends ModalModel {
  errors: string[];
}
