import { cloneObject } from '@foxeet/utils/functions';
import { EnumToStringPipe, ToDatePipeByLocale } from '@foxeet/utils-modules';
import { TableCellAlignment, TableCellType, TableColumn, TableConfig } from '../../../../components/generic-tables/domain/table.model';

const columnAssetName: TableColumn = {
  columnId: 'assetName',
  name: 'APPRAISALS_asset',
  sortable: false,
};

const columnFileName: TableColumn = {
  columnId: 'fileName',
  name: 'APPRAISALS_fileName',
  sortable: false,
};

const columnAppraisalVinculationType: TableColumn = {
  columnId: 'parentVinculationType',
  name: 'APPRAISALS_relationshipType',
  cellType: TableCellType.PIPE,
  pipe: EnumToStringPipe,
  keysPipe: ['AppraisalVinculations'],
  sortable: false,
};

const columnParentRecordId: TableColumn = {
  columnId: 'parentRecordId',
  name: 'APPRAISALS_relationshipType',
  sortable: false,
};

const columnSizeInMB: TableColumn = {
  columnId: 'sizeInMBString',
  name: 'APPRAISALS_mbWeight',
  sortable: false,
  alignment: TableCellAlignment.center,
};

const columnCheckBox: TableColumn = {
  columnId: 'checkBox',
  name: '',
  cellType: TableCellType.CHECKBOX,
};

const columnAppraisalDocumentType = {
  columnId: 'appraisalDocumentType',
  name: 'APPRAISALS_documentType',
  cellType: TableCellType.PIPE,
  sortable: false,
};

const columnAppraisalReportAssetDocumentType = {
  columnId: 'appraisalReportAssetDocumentType',
  name: 'APPRAISALS_documentType',
  cellType: TableCellType.PIPE,
  sortable: false,
};

const columnAddedDateTime: TableColumn = {
  columnId: 'addedDateTime',
  name: 'APPRAISALS_documentAddedTime',
  cellType: TableCellType.PIPE,
  pipe: ToDatePipeByLocale,
  keysPipe: ['yyyy-MM-dd HH:mm'],
  sortable: true,
};
const columnObtainedDateTime: TableColumn = {
  columnId: 'obtainedDatetime',
  name: 'APPRAISALS_dateOfIssue',
  cellType: TableCellType.PIPE,
  pipe: ToDatePipeByLocale,
  keysPipe: ['dd-MM-yyyy'],
  sortable: true,
};
const columnDownload: TableColumn = {
  id: 'AT_document_download',
  columnId: 'download',
  name: '',
  cellType: TableCellType.ACTION_BUTTON,
  icon: 'cloud_download',
  callback: () => {
    console.log('Callback');
  },
  sortable: false,
};
const columnPrint: TableColumn = {
  id: 'AT_document_print',
  columnId: 'print',
  name: '',
  cellType: TableCellType.ACTION_BUTTON,
  callback: () => {
    console.log('Callback');
  },
  sortable: false,
};

const columnMenu: TableColumn = {
  columnId: 'menu',
  name: '',
  cellType: TableCellType.OPTIONS_MENU,
  options: [],
  alignment: TableCellAlignment.center,
  callback: (options) => {
    console.log('Callback');
  },
};

const appraisalDocumentsColumns = [
  columnCheckBox,
  columnFileName,
  columnAppraisalDocumentType,
  columnAppraisalVinculationType,
  columnParentRecordId,
  columnAddedDateTime,
  columnObtainedDateTime,
  columnSizeInMB,
  columnDownload,
  columnPrint,
  columnMenu,
];
const appraisalAssetDocumentsColumns = [
  columnCheckBox,
  columnFileName,
  columnAppraisalReportAssetDocumentType,
  columnAddedDateTime,
  columnObtainedDateTime,
  columnSizeInMB,
  columnDownload,
  columnPrint,
  columnMenu,
];

const appraisalDocumentsColumnNames = ['fileName', 'appraisalDocumentType', 'parentRecordId', 'addedDateTime', 'obtainedDatetime', 'sizeInMBString'];
const appraisalAssetDocumentsColumnNames = ['fileName', 'appraisalReportAssetDocumentType', 'addedDateTime', 'obtainedDatetime', 'sizeInMBString'];

export const appraisalDocumentListTableConfig: TableConfig = {
  topButtonActions: [],
  downloadExcelEnabled: false,
  displayedColumns: [...appraisalDocumentsColumnNames],
  columns: [...appraisalDocumentsColumns],
  footer: {},
};

export const appraisalAssetDocumentListTableConfig: TableConfig = {
  topButtonActions: [],
  downloadExcelEnabled: false,
  displayedColumns: ['assetName', ...appraisalAssetDocumentsColumnNames],
  columns: [columnAssetName, ...cloneObject(appraisalAssetDocumentsColumns)],
  footer: {},
};

export const appraisalAssetReportDocumentListTableConfig: TableConfig = {
  topButtonActions: [],
  downloadExcelEnabled: false,
  displayedColumns: [...appraisalAssetDocumentsColumnNames],
  columns: [...cloneObject(appraisalAssetDocumentsColumns)],
  footer: {},
};
