import { ValueOf } from '@foxeet/domain';

export class ObjectUtils {
  static sortAlphabeticallyByProp(arrayToSort: any[], prop: string | number): any[] {
    return arrayToSort.sort((elemA, elemB) => {
      if (elemA[prop] < elemB[prop]) return -1;
      if (elemA[prop] > elemB[prop]) return 1;
      return 0;
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ObjectUtils {
  export const mapObject = <T, K extends keyof T, X, Y extends keyof Y>(
    obj: Record<K, ValueOf<T>>,
    f: (x: Record<K, ValueOf<T>>) => Record<Y, ValueOf<X>>,
  ): Record<Y, ValueOf<X>> => f(obj);
}
