import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from '@foxeet/n-core';

@Component({
  selector: 'ui-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(private _router: Router) {
    this._router.navigateByUrl(PATHS.PAGE_NOT_FOUND);
  }
}
