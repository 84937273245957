import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeLeftPipe' })
export class TimeLeftPipe implements PipeTransform {
  private placeholder = '-';

  transform(date: Date): string {
    if (!date) {
      return this.placeholder;
    }

    const currentDate = new Date().getTime();
    const futureDate = new Date(date).getTime();
    const result: number = futureDate - currentDate;
    const minutes = result > 0 ? `${Math.floor(result / 60000).toString()} min` : this.placeholder;

    return minutes;
  }
}
