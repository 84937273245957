import { Injectable } from '@angular/core';
import { WorkflowService } from '../services/workflow.service';
import { CacheService } from '../services/cache.service';
import { HttpClient } from '@angular/common/http';
import { removeNullsAndUndefined } from '../utils/data-access.utils';

@Injectable()
export class GlobalSearchService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'View/GlobalSearch', cache);
  }

  getDataFromSearch<T>(page: string | number, pagesize: string | number, query: string) {
    return this.http.post<T>(`${this.apiBranch}/ListPaged`, null, { params: this.setParams({ page, pagesize, query }) });
  }
}
