import { AssetSurfaceData, AssetSurfaceSelectorModel, AssetSurfacesInformationDto, AssetUses, InternationalizationTypes, OrNull } from '@foxeet/domain';
import { isNil, ObjectTS } from './javascript.functions';

export interface SurfacesChildData {
  name: string;
  value: number;
  id: number;
  header?: string; // mobile purposes
}

export interface SurfaceSelectData {
  header?: string;
  childs: SurfacesChildData[];
}

export class AppraisalAssetSurfaceSelectedDataUtils {
  public static surfacesSubTypes: { name: string; value: keyof AssetSurfaceData; id: keyof AssetSurfaceData }[] = [
    {
      name: 'surfaceCommons',
      value: 'totalBuiltSurfaceWithCommonSurfaces',
      id: 'totalBuiltSurfaceWithCommonSurfacesId',
    },
    {
      name: 'surfaceWithoutCommons',
      value: 'totalBuiltSurfaceWithoutCommonSurfaces',
      id: 'totalBuiltSurfaceWithoutCommonSurfacesId',
    },
    {
      name: 'surfaceUseful',
      value: 'totalUsefulSurface',
      id: 'totalUsefulSurfaceId',
    },
  ];

  private static plotSurfacesSubTypes: { name: string; value: keyof AssetSurfaceData; id: keyof AssetSurfaceData }[] = [
    {
      name: 'ASSETS_totalPlotSurface',
      value: 'totalPlotSurface',
      id: 'totalPlotSurfaceId',
    },
  ];

  public static surfaces: { header: string; parentProp: keyof Omit<AssetSurfacesInformationDto, 'surfacesObservations'> }[] = [
    { header: 'cadastral', parentProp: 'cadastralSurfaces' },
    { header: 'checked', parentProp: 'checkedSurfaces' },
    { header: 'registered', parentProp: 'registeredSurfaces' },
  ];

  static createSurfaceSelectDataIncludingEmpties(assetSurfacesInformation: AssetSurfacesInformationDto): SurfaceSelectData[] {
    return this.surfaces.map((el) => {
      const { [el.parentProp]: prop } = assetSurfacesInformation;
      return {
        header: el.header,
        childs: this.surfacesSubTypes.map((surface) => ({
          name: surface.name,
          id: prop ? prop[surface.id] : 0,
          value: prop ? prop[surface.value] : 0,
          header: el.header,
        })),
      };
    }) as SurfaceSelectData[];
  }

  static createSurfaceSelectData(assetSurfacesInformation: AssetSurfacesInformationDto): SurfaceSelectData[] {
    const selectData = this.createSurfaceSelectDataIncludingEmpties(assetSurfacesInformation);
    // Filter elements that haven't got value
    const result = selectData.filter((el) => !!(el.childs = el.childs.filter((subEl) => !!subEl.value)).length);
    return result;
  }

  static surfacesToPrimeNgDropdown(assetSurfacesInformation: AssetSurfacesInformationDto) {
    const surfaceList = this.surfaces.reduce((acc: any[], curr) => {
      const section = assetSurfacesInformation[curr.parentProp];
      const surfaceGroup: { label: string; items: any[] } = { label: curr.header, items: [] };
      const surfacesByType: (typeof surfaceGroup)[] = [];
      this.surfacesSubTypes.forEach((surface) => {
        if (section && section[surface.value]) {
          surfaceGroup.items.push({
            label: surface.name,
            value: section[surface.id],
            selectedValue: section[surface.value],
            groupLabel: curr.header,
          });
        }
      });
      surfacesByType.push(surfaceGroup);
      return acc.concat(surfacesByType);
    }, []);

    return surfaceList;
  }

  static plotSurfacesToPrimeNgDropdown(assetSurfacesInformation: AssetSurfacesInformationDto) {
    const surfaceList = this.surfaces.reduce((acc: any[], curr) => {
      const section = assetSurfacesInformation[curr.parentProp];
      const surfaceGroup: { label: string; items: any[] } = { label: curr.header, items: [] };
      const surfacesByType: (typeof surfaceGroup)[] = [];
      this.plotSurfacesSubTypes.forEach((surface) => {
        if (section && section[surface.value]) {
          surfaceGroup.items.push({
            label: surface.name,
            value: section[surface.id],
            selectedValue: section[surface.value],
            groupLabel: curr.header,
          });
        }
      });
      surfacesByType.push(surfaceGroup);
      return acc.concat(surfacesByType);
    }, []);

    return surfaceList;
  }

  static getSurfaceValueByAdoptedSurfaceId(assetSurfacesInformation: Omit<AssetSurfacesInformationDto, 'surfacesObservations'>, id: number) {
    if (isNil(id)) {
      return null;
    }

    let parentAdoptedSurfaceKey: OrNull<keyof Omit<AssetSurfacesInformationDto, 'surfacesObservations'>> = null;
    let adoptedSurfaceKey: OrNull<keyof AssetSurfaceData> = null;

    ObjectTS.keys<Omit<AssetSurfacesInformationDto, 'surfacesObservations'>>(assetSurfacesInformation).forEach((parentKey) => {
      let keyFound;
      if (assetSurfacesInformation[parentKey]) {
        keyFound = ObjectTS.keys(assetSurfacesInformation[parentKey])
          .filter((key) => (key as string).includes('Id'))
          .find((key) => {
            const { [parentKey]: parent } = assetSurfacesInformation;
            return parent ? parent[key] === id : false;
          });
      }
      if (keyFound) {
        parentAdoptedSurfaceKey = parentKey;
        adoptedSurfaceKey = keyFound;
      }
    });

    if (isNil(adoptedSurfaceKey) || isNil(parentAdoptedSurfaceKey)) {
      return null;
    }

    return assetSurfacesInformation[parentAdoptedSurfaceKey][(adoptedSurfaceKey as string).slice(0, (adoptedSurfaceKey as string).length - 2)];
  }

  static createPlotSurfaceSelectDataIncludingEmpties(assetSurfacesInformation: AssetSurfacesInformationDto): SurfaceSelectData[] {
    return this.surfaces.map((el) => {
      const { [el.parentProp]: prop } = assetSurfacesInformation;
      return {
        header: el.header,
        childs: this.plotSurfacesSubTypes.map((surface) => {
          const { [surface.id]: id, [surface.value]: value } = prop ?? ({} as any);
          return {
            name: surface.name,
            id,
            value,
          };
        }),
      };
    });
  }

  static createPlotSurfaceSelectData(assetSurfacesInformation: AssetSurfacesInformationDto): SurfaceSelectData[] {
    const selectData = this.createPlotSurfaceSelectDataIncludingEmpties(assetSurfacesInformation);
    // Filter elements that haven't got  value
    const result = selectData.filter((el) => !!(el.childs = el.childs.filter((subEl) => !!subEl.value)).length);
    return result;
  }

  static from_AssetSurfaceSelectorModel_to_SurfaceSelectData(surfaces: AssetSurfaceSelectorModel): SurfaceSelectData[] {
    return surfaces.groups.map((group) => {
      return {
        header: `AssetSurfaceGroup_${group.groupId}`,
        childs: group.surfaces.map((surface) => ({
          name: surface.name,
          value: surface.value,
          id: surface.id,
        })),
      };
    });
  }

  /**
   * Mobile purpose only
   */

  static getSurfaceSelectorData(selectedSurfaceId: number, surfaceTypes: SurfaceSelectData[]) {
    if (!isNil(selectedSurfaceId)) {
      return surfaceTypes.reduce((acc: any[], curr) => [...acc, ...curr.childs], []).find((el) => el.id === selectedSurfaceId);
    }
  }
}
