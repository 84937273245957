import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-stars-rating',
  templateUrl: './stars-rating.component.html',
})
export class StarsRatingComponent implements AfterViewInit {
  @Input() maxRating = 5;
  @Input() score: number = 0;
  @Input() id = 'stars';

  private _starWidth = 20;
  private _starsContainer: any = null;

  ngAfterViewInit(): void {
    this._starsContainer = document.getElementById(this.id);
    this._initStars();
  }

  private _initStars() {
    const fullstars = Math.trunc(this.score);
    const percentageStar = this.score - fullstars;

    const svgns = 'http://www.w3.org/2000/svg';
    const svg = this._createSVG(svgns);

    let i = 0;
    while (i < this.maxRating) {
      const percentage = i + 1 <= fullstars ? 100 : i > fullstars ? 0 : percentageStar * 100;
      if (percentage < 100 && percentage > 0) this._getGradient(svgns, svg, '' + i, percentage);
      this._createPath(svgns, svg, '' + i, percentage);
      i++;
    }

    this._starsContainer.appendChild(svg);
  }

  private _createSVG(svgns: any) {
    const svg = document.createElementNS(svgns, 'svg');
    svg.setAttribute('id', `svg-${this.id}`);
    svg.setAttribute('viewBox', `0 0 ${this.maxRating * this._starWidth + 12 * this.maxRating} ${this._starWidth * 2}`);
    this._getFullGradient(svgns, svg);
    this._getVoidGradient(svgns, svg);

    return svg;
  }

  private _setStopsGradient(stops: { offset: string; color: string }[], svgns: any, gradient: any) {
    stops.forEach((stop) => {
      const el = document.createElementNS(svgns, 'stop');
      el.setAttribute('offset', stop.offset);
      el.setAttribute('stop-color', stop.color);

      gradient.appendChild(el);
    });
  }

  private _getGradientId(index: string) {
    return `Gradient-${this.id}-${index}`;
  }

  private _getFullGradient(svgns: any, svg: any) {
    const stops = [
      { color: '#fec526', offset: `100%` },
      { color: '#e5e5e5', offset: '0%' },
    ];
    const defs = document.createElementNS(svgns, 'defs');
    const gradient = document.createElementNS(svgns, 'linearGradient');

    this._setStopsGradient(stops, svgns, gradient);

    gradient.id = 'Gradient';
    defs.appendChild(gradient);
    svg.appendChild(defs);
  }

  private _getVoidGradient(svgns: any, svg: any) {
    const stops = [
      { color: '#fec526', offset: `0%` },
      { color: '#e5e5e5', offset: '0%' },
    ];
    const defs = document.createElementNS(svgns, 'defs');
    const gradient = document.createElementNS(svgns, 'linearGradient');

    this._setStopsGradient(stops, svgns, gradient);

    gradient.id = 'Gradient-Void';
    defs.appendChild(gradient);
    svg.appendChild(defs);
  }

  private _getGradient(svgns: any, svg: any, index: string, percentage: number) {
    const defs = document.createElementNS(svgns, 'defs');
    const gradient = document.createElementNS(svgns, 'linearGradient');
    const stops = [
      { color: '#fec526', offset: `${percentage}%` },
      { color: '#e5e5e5', offset: '0%' },
    ];

    this._setStopsGradient(stops, svgns, gradient);

    gradient.id = this._getGradientId(index);
    defs.appendChild(gradient);
    svg.appendChild(defs);
  }

  private _createPath(svgns: any, svg: any, index: string, percentage: number) {
    const path = document.createElementNS(svgns, 'path');
    path.setAttribute('fill', `url(#${percentage === 0 ? 'Gradient-Void' : percentage < 100 ? this._getGradientId(index) : 'Gradient'})`);
    path.setAttribute('d', 'M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.11 l11.547-1.2L16.026,0.6L20.388,10.918z');
    path.setAttribute('style', `transform:translate(${+index * this._starWidth + 10 * +index}px, 0px)`);

    svg.appendChild(path);
  }
}
