<span class="md-inputfield p-float-label mt-4 span-margin" *ngIf="control" [ngClass]="{ readonly: readonly }">
  <p-calendar
    [ngClass]="{ 'p-disabled': control?.disabled || disabled }"
    [id]="componentId"
    appendTo="body"
    [formControl]="control"
    dateFormat="dd/mm/yy"
    [dataType]="dataType"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showTime]="showTime"
    [readonlyInput]="true"
    [showOnFocus]="!readonly && !control?.disabled"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [yearRange]="yearRange"
  >
  </p-calendar>
  <button *ngIf="!control?.disabled && canReset" class="resetButton" mat-icon-button (click)="control?.reset()"><mat-icon>close</mat-icon></button>
  <label for="{{ componentId }}">{{ label | translate }}</label>
  <span *ngIf="hintLabel" class="hintLabelClass">{{ hintLabel | translate }}</span>
  <ui-form-validation-messages [ngClass]="{ moveErrorRight: hintLabel }" [control]="control"></ui-form-validation-messages>
</span>
