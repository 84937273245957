import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppraisalIndexModel, LicenseeTypes, WorkerRoles } from '@foxeet/domain';
import { GenericMap } from './../../../../components/generic-tables/mappers/generic.map';
import { PATHS, SUB_PATHS } from '@foxeet/n-core';
import { EntitiesUtils, WorkersUtils } from '@foxeet/utils/functions';

@Injectable({ providedIn: 'root' })
export class ExternalConnectionsUnicajaAppraisalListMap implements GenericMap {
  constructor(private _router: Router) {}

  bodyMap(items: AppraisalIndexModel[], link: string, queryParams: any) {
    console.log(queryParams);
    return !items
      ? []
      : items.map((item) => {
          return {
            ...item,
            customerFullName: EntitiesUtils.getEntityFullName(item.workflowItemLicensees, LicenseeTypes.Customer),
            clickable: true,
            queryParams,
            link: `${link}/${item.id}`,
            clickEvent: ({ link }: { link: string }) => this._router.navigate([link], { queryParams }),
          };
        });
  }
}
