import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable()
export class MatPaginatorIntlCustomService extends MatPaginatorIntl implements OnDestroy {
  private _componentDestroyed: Subject<any> = new Subject();

  /** A label for the page size selector. */
  itemsPerPageLabel: string;

  /** A label for the button that increments the current page. */
  nextPageLabel: string;

  /** A label for the button that decrements the current page. */
  previousPageLabel: string;

  /** A label for the button that moves to the first page. */
  firstPageLabel: string;

  /** A label for the button that moves to the last page. */
  lastPageLabel: string;

  constructor(private _translateService: TranslateService) {
    super();

    this._addTranslations();
    this._getTranslates();

    this._translateService.onLangChange.pipe(takeUntil(this._componentDestroyed)).subscribe((res) => {
      this._getTranslates();
      this.changes.next();
    });
  }

  private _getTranslates() {
    this.itemsPerPageLabel = this._translateService.instant('itemsPerPageLabel');
    this.nextPageLabel = this._translateService.instant('nextPageLabel');
    this.previousPageLabel = this._translateService.instant('previousPageLabel');
    this.firstPageLabel = this._translateService.instant('firstPageLabel');
    this.lastPageLabel = this._translateService.instant('lastPageLabel');
  }

  private _addTranslations() {
    this._translateService.setTranslation(
      'es',
      {
        itemsPerPageLabel: 'Elementos por página',
        nextPageLabel: 'Siguiente página',
        previousPageLabel: 'Pagina anterior',
        firstPageLabel: 'Primera página',
        lastPageLabel: 'Última página',
      },
      true,
    );

    this._translateService.setTranslation(
      'en',
      {
        itemsPerPageLabel: 'Items per page',
        nextPageLabel: 'Next page',
        previousPageLabel: 'Previous page',
        firstPageLabel: 'First page',
        lastPageLabel: 'Last page',
      },
      true,
    );

    this._translateService.setTranslation(
      'pt',
      {
        itemsPerPageLabel: 'Itens por página',
        nextPageLabel: 'Página seguinte',
        previousPageLabel: 'Página anterior',
        firstPageLabel: 'Primeira página',
        lastPageLabel: 'Última página',
      },
      true,
    );
  }

  ngOnDestroy() {
    this._componentDestroyed.next('');
    this._componentDestroyed.unsubscribe();
  }
}
