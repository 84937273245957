import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { I18nModule } from '@foxeet/utils-modules';
import { FormInputTextAreaComponent } from './form-input-textarea.component';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';

@NgModule({
  declarations: [FormInputTextAreaComponent],
  imports: [CommonModule, InputTextareaModule, ReactiveFormsModule, I18nModule.forChild(), FormValidationMessagesModule],
  exports: [FormInputTextAreaComponent],
})
export class FormInputTextAreaModule {}
