import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebAsyncButtonModule, WebFormInputPasswordModule, WebFormInputTextModule, WebModalsModule } from '@foxeet/ui';
import { I18nModule } from '@foxeet/utils-modules';
import { ChangeTemporalPasswordComponent, LoginComponent, PasswordRestoreComponent, RequirePasswordChangeComponent } from './components';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ChangePasswordModalComponent } from './modals';
import { DataStorageService, FactoryLocalDataStorageService } from '@foxeet/utils/services/storage.service';
import { ShowIfHasAuthoritiesDirective } from './directives/show-if-has-authorities.directive';
import { EditableIfHasAuthoritiesPipe } from './pipes/editable-if-has-authorities.pipe';

const MATERIAL_COMPONENTS = [MatMenuModule, MatListModule, MatIconModule, MatButtonModule];
const UI_COMPONENTS = [WebAsyncButtonModule, WebFormInputTextModule, WebFormInputPasswordModule, WebModalsModule];

const COMPONENTS = [LoginComponent, ChangeTemporalPasswordComponent, PasswordRestoreComponent, RequirePasswordChangeComponent, ProfileMenuComponent, ChangePasswordModalComponent];

const DIRECTIVES = [ShowIfHasAuthoritiesDirective];
const PIPES = [EditableIfHasAuthoritiesPipe];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, I18nModule.forChild(), UI_COMPONENTS, MATERIAL_COMPONENTS],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  providers: [
    {
      provide: DataStorageService,
      useFactory: FactoryLocalDataStorageService,
    },
  ],
  exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class AuthModule {}
