import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ASYNC_BUTTON_STATE } from '@foxeet/ui';
import { CUSTOM_EMAIL } from '@foxeet/utils/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { AuthService } from '../../providers';

@Component({
  selector: 'feature-require-password-change',
  templateUrl: './require-password-change.component.html',
  styleUrls: ['./require-password-change.component.scss'],
})
export class RequirePasswordChangeComponent implements OnInit {
  private readonly _mobileQueryListener: () => void;
  public mobileQuery: MediaQueryList;

  public requirePasswordChangeForm!: FormGroup;
  public requirePasswordChangeButtonState = new Subject<ASYNC_BUTTON_STATE>();

  constructor(
    private _router: Router,
    private _media: MediaMatcher,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastrWrapperService: ToastrWrapperService,
  ) {
    this.mobileQuery = this._media.matchMedia('(max-width: 780px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('mobileQueryMatchWidth', this._mobileQueryListener);
  }

  ngOnInit() {
    this.requirePasswordChangeForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(CUSTOM_EMAIL.pattern)]],
    });
  }

  public getErrorMessage(formControl: AbstractControl): string {
    return formControl.hasError('required') ? this._translateService.instant('required') : formControl.hasError('pattern') ? this._translateService.instant('not_valid_email') : '';
  }

  public requirePasswordChange() {
    this.requirePasswordChangeButtonState.next(ASYNC_BUTTON_STATE.SENDING);
    const email = this.requirePasswordChangeForm.get('email')?.value;
    this._authService
      .sendMailToRestoreThePassword(email)
      .pipe(finalize(() => this.requirePasswordChangeButtonState.next(ASYNC_BUTTON_STATE.IDLE)))
      .subscribe(() => {
        this._toastrWrapperService.success('REQUIRE_PASSWORD_CHANGE_sendEmailSuccessMsg', 'REQUIRE_PASSWORD_CHANGE_sendEmailSuccessTitle');
      }, this._toastrWrapperService.errorHandler);
  }

  public cancel() {
    this._router.navigate(['/']);
  }
}
