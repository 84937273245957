import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { I18nModule } from '@foxeet/utils-modules';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputCheckboxComponent } from './form-input-checkbox.component';

@NgModule({
  declarations: [FormInputCheckboxComponent],
  imports: [CommonModule, CheckboxModule, FormValidationMessagesModule, ReactiveFormsModule, I18nModule.forChild()],
  exports: [FormInputCheckboxComponent],
})
export class FormInputCheckboxModule {}
