import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { defaultCropperOptions } from '../../../image-cropper-customizer';

export interface CoreProfileImageUploaderModel {
  callback?: (id: number, file: File) => Observable<HttpEvent<unknown>>;
  userId?: number;
  aspectRatio?: number;
  roundCropper?: boolean;
  imageCropperOptions?: any;
}

@Component({
  selector: 'ui-profile-image-uploader',
  templateUrl: './profile-image-uploader.component.html',
  styleUrls: ['./profile-image-uploader.component.scss'],
})
export class CoreProfileImageUploaderComponent extends UnsuscriptionHandler implements OnInit {
  private callback?: (userId: number, file: File) => Observable<HttpEvent<unknown>>;
  private _userId?: number;
  private _imageCropped: File;

  public files: any[];
  public fileEvent: Event;
  public defaultImageCropperOptions = defaultCropperOptions;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CoreProfileImageUploaderModel,
    @Optional() private dialogRef: MatDialogRef<CoreProfileImageUploaderComponent>,
    private _toastrWrapperService: ToastrWrapperService,
  ) {
    super();
  }

  ngOnInit() {
    this.callback = this.data.callback;
    this._userId = this.data.userId;
  }

  uploadImage($event: Event) {
    this.fileEvent = $event;
  }

  public setCroppedImage(file: File) {
    this._imageCropped = file;
  }

  saveProfileImage() {
    if (this.callback && this._userId) {
      this.callback(this._userId, this._imageCropped)
        .pipe(takeUntil(this._componentDestroyed))
        .subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            if (event.ok) {
              this._toastrWrapperService.success();
              this.dialogRef.close(event.body);
            }
          }
        }, this._toastrWrapperService.errorHandler);
    } else {
      this.dialogRef.close(this._imageCropped);
    }
  }

  public closeEventTrigger() {
    this.dialogRef.close();
  }
}
