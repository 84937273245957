import { TutorialModalComponent } from './tutorial-modal/tutorial-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { WebFormInputPasswordModule } from '../../../form-inputs/form-input-password';
import { WebAsyncButtonModule } from '../../async-button';
import { WebBasicContainerModule } from '../../basic-container';
import { WebImageCropperModule } from '../../image-cropper-customizer';
import { ConfirmExitModalComponent } from './confirm-exit-modal/confirm-exit-modal.component';
import { DebugModalComponent } from './debug-modal/debug-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { InternationalizationModalComponent } from './internationalization-modal/internationalization-modal.component';
import { MyWorkModal } from './my-work-modal/my-work-modal';
import { SeveralConfirmButtonsModalComponent } from './several-options-modal/several-confirm-buttons-modal.component';
import { CoreProfileImageUploaderComponent } from './user-profile-picture/profile-image-uploader.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { WebFormInputCheckboxModule, WebFormInputSwitchModule, WebFormInputTextModule } from '../../../form-inputs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WebConfirmModalModule } from './confirm-modal';

const UI_COMPONENTS = [
  WebAsyncButtonModule,
  WebBasicContainerModule,
  WebFormInputPasswordModule,
  WebFormInputTextModule,
  WebImageCropperModule,
  WebFormInputCheckboxModule,
  WebFormInputSwitchModule,
  WebConfirmModalModule,
];

const modals = [
  CoreProfileImageUploaderComponent,
  DebugModalComponent,
  ConfirmExitModalComponent,
  InternationalizationModalComponent,
  MyWorkModal,
  InfoModalComponent,
  SeveralConfirmButtonsModalComponent,
  TutorialModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    I18nModule.forChild(),
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    MatStepperModule,
    MatButtonModule,
    PipesModule,
    ClipboardModule,
    ...UI_COMPONENTS,
  ],
  declarations: [...modals],
  exports: [...modals],
})
export class ModalsModule {}
