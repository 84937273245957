<mat-label class="title" *ngIf="title">{{ title | translate }}</mat-label>

<div class="container-fluid">
  <div class="row" *ngFor="let row of model">
    <ng-container *ngFor="let element of row">
      <div *ngIf="!element?.isHidden" [class]="getCalculatedClasses(element)" class="d-flex align-items-center">
        <div *ngIf="element?.isTitleSection" class="title-section">
          <h4 [ngClass]="{ 'disabled-item': element?.isDisabled || disabled }">{{ element?.label | translate }}</h4>

          <button *ngIf="element?.button" mat-icon-button aria-label="Copy data" (click)="element?.button.callback()">
            <mat-icon>file_copy</mat-icon>
          </button>
        </div>
        <div *ngIf="element?.isSubTitleSection" class="title-section">
          <p class="small">{{ element?.subLabel | translate }}</p>
        </div>

        <button *ngIf="element?.isInfoButton" mat-icon-button [matTooltip]="element?.infoButtonMessage | translate">
          <mat-icon>info</mat-icon>
        </button>

        <div [ngClass]="element?.extraClasses" [ngSwitch]="element?.type" class="full-width">
          <ng-container *ngSwitchCase="FilterInputTypes.CHECK_BOX">
            <ui-form-input-checkbox
              *uiPlanBadge="{ authorityRoot: element?.authorityRoute, label: 'Comfort/Premium' }"
              [componentId]="element?.id"
              [form]="modelFormGroup"
              [controlName]="element?.controlName"
              [label]="element?.labelCode | translate : element?.internationalizationType"
              [disabled]="element?.isDisabled || disabled"
            ></ui-form-input-checkbox>
          </ng-container>

          <!-- OPTIONLABEL y OPTIONVALUE tienen que estar encima de OPTIONS -->
          <ui-form-input-dropdown
            *ngSwitchCase="FilterInputTypes.COMBO"
            [componentId]="element?.id"
            [optionLabel]="element?.optionLabel"
            [optionValue]="element?.optionValue"
            [options]="element?.options"
            [form]="modelFormGroup"
            [label]="
              (element?.label$ | async)
                ? (element?.label$ | async | translate : element?.internationalizationType)
                : (element?.labelCode | translate : element?.internationalizationType)
            "
            [controlName]="element?.controlName"
            [activeFilter]="element?.activeFilter || false"
            [filterBy]="element?.filterBy ?? 'translatedLabel'"
            [activeVirtualScroll]="element?.activeVirtualScroll || false"
            [placeholder]="element?.placeholder | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [showClear]="!!element?.showClear"
          ></ui-form-input-dropdown>

          <ui-form-input-text
            *ngSwitchCase="FilterInputTypes.TEXT"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [tooltip]="element?.tooltip | translate"
            [showButton]="element?.showInputButton"
            [buttonIcon]="element?.buttonIcon"
            [buttonColor]="element?.buttonColor"
            [callback]="element?.callback"
          ></ui-form-input-text>

          <ui-form-input-textarea
            *ngSwitchCase="FilterInputTypes.TEXT_AREA"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [autoResize]="element?.autoResize || false"
          ></ui-form-input-textarea>

          <ui-form-input-calendar
            *ngSwitchCase="FilterInputTypes.DATE_PICKER"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [setTimeToEndOfDay]="element?.setTimeToEndOfDay || false"
            [hintLabel]="element?.subLabelCode"
            [showLabel]="element?.showLabel"
            [dataType]="element?.dataType || 'date'"
            [canReset]="element?.canReset"
          ></ui-form-input-calendar>

          <ui-form-input-triple-switch
            *ngSwitchCase="FilterInputTypes.TRIPLE_SWITCH"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
          ></ui-form-input-triple-switch>

          <ui-form-input-multiselect
            *ngSwitchCase="FilterInputTypes.MULTISELECT"
            [componentId]="element?.id"
            [options]="element?.options"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [labelKeys]="element?.labelKeys"
            [indexBy]="element?.indexBy"
            [controlName]="element?.controlName"
            [appendToBody]="element?.appendToBody"
            [form]="modelFormGroup"
            [disabled]="element?.isDisabled || disabled"
          ></ui-form-input-multiselect>

          <ui-form-input-number
            *ngSwitchCase="FilterInputTypes.NUMBER"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [suffix]="element?.suffix ?? ''"
            [suffixType]="element?.internationalizationType"
            [digitsInfo]="element?.digitsInfo ?? '.2-2'"
            [enableFormat]="element?.enableFormat ?? true"
            [min]="element?.min"
          ></ui-form-input-number>

          <ui-form-input-mask
            *ngSwitchCase="FilterInputTypes.MASK"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [mask]="element?.mask || null"
            [slotChar]="element?.slotChar || ''"
          ></ui-form-input-mask>

          <ui-form-input-chips
            *ngSwitchCase="FilterInputTypes.CHIPS_SEARCHER"
            [componentId]="element?.id"
            class="full-width"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [minimumCharsToEmitEvent]="element?.minimumCharsToEmitEvent"
            [listSubject]="element?.listSubject"
            [filterName]="element?.filterName"
            [labelKeys]="element?.labelKeys"
            [valueKey]="element?.valueKey"
            [maxSelection]="element?.maxSelection"
            [disabled]="element?.isDisabled || disabled"
            [showImage]="element?.showImage"
          ></ui-form-input-chips>

          <ui-form-input-password
            *ngSwitchCase="FilterInputTypes.PASSWORD"
            [componentId]="element?.id"
            [form]="modelFormGroup"
            [controlName]="element?.controlName"
            [label]="element?.labelCode | translate : element?.internationalizationType"
            [disabled]="element?.isDisabled || disabled"
            [showPasswordStrength]="element?.showPasswordStrength || false"
            [showPasswordBtn]="element?.showPasswordBtn || true"
          ></ui-form-input-password>

          <ui-async-button
            *ngSwitchCase="FilterInputTypes.ASYNC_BUTTON"
            [asyncButton]="false"
            [buttonClassByType]="'primary'"
            [size]="'md'"
            [label]="element?.label | translate"
            [componentId]="'AT_' + element?.label"
            [loadingLabel]="element?.loadingLabel | translate"
            (clickEvent)="element?.callback()"
          ></ui-async-button>
        </div>

        <mat-icon
          *ngIf="!element?.isHidden && element?.outItemInfoIcon"
          [matTooltip]="element.outItemInfoIcon.label | translate"
          [matTooltipPosition]="element.outItemInfoIcon.position || 'above'"
          [ngClass]="{
            'mat-icon-primary': element?.outItemInfoIcon.color === 'primary',
            'mat-icon-secondary': element?.outItemInfoIcon.color === 'secondary'
          }"
          class="ml-2 mb-1 dynamic-form-info-icon"
          (click)="element?.outItemInfoIcon.callback()"
          >info
        </mat-icon>
      </div>
    </ng-container>
  </div>
</div>
