import { AppraisalStepData } from './appraisal.model';

export interface BaseDashboardModel {
  AppraisalsInProcessCount: number;
  AppraisalsPendingAssignAppraiserCount: number;
  AppraisalsWithAppraiserWorkingCount: number;
  AppraisalsFinishedCount: number;
  AppraisalsWithIncidencesNotResolvedCount: number;
}

export interface DashboardModel {
  AppraisalsCount: number;
  TotalIncidents: number;
  ElapsedTimeOfIncidents: string;
  TotalClaims: number;
  ElapsedTimeOfClaims: string;
  AppraisalsWithIncidencesNotResolvedCount: number;
  AppraisalsWithClaimsNotResolvedCount: number;
  VisintPendingDeadlineExpired: number;
  DataSentDeadlineExpired: number;
  TotalAppraisals: number;
  TotalAverageTime: string;
  AppraisalStepData: AppraisalStepData[];
}

export interface DashboardOrderModel {
  OrdersCount: number;
  OrdersCancelledCount: number;
  OrderStepData: AppraisalStepData[];
}

export interface WorkflowItemStepDataModel {
  currentStep: string;
  numberOfWorkflowItems: number;
  averageTime: string;
}
