export interface AppraisalPortfolioIndexModel {
  id: number;
  name: string;
  lastUpdateDateTime: string;
  lastUpdateUserId: number;
  addedDateTime: string;
  addedUserId: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
}

export interface AppraisalPortfolioItemModel {
  id: number;
  name: string;
  lastUpdateDateTime: string;
  lastUpdateUserId: number;
  addedDateTime: string;
  addedUserId: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  // addedUser:	IUser{...}
  // lastUpdatedUser:	IUser{...}
}

export interface AppraisalPortfolioEditAppraisalsModel {
  id: number;
  appraisalIds: number[];
}
