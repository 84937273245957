import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setCountries, setCountriesAPI, setLocalities, setLocalitiesAPI, setProvinces, setProvincesAPI, setVias, setViasAPI } from './location.actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AllLocaties, AllProvinces, Countries, AllVias } from './location.selectors';
import { of, Subscription } from 'rxjs';
import { LocationState } from './location.state';
import { CountryModel, LocalityModel, ProvinceModel } from '@foxeet/domain';
import { LocationService } from '@foxeet/data-access';
import { ObjectTS } from '../to-delete/bucket';

type KeysProvincesState = keyof LocationState['provinces'];
type KeysLocalitiesState = keyof LocationState['localities'];
type KeysViasState = keyof LocationState['vias'];

/**
 * @todo Comentar o modificar los filters para que se lancen cuando hayan distintas traducciones de la info
 */
@Injectable()
export class LocationEffects implements OnDestroy {
  private nCountries = 0;
  private provincesSetted: Array<KeysProvincesState> = [];
  private localitiesSetted: Array<KeysLocalitiesState> = [];
  private viasSetted: Array<KeysViasState> = [];

  private readonly subs = new Subscription();

  setCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCountries),
      filter(() => !this.nCountries),
      mergeMap(() => this.setCountries()),
    ),
  );

  setProvinces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProvinces),
      filter((country) => !this.provincesSetted.find((pSet) => pSet === country.threeLetterIso)),
      mergeMap((country) => this.setProvinces(country)),
    ),
  );

  setLocalities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLocalities),
      filter(({ country, province }) => !this.localitiesSetted.find((lSet) => lSet === `${country.threeLetterIso}-${province.ineCod}`)),
      mergeMap(({ country, province }) => this.setLocalities(country, province)),
    ),
  );

  setVias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setVias),
      filter(({ locality }) => !this.viasSetted.find((lSet) => lSet === `${locality.ineCodLocality}`)),
      mergeMap(({ locality, province }) => this.setVias(locality, province)),
    ),
  );

  constructor(private actions$: Actions, private readonly locationService: LocationService, private store: Store) {
    this.subs.add(this.store.select(Countries).subscribe((countries) => (this.nCountries = countries.length)));
    this.subs.add(this.store.select(AllProvinces).subscribe((provinces) => (this.provincesSetted = ObjectTS.keys(provinces))));
    this.subs.add(this.store.select(AllLocaties).subscribe((localities) => (this.localitiesSetted = ObjectTS.keys(localities))));
    this.subs.add(this.store.select(AllVias).subscribe((vias) => (this.viasSetted = ObjectTS.keys(vias))));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setCountries() {
    return this.locationService.getCountries().pipe(
      catchError(() => of(null)),
      map((countries) => setCountriesAPI({ countries: countries ?? [] })),
    );
  }

  setProvinces(country: CountryModel) {
    return this.locationService.getProvinces().pipe(
      catchError(() => of([])),
      map((provinces) =>
        setProvincesAPI({
          country,
          provinces,
        }),
      ),
    );
  }

  setLocalities(country: CountryModel, province: ProvinceModel) {
    return this.locationService.getLocalitiesByIneCodProvince(province.ineCod).pipe(
      map((localities) =>
        setLocalitiesAPI({
          country,
          province,
          localities,
        }),
      ),
    );
  }

  setVias(locality: LocalityModel, province: ProvinceModel) {
    return this.locationService.getViasByLocalityAndIneCodProvinceIds(locality.ineCodLocality, province.ineCod).pipe(
      map((vias) =>
        setViasAPI({
          locality,
          vias,
        }),
      ),
    );
  }
}
