import { Component, Input } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-mask',
  templateUrl: './form-input-mask.component.html',
  styleUrls: ['./form-input-mask.component.scss'],
})
export class FormInputMaskComponent extends BaseFormComponent<string> {
  @Input() shouldAutoClear = false;

  @Input() mask!: string;
  @Input() slotChar: string = '';

  public value = '';
}
