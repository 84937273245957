import { BillingTaxTypes, EntityTypes, FloorTypes, PaymentOptions, StreetTypes } from '../enums';

export interface RequesterCompanyFilterModel {
  summary: string;
  activatedFiltersCount: string;
  name: string;
  requesterCompanyId?: number;
  addedMaxDateTime?: Date;
  addedMinDateTime?: Date;
  code: number;
}
export interface RequesterCompanyEditBillingDataModel {
  id: number;
  legalName: string;
  nationalIdentityDocument: string;
  bankAccount: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  block: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  automaticInvoicing: boolean;
}

//////////////////////////////////
// Nuevos modelos
export interface RequesterCompanyIndexModel {
  id: number;
  name: string;
  nationalIdentityDocument: string;
  code: string;
  prescriptorType: EntityTypes;
}

export interface RequesterCompanyModel {
  id: number;
  name: string;
  nameSearchPattern: string;
  description: string;
  nationalIdentityDocument: string;
  observations: string;
  email: string;
  phone: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  addedDateTime: string;
  addedUserId: number;
  lastUpdatedDateTime: string;
  lastUpdatedUserId: number;
  // billingData: BillingData;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  code: string;
  prescriptorType: EntityTypes;
}

export interface RequesterCompanyEditModel {
  id: number;
  name: string;
  description: string;
  observations: string;
  email: string;
  phone: string;
  nationalIdentityDocument: string;
  latitude: number;
  longitude: number;
  ivaPercentage: number;
  irpfPercentage: number;
  billingTaxType: BillingTaxTypes;
  code: string;
  prescriptorType: EntityTypes;
}

export interface RequesterCompanyEditPartnerDataModel {
  id: number;
  paymentOption: PaymentOptions;
}

export interface RequesterCompanyUserIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  customerName: string;
  requesterCompanyName: string;
}
