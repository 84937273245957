import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { BaseWithUnsubComponent } from '../../../base-components/base.component';
import { ASYNC_BUTTON_CLASS_TYPES, ASYNC_BUTTON_SIZES, ASYNC_BUTTON_STATE } from '../async-button.types';

@Component({
  selector: 'ui-async-button',
  templateUrl: './async-button.component.html',
  styleUrls: ['./async-button.component.scss'],
})
export class AsyncButtonComponent extends BaseWithUnsubComponent implements OnInit, OnChanges {
  public faSpinner = faSpinner;

  public ASYNC_BUTTON_STATE = ASYNC_BUTTON_STATE;

  public inlineClasses = '';
  public state = ASYNC_BUTTON_STATE.IDLE;

  @Input() size: ASYNC_BUTTON_SIZES | string = ASYNC_BUTTON_SIZES.LARGE;

  @Input() rounded = false;

  @Input() type = 'button';

  @Input() asyncButton = false;

  @Input() buttonClassByType: ASYNC_BUTTON_CLASS_TYPES | string = ASYNC_BUTTON_CLASS_TYPES.PRIMARY;

  @Input() label = 'send';

  @Input() loadingLabel = 'sending';

  @Input() disabled = false;

  @Input() icon?: IconDefinition;

  /** @deprecated */
  @Input() inputState = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);

  /**
   * @todo Eliminar la version deprecated y cambiarla en todas partes. Esto va a sustituir a inputState.
   */
  public _btnState = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);
  @Input() set btnState(btnState: ASYNC_BUTTON_STATE) {
    this._btnState.next(btnState);
  }

  get btnState(): ASYNC_BUTTON_STATE {
    return this._btnState.value;
  }

  @Output() clickEvent = new EventEmitter<MouseEvent>();

  @ViewChild('button', { static: true }) button!: HTMLButtonElement;

  ngOnInit() {
    this.inlineClasses = ['butt', this.size, this.rounded ? 'rounded' : '', this.buttonClassByType].filter((el) => !!el.trim().length).join(' ');
  }

  /**
   *  @todo: Esto es un arreglo para los componentes que no tengan valor en el inputState. Se eliminará cuando se cambie
   * inputstate por btnState. Quedaría pendiente añadir el HTML para que tire de btnState.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputState && !changes.inputState?.currentValue) {
      this.inputState = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);
    }
    /**
     * @todo: Provisional. Necesario porque no actualiza el estado del BehaviorSubject. Eliminar cuando se elimine la versión deprecated.
     */
    if (changes.inputState?.currentValue) {
      this.inputState.pipe(takeUntil(this._componentDestroyed)).subscribe((val) => (this.state = val));
    }
    if (changes.buttonClassByType) {
      this.inlineClasses = ['butt', this.size, this.rounded ? 'rounded' : '', this.buttonClassByType].filter((el) => !!el.trim().length).join(' ');
    }
  }

  public onButtonClick(event: MouseEvent): void {
    if (this.asyncButton) {
      this.toggleState();
    }
    this.clickEvent.emit(event);
  }

  private toggleState(): void {
    const nextState: ASYNC_BUTTON_STATE = +!this.inputState.value;
    this.inputState.next(nextState);
  }
}
