import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { isNil } from 'lodash';

@Injectable()
export class AppraisalBatchesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalBatches', cache);
  }

  getTemplate(id: number): Observable<any> {
    return this.get(`Template`, { params: this.setParams({ orderRequestBatchId: id }), observe: 'response', responseType: 'blob' });
  }

  uploadNewBatch(document: Blob, id: any) {
    const body = new FormData();
    body.append('model', document);
    if (!isNil(id)) {
      body.append('orderRequestBatchId', id);
    }
    return this.post('', body, {
      observe: 'events',
      responseType: 'json',
      reportProgress: true,
    });
  }
}
