import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './domain/chests-culture.config';
import { CultureReducer } from './redux/culture.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CultureEffects } from './redux/culture.effects';
import { DataAccessModule } from '@foxeet/data-access';
import { getCountryConfigurations, getCurrencies } from './redux/culture.actions';

@NgModule({
  imports: [CommonModule, DataAccessModule, StoreModule.forFeature(FEATURE_NAME, CultureReducer), EffectsModule.forFeature([CultureEffects])],
})
export class CultureChestModule {
  constructor(private store: Store) {
    this.store.dispatch(getCountryConfigurations());
    this.store.dispatch(getCurrencies());
  }
}
