import { AppraisalImplementationFileDocumentTypes } from '../enums';

export interface AppraisalDeliverableIndexModel {
  deliverableId: number;
  workflowItemId: number;
  workflowItemReportId: number;
  version: number;
  generatedDatetime: Date;
  fileName: string;
  fileWebPath: string;
  fileZipName: string;
  fileZipPath: string;
  fileZipWebPath: string;
  appraisalDocumentType: AppraisalImplementationFileDocumentTypes;
}
