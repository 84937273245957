import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, startWith, take, takeUntil } from 'rxjs/operators';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-chips',
  templateUrl: './form-input-chips.component.html',
  styleUrls: ['./form-input-chips.component.scss'],
})
export class FormInputChipsComponent extends BaseFormComponent<any> implements OnInit {
  @Input() readonly = false;

  @Input() filteredItems = new BehaviorSubject<any[]>([]);

  @Input() minimumCharsToEmitEvent: number;
  @Input() listSubject: (filter: unknown) => Observable<any>; // TODO: Tipar
  @Input() filterName: string;
  @Input() labelKeys: string[];
  @Input() valueKey: string;
  @Input() maxSelection: number;
  @Input() showImage: boolean = false;

  @Output() chipRemoved: EventEmitter<void> = new EventEmitter();

  public isible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [
    /*ENTER, COMMA*/
  ];
  public selectedItems: any[] = [];
  public inputCtrl = new FormControl();

  @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete, { static: false }) matAutocomplete!: MatAutocomplete;

  ngOnInit() {
    this._loaded = true;
    this.setControl();

    this.control.valueChanges.pipe(startWith(this.control?.value), takeUntil(this._componentDestroyed)).subscribe((value) => {
      if (value === null) {
        // this.filteredItems.next([]);
        this.selectedItems = [];
      }

      if (this.value == null && !!value?.length) {
        this.listSubject({ ids: value })
          .pipe(take(1))
          .subscribe(
            (res) => this.mapResponse(res, value),
            () => this.filteredItems.next([]),
          );
      }

      this.value = value;
      this.errors = this.control.errors;
      this.errorKeys = Object.keys(this.control.errors ?? {});
    });

    this.checkDisable();

    this.inputCtrl.valueChanges
      .pipe(
        filter((v) => v && v.length >= this.minimumCharsToEmitEvent),
        debounceTime(500),
        takeUntil(this._componentDestroyed),
      )
      .subscribe((value) => {
        const filterres: Record<string, unknown> = {};
        filterres[this.filterName] = value;
        this.listSubject(filterres)
          .pipe(take(1))
          .subscribe(
            (res) => this.mapResponse(res),
            () => this.filteredItems.next([]),
          );
      });
  }

  chipRemove() {
    this.chipRemoved.next();
  }

  private mapResponse = (res, selectedItems?) => {
    // TODO: Review when services migration finishes. (Arturo)
    const body = res?.body ?? res;
    const mappedItems = body.map((el) => ({
      img: this.showImage && el.profileImageFileWebPath ? el.profileImageFileWebPath : null,
      label: this.labelKeys.map((key) => el[key]).join(' '),
      value: el[this.valueKey],
    }));
    if (selectedItems) {
      this.selectedItems = mappedItems.filter((ele) => selectedItems.includes(ele.value));
      this.updateValues();
    }
    this.filteredItems.next(mappedItems);
  };

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.updateValues();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.filteredItems.next([]);
    this.updateValues();
  }

  updateValues() {
    const values: any[] = this.selectedItems.map((el) => el.value);
    this.disabled = !!this.maxSelection && this.selectedItems.length === this.maxSelection;
    this.control.setValue(values);
  }
}
