import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nModule } from '@foxeet/utils-modules';
import { FormInputChipsComponent } from './form-input-chips.component';

@NgModule({
  declarations: [FormInputChipsComponent],
  imports: [CommonModule, MatFormFieldModule, MatChipsModule, MatAutocompleteModule, ReactiveFormsModule, I18nModule.forChild(), MatTooltipModule, MatIconModule],
  exports: [FormInputChipsComponent],
})
export class FormInputChipsModule {}
