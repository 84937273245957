import { AssetUses, CountryConfigurationEnumModel } from '@foxeet/domain';
import { createReducer, on } from '@ngrx/store';
import {
  setCountryConfigByIso,
  setCountryConfigurations,
  setCulture,
  setCurrencies,
  setLanguage,
  setLanguageAndCulture,
  setMeassurementCultureByAsset,
  setReportCurrency,
} from './culture.actions';
import { CultureSetted, CultureState, initialState } from './culture.state';

import { getCurrencyPerSurfaceUnitByAssetUse, getSurfaceUnitByAssetUse, getUnitLengthByAssetUse } from '../utils/culture.utils';
import { isNil, isNilOrEmptyString } from '../to-delete/bucket';

export const CultureReducer = createReducer<CultureState>(
  initialState,
  on(setLanguage, (state, action) => {
    const newState: CultureState = {
      ...state,
      setted: {
        ...state.setted,
        cultureCodeLanguage: action.language ?? state.setted.cultureCodeLanguage,
      },
    };
    localStorage.setItem('language', newState.setted.cultureCodeLanguage);
    return newState;
  }),
  on(setReportCurrency, (state, action) => {
    const reportCurrency = state.currencies.find((c) => c.cultureCodeCurrency === action.culture)?.symbol ?? state.setted.currency;

    return {
      ...state,
      setted: {
        ...state.setted,
        reportCultureCode: action.culture,
        reportCurrency,
        reportCurrencyPerSurfaceUnit: !isNilOrEmptyString(reportCurrency) ? `${reportCurrency}/${state.setted.surfaceUnit}` : state.setted.currencyPerSurfaceUnit,
      },
    };
  }),
  on(setCulture, (state, action) => ({
    ...state,
    setted: {
      ...state.setted,
      cultureCodeCurrency: action.culture ?? state.setted.cultureCodeCurrency,
      currency: state.currencies.find((c) => c.cultureCodeCurrency === action.culture)?.symbol ?? state.setted.currency,
    },
  })),
  on(setLanguageAndCulture, (state, action) => {
    const currency = state.currencies.find((c) => c.cultureCodeCurrency === action.cultureCodeCurrency)?.symbol ?? state.setted.currency;
    const newState = {
      ...state,
      setted: {
        ...state.setted,
        currency,
        cultureCodeCurrency: action.cultureCodeCurrency ?? state.setted.cultureCodeCurrency,
        cultureCodeLanguage: action.cultureCodeLanguage ?? state.setted.cultureCodeLanguage,
        currencyPerSurfaceUnit: `${currency}/${state.setted.surfaceUnit}`,
      },
    };
    localStorage.setItem('language', newState.setted.cultureCodeLanguage);
    return newState;
  }),
  on(setMeassurementCultureByAsset, (state, action) => ({
    ...state,
    setted: setSettedValues(state.setted, state.inUse ?? ({} as CountryConfigurationEnumModel), action.use),
  })),
  on(setCountryConfigurations, (state, action) => {
    const loaded = action.configs ?? [];
    const inUse = loaded.find((cfg) => cfg.threeLetterIso === state.countryISO);
    const setted: CultureSetted = setSettedValues(state.setted, inUse ?? ({} as CountryConfigurationEnumModel));

    return {
      ...state,
      inUse,
      loaded,
      setted,
    };
  }),
  on(setCountryConfigByIso, (state, action) => {
    const inUse = state.loaded.find((cfg) => cfg.threeLetterIso === action.countryISO || cfg.threeLetterIso === 'ESP');
    const setted: CultureSetted = setSettedValues(state.setted, inUse ?? ({} as CountryConfigurationEnumModel));
    return {
      ...state,
      inUse,
      setted,
      countryISO: action.countryISO,
    };
  }),
  on(setCurrencies, (state, action) => ({ ...state, currencies: action.configs ?? [] })),
);

function setSettedValues(state: CultureSetted, config: CountryConfigurationEnumModel, assetUse?: AssetUses): CultureSetted {
  if (!isNil(assetUse)) {
    return {
      ...state,
      surfaceUnit: getSurfaceUnitByAssetUse(assetUse, config ?? ({} as CountryConfigurationEnumModel)),
      unitLength: getUnitLengthByAssetUse(assetUse, config ?? ({} as CountryConfigurationEnumModel)),
      currencyPerSurfaceUnit: getCurrencyPerSurfaceUnitByAssetUse(assetUse, config ?? ({} as CountryConfigurationEnumModel), state.currency),
      reportCurrencyPerSurfaceUnit: getCurrencyPerSurfaceUnitByAssetUse(assetUse, config ?? ({} as CountryConfigurationEnumModel), state.reportCurrency),
    };
  }
  return {
    ...state,
    surfaceUnit: config?.defaultSurfaceUnitMeasurement?.symbol ?? '',
    unitLength: config?.defaultLengthUnitMeasurement?.symbol ?? '',
    currency: config?.defaultCurrency?.symbol ?? '',
    currencyPerSurfaceUnit: `${config?.defaultCurrency?.symbol}/${config?.defaultSurfaceUnitMeasurement?.symbol}`,
    reportCurrencyPerSurfaceUnit: `${state.reportCurrency}/${config?.defaultSurfaceUnitMeasurement?.symbol}`,
  };
}
