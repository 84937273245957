import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigLoaderServiceOptions } from './config-loader.service';
import { ConfigLoaderModuleOptions, FOR_ROOT_OPTIONS_TOKEN, provideMyServiceOptions } from './domain/config-loader.model';

@NgModule()
export class ConfigLoaderModule {
  // I setup the module providers for the root application.
  static forRoot<T = any, K = Record<string, unknown>>(options?: ConfigLoaderModuleOptions<T, K>): ModuleWithProviders<ConfigLoaderModule> {
    return {
      ngModule: ConfigLoaderModule,
      providers: [
        // In order to translate the raw, optional OPTIONS argument into an
        // instance of the MyServiceOptions TYPE, we have to first provide it as
        // an injectable so that we can inject it into our FACTORY FUNCTION.
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options,
        },
        // Once we've provided the OPTIONS as an injectable, we can use a FACTORY
        // FUNCTION to then take that raw configuration object and use it to
        // configure an instance of the MyServiceOptions TYPE (which will be
        // implicitly consumed by the MyService constructor).
        {
          provide: ConfigLoaderServiceOptions,
          useFactory: provideMyServiceOptions,
          deps: [FOR_ROOT_OPTIONS_TOKEN],
        },
        // NOTE: We don't have to explicitly provide the MyService class here
        // since it will automatically be picked-up using the "providedIn"
        // Injectable() meta-data.
      ],
    };
  }
}
