import { AssetSurfaceIds } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';

export interface ValorationOtherMethodModel {
  id: number;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  buildeable: number;
  numberOfElementsToBuild: number;
  valorationValue: number;
  valorationUnitValue: number;
  observations: string;
  calculationsFilename: string;
  calculationsFileWebPath: string;
  file: string;
  isFutureValoration: boolean;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}
