import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DownloadFileButtonComponent } from './download-file-button.component';

@NgModule({
  declarations: [DownloadFileButtonComponent],
  imports: [CommonModule, FontAwesomeModule, MatProgressSpinnerModule],
  exports: [DownloadFileButtonComponent],
})
export class DownloadFileButtonModule {}
