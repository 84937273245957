// TODO: review unused
export interface UserRoleAssignModel {
  UserId: number;
  RoleId: number;
  Role: string;
}

export interface RoleIndexModel {
  Id: number;
  Name: string;
}

export interface UserRoleIndexModel {
  Role: RoleIndexModel;
  RoleId: number;
  UserId: number;
}
