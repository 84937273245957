import { Injectable } from '@angular/core';
import { ConfigLoaderModuleOptions } from './domain/config-loader.model';

// Import the core angular services.
// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

@Injectable({ providedIn: 'root' })
export class ConfigLoaderServiceOptions<T = any, K = Record<string, unknown>> {
  private _options: ConfigLoaderModuleOptions<T, K>;

  constructor(options: ConfigLoaderModuleOptions<T, K>) {
    this._options = options;
  }

  get Options() {
    return this._options;
  }
}

// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

@Injectable({ providedIn: 'root' })
export class DynamicConfigLoaderService<T = any, K = Record<string, unknown>> {
  private serviceOptions: ConfigLoaderServiceOptions<T, K>;

  constructor(serviceOptions: ConfigLoaderServiceOptions<T, K>) {
    this.serviceOptions = serviceOptions;
  }

  get Options() {
    return this.serviceOptions.Options;
  }
}
