import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { TutorialStep, TutorialStepDirection } from '../../domain/tutorial.model';

@Component({
  selector: 'ui-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent {
  @ViewChild('stepper') private stepper: MatStepper;

  public sectionType = TutorialStepDirection;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { steps: TutorialStep[] }) {
    console.log(data);
  }

  public changeStep(step: number) {
    this.stepper.selectedIndex = step;
  }
}
