import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { InputTextarea } from 'primeng/inputtextarea';
import { BaseFormComponent } from '../../../base-components';
import { debounceTime, delay, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ui-form-input-textarea',
  templateUrl: './form-input-textarea.component.html',
  styleUrls: ['./form-input-textarea.component.scss'],
})
export class FormInputTextAreaComponent extends BaseFormComponent<string> implements AfterViewInit {
  @Input() autoResize = false;
  @Input() rows = 3;

  /**
   * @todo: Revisar!! Esto es porque no se redimensiona correctamente
   */
  @ViewChild(InputTextarea) textarea!: InputTextarea;

  ngAfterViewInit(): void {
    if (this.autoResize) {
      this.control.valueChanges.pipe(startWith(this.control.value), debounceTime(1000), delay(1000), takeUntil(this._componentDestroyed)).subscribe((v) => this.textarea?.resize());
    }
  }

  /** **** */
}
