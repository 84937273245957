import { LicenseService } from '@foxeet/data-access';
import { LicenseeFilterModel, LicenseeHeaderIndexModel, LicenseeIndexModel, LicenseeTypes, OrUndef, WorkflowItemLicenseeIndexModel } from '@foxeet/domain';
import { dynamicInjector } from './dynamic-injector';
import { ObjectTS } from './javascript.functions';

export class EntitiesUtils {
  static getEntityDataByType(entities: WorkflowItemLicenseeIndexModel[], entityType: LicenseeTypes): OrUndef<LicenseeHeaderIndexModel> {
    return entities && entities.find((e) => e.licenseeTypeValue === entityType)?.licensee;
  }

  static getEntityFullName(entities: WorkflowItemLicenseeIndexModel[], entityType: LicenseeTypes): OrUndef<string> {
    const entity = this.getEntityDataByType(entities, entityType);
    return entity?.fullName;
  }

  static getMapEntitiesIdAndRoles(entitiesIds: number[], roles: LicenseeTypes[]) {
    return entitiesIds && roles.reduce((acc, curr) => ({ ...acc, [curr]: entitiesIds }), {});
  }

  static mergeLicensees(...licensees: { [key: number]: number[] }[]): { [key: number]: number[] } {
    const licenseeIds: { [key: number]: number[] } = {};
    licensees.forEach(
      (licensee) =>
        licensee &&
        ObjectTS.keys(licensee).forEach((key) => {
          licenseeIds[key] = licenseeIds[key] ? [...licenseeIds[key], ...licensee[key]] : licensee[key];
        }),
    );

    return licenseeIds;
  }

  static getEntityLicenseeIdByType(entities: WorkflowItemLicenseeIndexModel[], entityType: LicenseeTypes): OrUndef<number> {
    return entities && entities.find((e) => e.licenseeTypeValue === entityType)?.licenseeId;
  }

  static supplierCompaniesFunc = (filter) => {
    const licenseService: LicenseService = dynamicInjector().get(LicenseService);
    const newFilter: LicenseeFilterModel = { ...filter };
    return licenseService.getResolverUsersListAll(newFilter);
  };

  static requesterCompaniesFunc = (filter) => {
    const licenseService: LicenseService = dynamicInjector().get(LicenseService);
    const newFilter: LicenseeFilterModel = { ...filter, licenseeTypes: [LicenseeTypes.RequesterCompany] };
    return licenseService.listAllByFilter<LicenseeIndexModel[]>(newFilter);
  };

  static customerFunc = (filter) => {
    const licenseService: LicenseService = dynamicInjector().get(LicenseService);
    const newFilter: LicenseeFilterModel = { ...filter, licenseeTypes: [LicenseeTypes.Customer] };
    return licenseService.listAllByFilter<LicenseeIndexModel[]>(newFilter);
  };
}
