import { LanguageSelectorList } from '../interfaces/selector-language.interfaces';
import { mapAlpha3ToAlpha2ISO } from '../utils/iso-country-alpha-2.map';

export const getLanguageList = (languages: LanguageSelectorList): LanguageSelectorList => {
  return languages.map((lang) => ({
    cultureCodeLanguage: lang.cultureCodeLanguage,
    lang: lang.lang,
    label: `LANG_${lang.lang}`,
    img: lang.img,
    flag: countryFlag(lang.img),
  }));
};

export const countryFlag = (code: string): string => {
  let flagEmoji = '';
  const alpha2ISO = mapAlpha3ToAlpha2ISO.get(code);
  if (code && alpha2ISO) {
    flagEmoji = alpha2ISO.toUpperCase().replace(/./g, (char: string) => String.fromCodePoint(char.charCodeAt(0) + 127397));
  }
  return flagEmoji;
};
