<div
  class="custom-container"
  [ngStyle]="{ height: fullHeight ? '100%' : '', 'background-color': backgroundColor, 'border-radius': radius }"
  [ngClass]="{ backgroundErrorColor, backgroundWarningColor, 'mat-elevation-z1': elevation }"
>
  <div *ngIf="!hideTitle" class="custom-container-header" [ngStyle]="{ height: fullHeight ? '10%' : '', padding: noTitlePadding ? '' : '2em' }">
    <h2 *ngIf="title" [ngStyle]="{ color: titleColor }" class="title" id="AT_containerTitle">
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <span>
        {{ title | translate: undefined:titleParams }}
      </span>
      <span *ngIf="subTitle" class="sub-title">{{ subTitle }}</span>
    </h2>
    <ng-content select="#headerActions"></ng-content>
  </div>

  <div
    [ngStyle]="{
      padding: noPadding || contentPadding ? contentPadding || '0' : '2em',
      height: fullHeight ? '90%' : '',
      display: vAlignCenter ? 'flex' : '',
      'align-items': vAlignCenter ? 'center' : ''
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
