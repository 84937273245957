import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CultureUtils } from '@foxeet/chests/culture';
import { AssetUses, InternationalizationTypes } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { isNil, isNilOrEmptyString } from '@foxeet/utils/functions';
import { Store } from '@ngrx/store';

@Pipe({ name: 'DecimalFormatterPipe' })
export class DecimalFormatterPipe extends UnsuscriptionHandler implements PipeTransform {
  private cultureUtils: CultureUtils;

  constructor(private readonly store: Store) {
    super();

    this.cultureUtils = new CultureUtils(store, this._componentDestroyed);
  }

  private _getSuffix(suffixType: InternationalizationTypes, assetUse: AssetUses | null = null, cultureCodeCurrency: string | null = null): string {
    return this.cultureUtils.getSuffixBySuffixTypeAndAssetUse(suffixType, assetUse, cultureCodeCurrency);
  }

  private _getSuffixType(
    suffixType: InternationalizationTypes | null,
    suffix: string | null = '',
    assetUse: AssetUses | null = null,
    cultureCodeCurrency: string | null = null,
  ): string {
    return suffix ? suffix : !isNil(suffixType) ? this._getSuffix(suffixType, assetUse, cultureCodeCurrency) : '';
  }

  public transform(
    value: number | string,
    suffixType: InternationalizationTypes | null = null,
    suffix: string | null = '',
    format = '.2-2',
    assetUse: AssetUses | null = null,
    cultureCodeCurrency: string | null = null,
  ): string {
    if (isNaN(+value) || isNilOrEmptyString(value?.toString())) {
      return value ? value + '' : '';
    }
    return `${new DecimalPipe(this.cultureUtils.CultureSetted?.cultureCodeCurrency ?? '').transform(value, format)} ${this._getSuffixType(
      suffixType,
      suffix,
      assetUse,
      cultureCodeCurrency,
    )}`;
  }
}
