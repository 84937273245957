<div>
  <div class="title-container">
    <h2>DEBUG MENU</h2>
  </div>

  <div class="body-container">
    <div class="mb-4">
      <h4 class="mb-2">Endpoint address</h4>
      <span>{{ defaultEndpoint }}</span>
    </div>

    <div class="mb-4" [formGroup]="formGroup">
      <div class="py-2">
        <h2 class="mb-2">Set custom endpoint</h2>
        <input class="form-control" formControlName="newEndpoint" placeholder="Ejemplo: http://192.168.XX.XX:XXXX" />
      </div>

      <div class="py-2">
        <ui-async-button
          componentId="AT_set_endpoint"
          [disabled]="formGroup.invalid"
          [asyncButton]="false"
          [buttonClassByType]="'secondary'"
          [size]="'md'"
          [label]="'Set endpoint' | translate"
          [loadingLabel]="'Setting' | translate"
          (clickEvent)="setEndpoint(formGroup.controls.newEndpoint.value)"
        ></ui-async-button>
      </div>
    </div>

    <div>
      <h2 class="mb-2">Set custom endpoint</h2>
      <div class="py-2">
        <ui-async-button
          class="px-3"
          [asyncButton]="false"
          [buttonClassByType]="'secondary'"
          [size]="'md'"
          [label]="'DEV'"
          [loadingLabel]="'DEV'"
          (clickEvent)="setEndpoint('dev')"
        ></ui-async-button>

        <ui-async-button
          class="px-3"
          [asyncButton]="false"
          [buttonClassByType]="'secondary'"
          [size]="'md'"
          [label]="'QA'"
          [loadingLabel]="'QA'"
          (clickEvent)="setEndpoint('qa')"
        ></ui-async-button>

        <ui-async-button
          class="px-3"
          [asyncButton]="false"
          [buttonClassByType]="'secondary'"
          [size]="'md'"
          [label]="'PROD'"
          [loadingLabel]="'PROD'"
          (clickEvent)="setEndpoint('prod')"
        ></ui-async-button>
      </div>
    </div>

    <div>
      <h2 class="mb-2">Debug options</h2>
      <div class="py-2">
        <ui-form-input-checkbox
          [componentId]="'AT_check_enable_modal_error'"
          [form]="formGroup"
          [controlName]="'checkEnableModalError'"
          [label]="'BOTTOM_BAR_error_dialog_enabled' | translate"
        ></ui-form-input-checkbox>
      </div>
    </div>
  </div>

  <div class="footer-container">
    <div class="d-flex justify-content-between">
      <ui-async-button
        id="closeButton"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        [size]="'md'"
        [label]="'close' | translate"
        [loadingLabel]="'closing' | translate"
        (clickEvent)="closeEventTrigger()"
      ></ui-async-button>

      <ui-async-button
        id="confirmButton"
        [asyncButton]="false"
        [buttonClassByType]="'primary'"
        [size]="'md'"
        [label]="'confirm' | translate"
        [loadingLabel]="'confirming' | translate"
        (clickEvent)="confirmEventTrigger()"
      ></ui-async-button>
    </div>
  </div>
</div>
