import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { ExpandablePanel } from '../domain/model';

@Component({
  selector: 'ui-expandable-filters-panel',
  templateUrl: './expandable-filters-panel.component.html',
  styleUrls: ['./expandable-filters-panel.component.scss'],
})
export class ExpandableFiltersPanelComponent implements OnInit {
  @Input() height = 200;
  @Input() label = '';
  @Input() autoClose = false;
  @Input() expandablePanel!: ExpandablePanel;
  @Output() update: EventEmitter<void> = new EventEmitter();
  @Output() clear: EventEmitter<void> = new EventEmitter();

  public showDrawer = false;
  public maxHeight = 200;

  @HostListener('keydown.enter') onEnter() {
    if (this.showDrawer) {
      this.onUpdate();
    }
  }

  ngOnInit() {
    this.maxHeight = this.height;
    this.height = 0;
  }

  toggleFilters() {
    this.expandablePanel.opened = !this.expandablePanel.opened;
  }

  onUpdate() {
    this.toggleFilters();
    this.update.emit();
  }

  onClear() {
    this.toggleFilters();
    this.clear.emit();
  }
}
