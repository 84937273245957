import { BillingTaxTypes } from '../enums';

export interface SupplierCompanyCreateModel {
  Name: string;
  Description: string;
  Observations: string;
  ContactEmail: string;
  ContactPhone: string;
  NationalIdentityDocument: string;
}

export interface SupplierCompanyEditBillingDataModel {
  SupplierCompanyId: number;
  LegalName: string;
  NationalIdentityDocument: string;
  BankAccount: string;
  StreetType: string;
  StreetName: string;
  StreetNumber: string;
  Stairs: string;
  Portal: string;
  Floor: string;
  Gate: string;
  Block: string;
  Locality: string;
  Province: string;
  PostalCode: string;
  Country: string;
  IvaPercentage: number;
  IrpfPercentage: number;
  BillingTaxType: BillingTaxTypes;
}

export interface SupplierCompanyFilterModel {
  Summary: string;
  ActivatedFiltersCount: number;
  OrderFieldName: string;
  OrderFieldAsc: boolean;
  PossibleOrderFieldNames: string[];
  SupplierCompanyId: number;
  Name: string;
  AddedMaxDateTime: Date;
  AddedMinDateTime: Date;
}

export interface SupplierCompanyEditModel {
  id: number;
  name: string;
  description: string;
  observations: string;
  email: string;
  phone: string;
  nationalIdentityDocument: string;
}

export interface SupplierCompanyModel {
  id: number;
  name: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  nationalIdentityDocument: string;
  description: string;
  observations: string;
  email: string;
  phone: string;
}

export interface SupplierCompanyIndexModel {
  id: number;
  name: string;
  thumbnailFileName: string;
  thumbnailFileWebPath: string;
  nationalIdentityDocument: string;
}

export interface SupplierCompanyUserIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  appraisalsInProcessCount: number;
  customerName: string;
  requesterCompanyName: string;
}
