import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppraisalImplementationFileDocumentTypes,
  AppraisalManagersModel,
  FileTypes,
  OrNull,
  ValidationAppraisalDashboardModel,
  ValidationAppraisalElapsedTimesDashboardModel,
  ValidationAppraisalFilesIndexModel,
  ValidationAppraisalFilter,
  ValidationAppraisalRegisteredPropertyModel,
} from '@foxeet/domain';
import { Observable, take } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';
import { isNil } from '../to-delete/bucket';

@Injectable()
export class QualityControlService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'ValidationAppraisals', cache);
  }

  assignTechincal(appraisalId: number, technicalManagerUserId: number) {
    const body = { appraisalIds: [appraisalId], technicalManagerUserId };
    return this.put<typeof body, string>(`${appraisalId}/AssignTechnical`, body);
  }

  assignTechnicalManager(appraisalId: number, technicalManagerUserId: number) {
    const body = { appraisalIds: [appraisalId], TechnicalManagerUserId: technicalManagerUserId };
    return this.put<typeof body, string>(`${appraisalId}/AssignTechnicalManager`, body);
  }

  assignProcessManager(appraisalId: number, processManagerUserId: number) {
    // '/AssignProcessManager';
    const body = { appraisalIds: [appraisalId], processManagerUserId };
    return this.put<typeof body, string>(`${appraisalId}/AssignProcessManager`, body);
  }

  createWithFiles(data: FormData) {
    return this.postMultipartFile('', data);
  }

  startValidation(appraisalId: number) {
    // '/StartValidation';
    // const parameters: URLParameter[] = [{ key: 'AppraisalId', value: appraisalId }];
    return this.put(`${appraisalId}/StartValidation`);
  }

  succeedValidation(appraisalId: number) {
    // const parameters: URLParameter[] = [{ key: 'AppraisalId', value: appraisalId }];
    return this.put(`${appraisalId}/SucceededValidation`);
  }

  setAsDelivered(id: number) {
    // const parameters: URLParameter[] = [{ key: 'appraisalId', value: appraisalId }];
    return this.put(`${id}/Delivered`);
  }

  finishValidation(id: number) {
    // const parameters: URLParameter[] = [{ key: 'AppraisalId', value: AppraisalId }];
    return this.put(`${id}/FinishValidation`);
  }

  cancelValidation(id: number, data: any) {
    return this.put(`${id}/Cancel`, data);
  }

  public getManagers(id: number): Observable<AppraisalManagersModel> {
    return this.get<AppraisalManagersModel>(`${id}/Managers`);
  }

  public getRegisteredProperties(id: number): Observable<ValidationAppraisalRegisteredPropertyModel[]> {
    return this.get<ValidationAppraisalRegisteredPropertyModel[]>(`${id}/RegisteredProperties`);
  }

  public getDocuments(id: number): Observable<ValidationAppraisalFilesIndexModel[]> {
    return this.get<ValidationAppraisalFilesIndexModel[]>(`${id}/Documents`);
  }

  public createMassive(data: any, excelFile: Blob, pdfFiles: Blob[]) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    pdfFiles.forEach((file) => formData.append(`PdfFiles`, file));
    formData.append(`ExcelFile`, excelFile);

    return this.postMultipartFile('CreateMultiple', formData);
  }

  public uploadValidationAppraisalDocument(appraisalId: number, appraisalDocumentType: AppraisalImplementationFileDocumentTypes, document: Blob) {
    const formData = new FormData();
    formData.append('appraisalDocumentType', `${appraisalDocumentType}`);
    formData.append('file', document);
    return this.postMultipartFile(`${appraisalId}/Documents`, formData);
  }

  public uploadValidationAppraisalDocumentQuality(
    appraisalId: number,
    validationAppraisalDocumentType: AppraisalImplementationFileDocumentTypes,
    file: Blob,
    isPrintable: boolean,
    obtainedDatetime: OrNull<Date>,
  ) {
    const body = new FormData();
    body.append('File', file);
    body.append('appraisalDocumentType', `${validationAppraisalDocumentType}`);
    if (obtainedDatetime) {
      body.append('documentObtainedDate', `${obtainedDatetime.toISOString()}`);
    }
    return this.postMultipartFile(`${appraisalId}/Documents`, body);
  }

  getSimpleTemplateURL(): Observable<string> {
    return this.get(`SingleTemplate`, { responseType: 'text' });
  }

  getMassiveTemplateURL(): Observable<string> {
    return this.get('Template', { responseType: 'text' });
  }

  public getElapsedTimesDashboard(filter: ValidationAppraisalFilter): Observable<ValidationAppraisalElapsedTimesDashboardModel> {
    return this.http.post<ValidationAppraisalElapsedTimesDashboardModel>(`${this.apiBranch}/ElapsedTimesDashboard`, filter).pipe(take(1));
  }
  getDashboard(filter: ValidationAppraisalFilter): Observable<ValidationAppraisalDashboardModel> {
    return this.http.post<ValidationAppraisalDashboardModel>(`${this.apiBranch}/Dashboard`, filter).pipe(take(1));
  }

  public deleteAllProperties(id: number) {
    return this.delete(`${id}/RegisteredProperties`);
  }
}
