import { AppraisalOrderTypes, AppraisalPurposes, LicenseeTypes, WorkerRoles } from '../enums';
import { WorkflowItemStepDataModel } from './dashboard.model';

export interface AppraisalDashboardModel {
  workflowItemCount: number;
  workflowItemStepData: WorkflowItemStepDataModel[];
  totalAppraisalsNotDelivered: number;
  appraisalsPendingToReceive: number;
  appraisalsPendingToValidate: number;
  incidents: number;
  incidentsPendingToReceive: number;
  totalIncidentsPendingToReceive: number;
  incidentsPendingToValidate: number;
  totalIncidentsPendingToValidate: number;
  claimsPendingToReceive: number;
  totalClaimsPendingToReceive: number;
  claimsPendingToValidate: number;
  totalClaimsPendingToValidate: number;
  revisionsPendingToReceive: number;
  totalRevisionesPendingToReceive: number;
  revisionsPendingToValidate: number;
  totalRevisionesPendingToValidate: number;
}
export interface AppraisalDashboardFilterModel {
  appraiserUserId?: number;
  appraiserUserIds?: number[];
  commercialManagerUserId?: number;
  commercialManagerUserIds?: number[];
  customerId?: number;
  customerIds?: number[];
  orderType?: AppraisalOrderTypes;
  orderTypes?: number[];
  portfolioId?: number;
  portfolioIds?: number[];
  purpose?: AppraisalPurposes;
  purposes?: number[];
  requesterCompanyId?: number;
  requesterCompanyIds?: number[];
  technicalManagerUserId?: number;
  technicalManagerUserIds?: number[];
  addedMaxDateTime?: string;
  addedMinDateTime?: string;
  processManagerUserId?: number;
  processManagerUserIds?: number[];
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
}

export interface AppraisalElapsedTimesDashboard {
  workflowItemStepData: WorkflowItemStepDataModel[];
  // Finished
  finished_AverageElapsedTimeInMinToFinishAppraisals: number;
  finished_AverageElapsedTimeInMinBeforeSendByAppraiser: number;
  finished_AverageElapsedTimeInMinAfterSendByAppraiser: number;

  finished_AverageElapsedTimeInMinToSolveRevisions: number;

  finished_AverageElapsedTimeInMinToSolveIncidents: number;
  finished_AverageElapsedTimeInMinToSolveIncidentsBeforeSendByAppraiser: number;
  finished_AverageElapsedTimeInMinToSolveIncidentsAfterSendByAppraiser: number;

  finished_AverageElapsedTimeInMinToSolveClaims: number;
  // In progress
  inProgress_AverageElapsedTimeInMinToFinishAppraisals: number;
  inProgress_AverageElapsedTimeInMinBeforeSendByAppraiser: number;
  inProgress_AverageElapsedTimeInMinAfterSendByAppraiser: number;

  inProgress_AverageElapsedTimeInMinToSolveRevisions: number;

  inProgress_AverageElapsedTimeInMinToSolveIncidents: number;
  inProgress_AverageElapsedTimeInMinToSolveIncidentsBeforeSendByAppraiser: number;
  inProgress_AverageElapsedTimeInMinToSolveIncidentsAfterSendByAppraiser: number;

  inProgress_AverageElapsedTimeInMinToSolveClaims: number;
}
