import { AppraisalGradeLevels, AppraisalGradeTypes, EventType, FloorTypes, PaymentOptions, StreetTypes } from '../enums';
import { AppraisalIndexModel } from './appraisal.model';

export interface AppraiserUserModel {
  id: number;
  lockoutEnd: string;
  twoFactorEnabled: boolean;
  phoneNumberConfirmed: boolean;
  phoneNumber: string;
  concurrencyStamp: string;
  securityStamp: string;
  passwordHash: string;
  emailConfirmed: boolean;
  normalizedEmail: string;
  email: string;
  normalizedUserName: string;
  userName: string;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  firstName: string;
  firstNameSearchPattern: string;
  lastName: string;
  lastNameSearchPattern: string;
  profileImageFileName: string;
  profileImageFileWebPath: string;
  creationDateTime: string;
  activationEmailLastSentDateTime: string;
  activationDateTime: string;
  lastLoginDateTime: string;
  isActive: boolean;
  isTemporalPassword: boolean;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  degree: string;
  externalCode: string;
  observations: string;
  internalObservations: string;
  latitude: number;
  longitude: number;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  streetType: StreetTypes;
  streetName: string;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
}

export interface AppraiserUserEditModel {
  id: number;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  degree: string;
  code: string;
  externalCode: string;
  observations: string;
  internalObservations: string;
  streetName: string;
  streetNumber: number;
  locality: string;
  province: string;
  latitude: number;
  longitude: number;
  ineCodProv: number;
  ineCodMun: number;
  dgcCodVia: number;
  ineCodVia: string;
  postalCode: string;
  isPartner: boolean;
  countryThreeLetterISORegionName: string;
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
}

export interface AppraisersUserEditPartnerDataModel {
  id: number;
  isPartner: boolean;
  commercialPercentageFee: number;
  executionPercentageFee: number;
  paymentOption: PaymentOptions;
}

export interface AppraiserUserIndexModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  requesterCompanyId: number;
  supplierCompanyId: number;
  customerId: number;
  nationalIdentityDocument: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  isPartner: boolean;
  appraisalsInProcessCount: number;
}

export interface AppraiserAppraisalExchangePreferencesModel {
  id: number;
  appraiserAppraisalExchangePreferences: AppraiserUserAppraisalExchangePreferences;
}

export interface AppraiserUserAppraisalExchangePreferences {
  maxDistanceInMeters: number;
  minFee: number;
  canResidential: boolean;
  canCommercial: boolean;
  canTertiary: boolean;
  canIndustrial: boolean;
  canAnnex: boolean;
  canRusticEstate: boolean;
  canTerain: boolean;
  canDotational: boolean;
  specificationsEditedFromPreferences: boolean;
}

export interface AppraiserUserAppraisalExchangeSpecificationsModel {
  specificationsEditedFromPreferences: boolean;
  canResidential: boolean;
  canCommercial: boolean;
  canTertiary: boolean;
  canIndustrial: boolean;
  canAnnex: boolean;
  canRusticEstate: boolean;
  canTerain: boolean;
  canDotational: boolean;
}

export interface AppraiserUserAppraisalGradesSummaryModel {
  id: number;
  userId: number;
  deadlineGrade: number;
  deadlineGradeCount: number;
  executionGrade: number;
  executionGradeCount: number;
  globalGrade: number;
  globalGradeCount: number;
  gradeType: AppraisalGradeTypes | null;
  gradeLevel: AppraisalGradeLevels | null;
}

export interface AppraiserUserFilterModel {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  id: number;
  ids: number[];
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  hasCustomerOrRequesterCompanyAssigned: boolean;
  customerId: number;
  customerIds: number[];
  requesterCompanyId: number;
  requesterCompanyIds: number[];
  supplierCompanyId: number;
  supplierCompanyIds: number[];
  sendNotificationsByEmail: boolean;
  sendNotificationsByPhone: boolean;
  nationalIdentityDocument: string;
  streetName: string;
  locality: string;
  province: string;
  code: string;
  distanceBetweenAssetLatitude: number;
  distanceBetweenAssetLongitude: number;
  ineCodProv: number;
  ineCodMun: number;
  ineCodLocality: number;
  countryThreeLetterISORegionName: string;
  dgcCodVia: number;
  ineCodVia: string;
  distanceBetweenPointLatitude: number;
  distanceBetweenPointLongitude: number;
  distanceBetweenPointMaxMeters: number;
  distanceBetweenPointMinMeters: number;
}

export interface AppraiserUserEventFilterModel {
  minDateTime: any;
  maxDateTime: any;
  appraiserUserId?: number;
}

export interface AppraiserUserSummaryEventsByDay {
  eventDateTime: any;
  events: EventsByDay[];
}
export interface EventsByDay {
  absenceFinishDatetime: Date;
  absenceStartDatetime: Date;
  appraisalId: number;
  eventDateTime: Date;
  eventType: EventType;
  recordId: string;
}

export interface AppraisalsEventsByDayForAppraiserModel {
  ExpiredAllDataSentDeadlines: AppraisalIndexModel[];
  ExpiredVisitScheduleDeadlines: AppraisalIndexModel[];
  Visits: AppraisalIndexModel[];
}

export interface DateModel {
  Day: number;
  Month: number;
  MonthName?: string;
  Year: number;
}
