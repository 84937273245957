import { AssetSurfaceData, AssetSurfacesInformationDto, AssetSurfacesInformationModel, AssetWorkPlanStateDistributionModel } from '@foxeet/domain';
import { FormUtils } from './form.functions';

export const typesOfSurfaces = ['cadastralSurfaces', 'registeredSurfaces', 'checkedSurfaces'];

export enum SurfacesIds {
  cadastralSurfaces_totaltotalPlotSurfaceId = 0,
  cadastralSurfaces_totalUsefulSurfaceId = 1,
  cadastralSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 2,
  cadastralSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 3,
  cadastralSurfaces_totalCoveredTerraceSurfaceId = 4,
  cadastralSurfaces_totalUncoveredTerraceSurfaceId = 5,
  registeredSurfaces_totaltotalPlotSurfaceId = 6,
  registeredSurfaces_totalUsefulSurfaceId = 7,
  registeredSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 8,
  registeredSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 9,
  registeredSurfaces_totalCoveredTerraceSurfaceId = 10,
  registeredSurfaces_totalUncoveredTerraceSurfaceId = 11,
  checkedSurfaces_totaltotalPlotSurfaceId = 12,
  checkedSurfaces_totalUsefulSurfaceId = 13,
  checkedSurfaces_totalBuiltSurfaceWithoutCommonSurfacesId = 14,
  checkedSurfaces_totalBuiltSurfaceWithCommonSurfacesId = 15,
  checkedSurfaces_totalCoveredTerraceSurfaceId = 16,
  checkedSurfaces_totalUncoveredTerraceSurfaceId = 17,
}

export const getBasicSurfaceObject = (): AssetSurfacesInformationDto => {
  const enumSurfaces = FormUtils.fromEnumToKeyValueArray(SurfacesIds, 'SurfacesIds');

  const surfaces: AssetSurfacesInformationDto = {
    cadastralSurfaces: null,
    registeredSurfaces: null,
    checkedSurfaces: null,
    surfacesObservations: '',
  };
  typesOfSurfaces.forEach((tSurface) => {
    const surfacesObject = {
      totalPlotSurface: 1000,
      totalUsefulSurface: 1000,
      totalBuiltSurfaceWithoutCommonSurfaces: 1000,
      totalBuiltSurfaceWithCommonSurfaces: 1000,
      totalCoveredTerraceSurface: 1000,
      totalUncoveredTerraceSurface: 1000,
      totalPlotSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalPlotSurfaceId`)?.key,
      totalUsefulSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalUsefulSurfaceId`)?.key,
      totalBuiltSurfaceWithoutCommonSurfacesId: enumSurfaces.find((el) => el.value == `${tSurface}_totalBuiltSurfaceWithoutCommonSurfacesId`)?.key,
      totalBuiltSurfaceWithCommonSurfacesId: enumSurfaces.find((el) => el.value == `${tSurface}_totalBuiltSurfaceWithCommonSurfacesId`)?.key,
      totalCoveredTerraceSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalCoveredTerraceSurfaceId`)?.key,
      totalUncoveredTerraceSurfaceId: enumSurfaces.find((el) => el.value == `${tSurface}_totalUncoveredTerraceSurfaceId`)?.key,
      allFields: [
        'totalPlotSurface',
        'totalUsefulSurface',
        'totalBuiltSurfaceWithoutCommonSurfaces',
        'totalBuiltSurfaceWithCommonSurfaces',
        'totalCoveredTerraceSurface',
        'totalUncoveredTerraceSurface',
      ],
    };
    surfaces[tSurface] = surfacesObject;
  });
  return surfaces;
};
