import { LicensePlanChangeTypes, LicensePlans } from '../enums';

export interface LicenseeDashboardFilterModel {
  firstName: string;
  firstNameContains: string;
  lastName: string;
  lastNameContains: string;
  fullName: string;
  fullNameContains: string;
  licensePlan: LicensePlans;
  phone: string;
  phoneContains: string;
  isActive: boolean;
  hasPaymentMethods: boolean;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  licenseExpirationMinDatetime: string;
  licenseExpirationMaxDatetime: string;
  currentWorkflowSteps: number;
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
}

export interface LicenseeDashboardModel {
  name: string;
  email: string;
  phone: string;
  activeLicensePlan: string;
  licenseExpirationDatetime: any;
  licensePlanChangeType: LicensePlanChangeTypes;
  finishedAppraisals: number;
  hasPaymentMethod: boolean;
}

export interface LicenseeGroupByPlanDashboardModel {
  plan: LicensePlans;
  numberOfActiveLicensees: number;
  numberOfInactiveLicensees: number;
  numberOfLicensees: number;
}

export interface AppraisalLicenseesGroupByPlanDashboardModel {
  activeLicenseesFinishedAppraisals: number;
  activeLicenseesStartedAppraisals: number;
  finishedAppraisals: number;
  inactiveLicenseesFinishedAppraisals: number;
  inactiveLicenseesStartedAppraisals: number;
  startedAppraisals: number;
  plan: LicensePlans;
}
