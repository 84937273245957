import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MergedRoute } from './state';
import { RouterReducerState } from '@ngrx/router-store/src/reducer';

export const selectRoot = createFeatureSelector<RouterReducerState<MergedRoute>>('router');
export const selectState = createSelector(selectRoot, (root) => root.state);
export const selectQueryParams = createSelector(selectState, (state) => state.queryParams);
export const selectQueryParam = (param: string) => createSelector(selectQueryParams, (queryParams) => queryParams[param]);
export const selectRouteParams = createSelector(selectState, (state) => state.params);
export const selectRouteParam = (param: string) => createSelector(selectRouteParams, (params) => params[param]);
export const selectRouteData = createSelector(selectState, (state) => state.data);
export const selectUrl = createSelector(selectState, (state) => state.url);

// export const selectRoot = createFeatureSelector<{ state: MergedRoute }>('router');
// export const selectQueryParams = createSelector(selectRoot, (state) => state.state.queryParams);
