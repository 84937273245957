import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { PATHS, SUB_PATHS } from '@foxeet/n-core';
import { ToastrWrapperService } from '@foxeet/utils/services/toastr-wrapper.service';
import { AuthService } from '../providers';

@Injectable({ providedIn: 'root' })
export class AuthTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private licenceHasExpired = false;

  constructor(private _authService: AuthService, private _router: Router, private _translateService: TranslateService, private _toastrWrapperService: ToastrWrapperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Bugfix IVFORCE-2504 fails on upload documents
    if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((error) => {
        if (error && (error.status === 401 || error.status === 402) && !request.url.match(/RefreshToken/) && !request.url.match(/Login/) && !this.licenceHasExpired) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request))),
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAuthenticationToken(request));
              }),
              finalize(() => (this.refreshTokenInProgress = false)),
            );
          }
        } else if (error && error.status === 402 && request.url.match(/RefreshToken/)) {
          const currentLicense = this._authService.getCurrentLicense();
          if (currentLicense?.isAdmin) {
            this._router.navigate([`${PATHS.ACCOUNT_MANAGEMENT}/${SUB_PATHS.BILLING_INFO}`]);
          } else {
            this._toastrWrapperService.error(this._translateService.instant('contactLicenseeAdmin'), this._translateService.instant('licenseExpired'));
          }
          return throwError({
            error: {
              title: this._translateService.instant('licenseExpired'),
              detail: this._translateService.instant('checkLicenseData'),
            },
          });
        } else if (error && error.status !== 200 && request.url.match(/RefreshToken/)) {
          this.refreshTokenInProgress = false;
          localStorage.clear();
          this._router.navigate([`${PATHS.LOGIN}`]);
          return throwError(error);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private refreshAccessToken(): Observable<any> {
    return this._authService.requestAccessToken().pipe(switchMap(() => of(true)));
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // const allowedDomains = ['192.168.16.10', '192.168.16.25', '192.168.16.24', '192.168.16.50', '192.168.16.37', 'iv.solutions'];
    if (!this._authService.getToken()) return request;
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this._authService.getToken()) });
  }
}
