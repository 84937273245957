import { MinMaxDates } from '@foxeet/domain';
import * as moment from 'moment';

type dateInputs = string | Date | moment.Moment;

export const transformDateToPrint = (date: dateInputs, format = 'YYYY-MM-DDTHH:mm:ss') => moment(date).format(format);
export const resetHour = (date: dateInputs): moment.Moment => moment(date).set({ hour: 0, minute: 0, second: 0 });
export const transformDateToSave = (date: string | Date) => new Date(date).toISOString();
export const minMaxDatesDependingBuildingConstructionYearToUse = (startDateString: string, buildingConstructionYearToUse: number, usefulLife: number): MinMaxDates => {
  const startDatetime = new Date(startDateString);
  const hours = startDatetime.getHours();
  const minutes = startDatetime.getMinutes();
  const newStartDatetime = new Date(startDatetime.getFullYear(), startDatetime.getMonth(), startDatetime.getDate(), hours, minutes);

  const maxDatetime = new Date(buildingConstructionYearToUse + usefulLife, startDatetime.getMonth(), startDatetime.getDate(), hours, minutes);
  const now = new Date();

  return {
    minStartDatetime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes).toISOString(),
    minFinishDatetime: newStartDatetime.toISOString(),
    maxStartDatetime: maxDatetime.toISOString(),
    maxFinishDatetime: maxDatetime.toISOString(),
  };
};
