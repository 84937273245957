import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './domain/market-study-chest.config';
import { MarketStudyReducer } from './redux/market-study.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MarketStudyEffects } from './redux/market-study.effects';
import { DataAccessModule } from '@foxeet/data-access';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, MarketStudyReducer), EffectsModule.forFeature([MarketStudyEffects]), DataAccessModule],
})
export class MarketStudyChestModule {}
