import { Injectable } from '@angular/core';
import { ExpandablePanel } from '../domain/model';

@Injectable()
export class ExpandableFilterService {
  public activeExpandablePanels: Map<number, ExpandablePanel> = new Map();

  public registerPanelAndGetPanel(): ExpandablePanel | undefined {
    const newRegisterId = this.activeExpandablePanels.size + 1;
    this.activeExpandablePanels.set(newRegisterId, { id: newRegisterId, opened: false });
    return this.activeExpandablePanels.get(newRegisterId);
  }
}
