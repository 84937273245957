import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoAlignment, InfoModalModel, InfoTypes } from '@foxeet/domain';
import { isNil } from '@foxeet/utils/functions';
import { ToastrWrapperService } from '@foxeet/utils/services';
@Component({
  selector: 'ui-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  public infoTypes = InfoTypes;
  public infoAlignment = InfoAlignment;

  constructor(
    @Optional() public dialogRef: MatDialogRef<InfoModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: InfoModalModel,
    private _toastWrapper: ToastrWrapperService,
  ) {
    console.log(data);
    this.data.infoType = isNil(this.data?.infoType) ? InfoTypes.simpleMessage : this.data?.infoType;
  }

  copyCode() {
    this._toastWrapper.success('copied');
  }
}
