import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { CustomWindow } from '../types/custom-window.type';

/**
 * Check if this environment can access Window object and return window or null if false.
 */
export function getWindow(platformId: any): CustomWindow | null {
  return isPlatformBrowser(platformId) ? ((<unknown>window) as CustomWindow) : null;
}

/**
 * Provide DOM Window reference or null if the environment is not a Browser.
 */
export const NGX_WINDOW = new InjectionToken<CustomWindow | null>('ngx-window', {
  providedIn: 'root',
  factory: () => getWindow(inject(PLATFORM_ID)),
});
