import { createAction, props } from '@ngrx/store';
import { AppraisalReportMarketStudyItemModel, AssetTypologies, AssetUses } from '@foxeet/domain';
import { FEATURE_NAME } from '../domain/market-study-chest.config';

const featureTITLE = `[${FEATURE_NAME}] `;

// funciones usadas en el effect para llamar a back
export const setMarketStudy = createAction(featureTITLE + 'setMarketStudy', props<{ use: AssetUses; typology: AssetTypologies; reportId: number }>());

// La info que se va a setear en el state para cada uso
export const setMarketStudyApi = createAction(featureTITLE + 'setMarketStudyAPI', props<{ marketStudies: AppraisalReportMarketStudyItemModel[] }>());
