import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { I18nModule } from '@foxeet/utils-modules';
import { FormValidationMessagesModule } from '../../form-validation-messages/web/form-validation-messages.module';
import { FormInputMaskComponent } from './form-input-mask.component';

@NgModule({
  declarations: [FormInputMaskComponent],
  imports: [CommonModule, InputMaskModule, FormValidationMessagesModule, ReactiveFormsModule, I18nModule.forChild()],
  exports: [FormInputMaskComponent],
})
export class FormInputMaskModule {}
