import { AssetUses, UrbanizationStates } from '../enums';

export interface BuildingTerrainDescriptionDataModel {
  terrainUse: AssetUses;
  urbanizationState: UrbanizationStates;
  withPaving: boolean;
  withStreetLighting: boolean;
  withWaterSupply: boolean;
  withSanitationNetwork: boolean;
  withTelephoneNetwork: boolean;
  withPipedGasNetwork: boolean;
  morphology: string;
  topography: string;
  observations: string;
  infrastructureObservations: string;
}
