import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@foxeet/utils-modules';
import { DetailMenuSectionsComponent } from './detail-menu-sections.component';
import { MatBadgeModule } from '@angular/material/badge';
import { WebExpertOnlyContainerModule } from '../../plan-container';

@NgModule({
  declarations: [DetailMenuSectionsComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    MatMenuModule,
    MatButtonModule,
    I18nModule.forChild(),
    MatBadgeModule,
    WebExpertOnlyContainerModule,
  ],
  exports: [DetailMenuSectionsComponent],
})
export class DetailMenuSectionsModule {}
