import { WorkTypes, WorkflowItemTypes } from '@foxeet/domain';
import { PATHS } from '@foxeet/n-core';

export class WorkTypesUtils {
  static getWorkTypeURLPath(workType: WorkTypes) {
    const paths = new Map([
      [WorkTypes.Appraisal, PATHS.APPRAISALS],
      [WorkTypes.AdditionalOrders, PATHS.MANAGEMENT_ORDERS],
      [WorkTypes.QualityControl, PATHS.QUALITY_CONTROL],
    ]);

    return paths.get(workType);
  }

  static getWorkTypeURLPathByWorkflowItemType(workType: WorkflowItemTypes) {
    const paths = new Map([
      [WorkflowItemTypes.Appraisal, PATHS.APPRAISALS],
      [WorkflowItemTypes.AdditionalOrder, PATHS.MANAGEMENT_ORDERS],
      [WorkflowItemTypes.ValidationAppraisal, PATHS.QUALITY_CONTROL],
    ]);

    return paths.get(workType);
  }

  static getWorkTypeByURLPath(path: string) {
    const workTypes = new Map([
      [PATHS.APPRAISALS, WorkTypes.Appraisal],
      [PATHS.MANAGEMENT_ORDERS, WorkTypes.AdditionalOrders],
      [PATHS.QUALITY_CONTROL, WorkTypes.QualityControl],
    ]);

    return workTypes.get(path);
  }
  static getWorkTypeByURLFull(url: string) {
    let path = '';

    if (url.includes(PATHS.APPRAISALS)) {
      path = PATHS.APPRAISALS;
    }
    if (url.includes(PATHS.MANAGEMENT_ORDERS)) {
      path = PATHS.MANAGEMENT_ORDERS;
    }
    if (url.includes(PATHS.QUALITY_CONTROL)) {
      path = PATHS.QUALITY_CONTROL;
    }

    const workTypes = new Map([
      [PATHS.APPRAISALS, WorkTypes.Appraisal],
      [PATHS.MANAGEMENT_ORDERS, WorkTypes.AdditionalOrders],
      [PATHS.QUALITY_CONTROL, WorkTypes.QualityControl],
    ]);

    return workTypes.get(path);
  }
}
