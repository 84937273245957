<span class="md-inputfield p-float-label my-4" *ngIf="control">
  <p-password
    styleClass="p-password p-component p-inputwrapper p-input-icon-right"
    [id]="componentId"
    class="input-password"
    [formControl]="control"
    [feedback]="showPasswordStrength"
    [toggleMask]="showPasswordBtn"
    [placeholder]="placeholder"
    appendTo="body"
  ></p-password>
  <label>{{ label }}</label>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"> </ui-form-validation-messages>
</span>
