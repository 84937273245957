<div class="custom-container">
  <div *ngFor="let item of items$ | async; let i = index; let first = first; let last = last" class="breadcrumElement">
    <div class="breadcrumElementContainer">
      <a *ngIf="item.link" [routerLink]="[item.link]" [queryParams]="item.queryParams" onSameUrlNavigation="'reload'">
        <span [ngClass]="{ breadcrumLastElement: last, notBreadcrumLastElement: !last }">{{ item?.label | translate }}</span>
      </a>

      <span *ngIf="!item.link" [ngClass]="{ breadcrumLastElement: last, notBreadcrumLastElement: !last }">{{ item?.label | translate }}</span>
    </div>
    <div *ngIf="!last" class="separator">
      <span>{{ charSeparator }}</span>
    </div>
  </div>
</div>
