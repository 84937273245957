import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-badge-text',
  templateUrl: './badge-text.component.html',
  styleUrls: ['./badge-text.component.scss'],
})
export class BadgeTextComponent implements OnInit {
  public itemTooltip: string;

  @Input() data: any = {};
  @Input() header: any = {};
  constructor() {}

  ngOnInit() {
    this.itemTooltip = `AppraisalIncidentCodes_${this.data.incidentCode}`;
  }
}
