export interface ImageCropperOptions {
  maintainAspectRatio: boolean;
  containWithinAspectRatio: boolean;
  resizeToWidth: number;
  cropperMinWidth: number;
  onlyScaleDown: boolean;
}

export interface ImageCropperTransformConfig {
  scale: number;
  minScale: number;
  maxScale: number;
  stepScale: number;
  minRotate: number;
  maxRotate: number;
  stepRotate: number;
  rotate: number;
  flipH: boolean;
  flipV: boolean;
  canvasRotation: number;
}

export enum ActionsTypes {
  ZoomIn,
  ZoomOut,
  Zoom,
  FlipH,
  FlipV,
  Rotate,
  RotateRight,
  RotateLeft,
}
