<mat-dialog-content class="wrapper container">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>{{'my_work_modal_title'| translate}}</h1>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 warning-message">
        <mat-icon>warning</mat-icon>
        <p [innerHTML]="'my_work_modal_body' | translate"></p>
      </div>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <ui-async-button
      size="md"
      componentId="AT_my_work_modal_btn"
      [label]="'my_work_modal_btn' | translate"
      [loadingLabel]="'sending' | translate"
      [asyncButton]="true"
      [buttonClassByType]="'primary'"
      (clickEvent)="rediretToMobile(data.licenseeId,data.appraisalId)"
    ></ui-async-button>
  </mat-dialog-actions>
</mat-dialog-content>
