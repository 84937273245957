import { Injectable } from '@angular/core';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { isNilOrEmptyString } from '@foxeet/utils/functions';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumItem } from '../domain/breadcrum.types';

@Injectable({ providedIn: 'root' })
export class BreadcrumService extends UnsuscriptionHandler {
  public items$: BehaviorSubject<BreadcrumItem[]> = new BehaviorSubject<BreadcrumItem[]>([]);

  constructor() {
    super();
  }

  public addItem(id: string, label: string, link: string, order: number, queryParams?: any): void {
    const translatedLabel = isNilOrEmptyString(label) ? '' : label;
    const newItem = { id, label: translatedLabel, link, order, queryParams };
    const oldItemList = this.items$.value;
    const newItemList = [...oldItemList, newItem].sort((a, b) => (a.order > b.order ? 1 : -1));
    this.items$.next(newItemList);
  }

  public getItem(id: string): BreadcrumItem | undefined {
    return this.items$.value.find((el) => el.id === id);
  }

  public removeItem(id: string): void {
    const indexToRemove = this.items$.value.findIndex((el) => el.id === id);
    const newList = this.items$.value;
    newList.splice(indexToRemove, 1);
    this.items$.next(newList);
  }

  public clearList(): void {
    this.items$.next([]);
  }
}
