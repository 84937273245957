import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  FakeMissingTranslationHandler,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateDirective,
  TranslateFakeCompiler,
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';
import { InternationalizationPipe } from './internationalization.pipe';

@NgModule({
  declarations: [InternationalizationPipe],
  imports: [TranslateModule],
  providers: [InternationalizationPipe],
  exports: [InternationalizationPipe, TranslateDirective],
})
export class I18nModule {
  /**
   * Use this method in your root module to provide the TranslateService
   */
  static forRoot(config: TranslateModuleConfig = {}): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        config.loader || { provide: TranslateLoader, useClass: TranslateFakeLoader },
        config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
        config.missingTranslationHandler || { provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler },
        TranslateStore,
        { provide: USE_STORE, useValue: config.isolate },
        { provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang },
        { provide: USE_EXTEND, useValue: config.extend },
        { provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage },
        TranslateService,
      ],
    };
  }

  /**
   * Use this method in your other (non root) modules to import the directive/pipe
   */
  static forChild(config: TranslateModuleConfig = {}): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        config.loader || { provide: TranslateLoader, useClass: TranslateFakeLoader },
        config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
        config.missingTranslationHandler || { provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler },
        { provide: USE_STORE, useValue: config.isolate },
        { provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang },
        { provide: USE_EXTEND, useValue: config.extend },
        { provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage },
        TranslateService,
      ],
    };
  }
}
