import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setMarketStudy, setMarketStudyApi } from './market-study.actions';
import { map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppraisalReportMarketStudyItemModel, AssetTypologies, AssetUses } from '@foxeet/domain';
import { Store } from '@ngrx/store';
import { UseMarketStudy } from './market-study.selectors';
import { MarketStudyReportService } from '@foxeet/data-access';

@Injectable()
export class MarketStudyEffects implements OnDestroy {
  private nsetMarketStudy = 0;

  private readonly subs = new Subscription();

  // Para el setsetMarketStudy (actions) se va a llamar a la función aquí setsetMarketStudy
  setMarketStudy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMarketStudy),
      mergeMap(({ use, typology, reportId }) => {
        return this.setMarketStudy(use, typology, reportId);
      }),
    ),
  );

  constructor(private actions$: Actions, private store: Store, private _marketStudyReportService: MarketStudyReportService) {
    this.subs.add(this.store.select(UseMarketStudy).subscribe((properties: any) => (this.nsetMarketStudy = properties.length)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // Llamada a back que, con setMarketStudyApi (en actions y en reducer), va a cambiar el state
  setMarketStudy(use: AssetUses, typology: AssetTypologies, reportId: number) {
    const defaultFilter = {
      data: {
        use,
        typology,
      },
      reportId,
    };

    return this._marketStudyReportService.AppraisalReportMarketStudyListAll<AppraisalReportMarketStudyItemModel[]>(defaultFilter).pipe(
      map((marketStudies) => {
        return setMarketStudyApi({ marketStudies });
      }),
    );
  }
}
