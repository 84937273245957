import { WorkflowItemIndexModel } from './appraisal.model';

export interface AppraisalClaimIndexModel {
  addedDateTime: string;
  addedUserId: number;
  currentStepValue: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  workflowItemId: number;
  id: number;
  workflowItem: WorkflowItemIndexModel;
  processManagerUserFullName: string;
  recordId: string;
}

export interface AppraisalClaimModel {
  addedDateTime: string;
  addedUserId: number;
  currentStepValue: number;
  isSolved: boolean;
  solvedDateTime: string;
  solvedUserId: number;
  workflowItemId: number;
  id: number;
  proceed: boolean;
  workflowItem: WorkflowItemIndexModel;
  addedUserFullName: string;
  reasonDescription: string;
  solutionDescription: string;
  solvedUserFullName: string;
  canSetAsProceed: boolean;
  canSetAsResolved: boolean;
}
