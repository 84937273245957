import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import {
  TableConfig,
  externalConnectionsUnicajaAppraisalListTableConfig,
  AppraisalDocumentListFilterComponent,
  AppraisalDocumentListMap,
  appraisalDocumentListTableConfig,
  ExternalConnectionsUnicajaAppraisalListFilterComponent,
  externalConnectionsUnicajaAppraisalListFilterConfig,
  ExternalConnectionsUnicajaAppraisalListMap,
} from '@foxeet/core';
import { IFormDataModel } from '@foxeet/domain';
import { NDataSource } from '@foxeet/utils/classes';
import { AppraisalIndexModel, AppraisalFileIndexModel, AppraisalFileWithAllSizeModel, GenericTableData } from '@foxeet/domain';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { EntitiesUtils } from '@foxeet/utils/functions';
import { UnicajaService } from '../../../../../data-access/src/lib/API/unicaja.service';
import { LicenseeFilterModel, LicenseeIndexModel, LicenseeTypes } from '../../../../../domain/src';

export interface ExternalConnectionsTableConfig {
  tableConfig: TableConfig;
  dataSource: any;
  filterConfig: IFormDataModel[][];
  filterClass: any;
  mapper: any;
  loadData: () => Observable<GenericTableData<AppraisalIndexModel[]>>;
}

@Injectable({
  providedIn: 'root',
})
export class ExternalConnectionsService extends UnsuscriptionHandler {
  public externalConnection$: BehaviorSubject<string> = new BehaviorSubject('');
  public externalConnectionData$: BehaviorSubject<any> = new BehaviorSubject(null);
  public externalConnectionsTableConfig: ExternalConnectionsTableConfig;
  public externalConnectionsDocumentsTableConfig: ExternalConnectionsTableConfig;
  public appraisalId$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private _unicajaService: UnicajaService,
    private _externalConnectionsUnicajaAppraisalListMap: ExternalConnectionsUnicajaAppraisalListMap,
    private _appraisalDocumentListMap: AppraisalDocumentListMap,
  ) {
    super();
  }

  public setURLData(activadedRoute: ActivatedRouteSnapshot) {
    this.externalConnection$.next(activadedRoute.data?.['external']);
    this.externalConnectionData$.next(activadedRoute.queryParams);
    this.appraisalId$.next(activadedRoute.params.id || null);
  }

  public getQueryParams(): any | null {
    return this.externalConnectionData$.value || null;
  }

  public getUnicajaListPaged(): Observable<GenericTableData<AppraisalIndexModel[]>> {
    const { filterClass } = this.externalConnectionsTableConfig;
    const { page, size, data } = filterClass.getCurrentFilter();
    const { unicajaCentro, unicajaHash, unicajaHora } = this.externalConnectionData$.value;

    return this._unicajaService.appraisalsListPagedByFilter(page, size, unicajaCentro, unicajaHash, unicajaHora, data);
  }

  _getCustomers = (filter: any) => {
    const { unicajaCentro, unicajaHash, unicajaHora } = this.externalConnectionData$.value;
    const newFilter: LicenseeFilterModel = { ...filter, licenseeTypes: [LicenseeTypes.Customer] };
    return this._unicajaService.getCustomers<LicenseeIndexModel>(newFilter, unicajaCentro, unicajaHash, unicajaHora);
  };

  public getTableConfig(externalConnection: string) {
    const externalConnectionsTableConfig: Map<string, () => ExternalConnectionsTableConfig> = new Map([
      [
        'unicaja',
        () => {
          const filterClass: ExternalConnectionsUnicajaAppraisalListFilterComponent = new ExternalConnectionsUnicajaAppraisalListFilterComponent('UnicajaFilter');
          const dataSource: NDataSource<AppraisalIndexModel, UnicajaService, ExternalConnectionsUnicajaAppraisalListMap, ExternalConnectionsUnicajaAppraisalListFilterComponent> =
            new NDataSource(this._unicajaService, this._externalConnectionsUnicajaAppraisalListMap, filterClass);
          return {
            loadData: this.getUnicajaListPaged.bind(this),
            tableConfig: cloneDeep(externalConnectionsUnicajaAppraisalListTableConfig),
            filterConfig: externalConnectionsUnicajaAppraisalListFilterConfig(this._getCustomers),
            mapper: this._externalConnectionsUnicajaAppraisalListMap,
            filterClass,
            dataSource,
          };
        },
      ],
    ]);

    this.externalConnectionsTableConfig = externalConnectionsTableConfig.get(externalConnection)();
    return this.externalConnectionsTableConfig;
  }

  getUnicajaDocumentsList(): Observable<AppraisalFileWithAllSizeModel> {
    const { filterClass } = this.externalConnectionsDocumentsTableConfig;
    const { data } = filterClass.getCurrentFilter();
    const { unicajaCentro, unicajaHash, unicajaHora } = this.externalConnectionData$.value;

    return this._unicajaService.appraisalsDocumentsListByFilter(unicajaCentro, unicajaHash, unicajaHora, data);
  }

  getDocumentsTableConfig(externalConnection: string) {
    const externalConnectionsDocumentsTableConfig: Map<string, () => ExternalConnectionsTableConfig> = new Map([
      [
        'unicaja',
        () => {
          const defaultFilter = {
            page: 1,
            size: 100,
            data: {
              orderFieldName: 'AddedDateTime',
              orderFieldAsc: false,
              workflowItemId: this.appraisalId$.value,
            },
          };
          const filterClass: AppraisalDocumentListFilterComponent = new AppraisalDocumentListFilterComponent('AppraisalDocumentFilter', defaultFilter);
          const dataSource: NDataSource<AppraisalFileIndexModel, UnicajaService, AppraisalDocumentListMap, AppraisalDocumentListFilterComponent> = new NDataSource(
            this._unicajaService,
            this._appraisalDocumentListMap,
            filterClass,
          );
          return {
            loadData: this.getUnicajaDocumentsList.bind(this),
            tableConfig: cloneDeep(appraisalDocumentListTableConfig),
            filterConfig: null,
            mapper: this._appraisalDocumentListMap,
            filterClass,
            dataSource,
          };
        },
      ],
    ]);
    this.externalConnectionsDocumentsTableConfig = externalConnectionsDocumentsTableConfig.get(externalConnection)();

    return this.externalConnectionsDocumentsTableConfig;
  }

  getLogoUrl(externalConnection) {
    const logoUrl: Map<string, string> = new Map([['unicaja', '../assets/images/logo-unicaja.png']]);

    return logoUrl.get(externalConnection);
  }
}
