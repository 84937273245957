<div class="p-3">
  <div class="p-grid">
    <div class="p-col">
      <h1>{{ title | translate }}</h1>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col">
      <p>{{ message | translate }}</p>
    </div>
  </div>
  <div class="d-flex justify-content-around">
    <div *ngFor="let button of buttonList">
      <ui-async-button
        componentId="AT_button_{{ button.label }}"
        size="md"
        [label]="button.label | translate"
        [loadingLabel]="button.label | translate"
        [asyncButton]="button.showState"
        [inputState]="button.state"
        [buttonClassByType]="button.buttonClassByType"
        (clickEvent)="selectedOption(button)"
      >
      </ui-async-button>
    </div>
  </div>
</div>
