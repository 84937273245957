import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormInputMultiselectComponent } from './form-input-multiselect.component';

@NgModule({
  declarations: [FormInputMultiselectComponent],
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule, I18nModule.forChild()],
  exports: [FormInputMultiselectComponent],
})
export class FormInputMultiselectModule {}
