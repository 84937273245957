<div class="wrapper">
  <mat-stepper labelPosition="bottom" #stepper>
    <mat-step *ngFor="let step of data?.steps">
      <div class="step-wrapper" class="{{ step.stepClass ? step.stepClass : '' }}">
        <div *ngIf="step?.image" class="step-image">
          <img [src]="step?.image" />
        </div>

        <div class="step-content" class="{{ step.stepClass ? step.stepClass : '' }}">
          <h4 *ngIf="step?.titleInfo" class="step-title  {{ step.titleClass ? step.titleClass : '' }}">{{ step?.titleInfo | translate }}</h4>

          <h2 *ngIf="step?.title" class="step-title  {{ step.titleClass ? step.titleClass : '' }}">{{ step?.title | translate }}</h2>

          <div *ngIf="!!step?.paragraphsBeforeImage?.length" class="step-paragraphs-wrapper {{ step?.paragraphsContainerClasses ? step?.paragraphsContainerClasses : '' }}">
            <p
              *ngFor="let paragraph of step?.paragraphsBeforeImage; i"
              class="{{ step?.paragraphsClasses && step?.paragraphsClasses[i] ? step?.paragraphsClasses[i] : '' }}"
              [innerHTML]="paragraph | translate | safe : 'html'"
            ></p>
          </div>

          <div *ngIf="step?.imageSubtitle" class="step-image {{ step.imageClass ? step.imageClass : '' }}">
            <img [src]="step?.imageSubtitle" />
          </div>

          <h2 *ngIf="step?.titleAfterImage" class="step-title">{{ step?.titleAfterImage | translate }}</h2>

          <div *ngIf="!!step?.paragraphs?.length" class="step-paragraphs-wrapper {{ step?.paragraphsContainerClasses ? step?.paragraphsContainerClasses : '' }}">
            <p
              class="{{ step?.paragraphsClasses && step?.paragraphsClasses[i] ? step?.paragraphsClasses[i] : '' }}"
              *ngFor="let paragraph of step?.paragraphs"
              [innerHTML]="paragraph | translate | safe : 'html'"
            ></p>
          </div>

          <div *ngIf="step?.imageAfterParagraph" class="step-image {{ step.imageClass ? step.imageClass : '' }}">
            <div *ngFor="let imageEach of step?.imageAfterParagraph; let indexImage = index">
              <img [src]="imageEach" />
            </div>
          </div>

          <div class="step-button" *ngIf="step?.button">
            <button mat-flat-button color="primary" (click)="step?.button?.callback()">{{ step?.button?.title | translate }}</button>
          </div>

          <div class="d-flex align-items-center justify-content-between" *ngIf="step?.buttons">
            <div class="step-button" *ngFor="let button of step?.buttons">
              <button mat-flat-button color="primary" (click)="button?.callback()">{{ button?.title | translate }}</button>
            </div>
          </div>

          <div *ngIf="!!step?.sections?.length">
            <div
              class="step-section-wrapper"
              class="{{ section?.classes }}"
              [ngClass]="{ reverse: section.direction === sectionType.reverse }"
              *ngFor="let section of step?.sections"
            >
              <div class="section-image">
                <img [src]="section.image" />
              </div>

              <div class="section-content" [ngStyle]="{ 'text-align': section?.textAlign ? section?.textAlign : 'center' }">
                <p *ngIf="section?.title">{{ section?.title | translate | uppercase }}</p>
                <p *ngFor="let sectionParagraph of section?.paragraphs" [innerHTML]="sectionParagraph | translate | safe : 'html'"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>

  <div class="footer">
    <div class="button" id="AT_previous_step_btn" [ngClass]="{ show: stepper.selectedIndex > 0 }" (click)="stepper.previous()"><mat-icon>chevron_left</mat-icon></div>
    <div
      *ngFor="let step of data?.steps; let index = index"
      id="AT_step_{{ index }}_btn"
      class="button-step"
      [ngClass]="{ active: index === stepper.selectedIndex }"
      (click)="changeStep(index)"
    ></div>
    <div class="button" id="AT_next_step_btn" [ngClass]="{ show: stepper.selectedIndex < data?.steps.length - 1 }" (click)="stepper.next()"><mat-icon>chevron_right</mat-icon></div>
  </div>
</div>
