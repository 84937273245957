import { Component, Input } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-password',
  templateUrl: './form-input-password.component.html',
  styleUrls: ['./form-input-password.component.scss'],
})
export class FormInputPassWordComponent extends BaseFormComponent<string> {
  @Input() showPasswordStrength = false;
  @Input() showPasswordBtn = true;
}
