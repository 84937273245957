<div class="example-container">
  <mat-toolbar color="primary" class="example-toolbar">
    <div class="left-container">
      <div class="menu-button-and-logo-container">
        <!-- <button class="menu-button" *ngIf="mobileQuery.matches" mat-icon-button (click)="setMenuListVisibility(true, true)">
            <mat-icon>menu</mat-icon>
          </button> -->

        <a class="img-as-link" [routerLink]="baseUrl" [queryParams]="queryParams">
          <img src="../assets/images/logo-tasafy.png" />
        </a>
      </div>

      <!-- Valorate implement hover event to navbar buttons -->
      <!-- https://stackoverflow.com/questions/53618333/how-to-open-and-close-angular-mat-menu-on-hover -->

      <!-- <ng-container *ngIf="!mobileQuery.matches">
          <ui-top-navbar-menu style="height: 100%"></ui-top-navbar-menu>
        </ng-container> -->
    </div>

    <div class="right-container">
      <a class="img-as-link">
        <img class="right-logo" [src]="logoUrl" />
      </a>
      <!-- <a
          class="img-as-link"
          href="https://www.institutodevaloraciones.com/?gclid=CjwKCAiA7dKMBhBCEiwAO_crFFabX2fxFb26WX2L_37sc5ALdlHQczOfSjf-bRrbAv1aQXF6kBOYSBoCD6wQAvD_BwE"
          target="_blank"
        >
          <img class="right-logo" src="../assets/images/IMG_0156.PNG" />
        </a> -->
      <!-- <ui-selector-language [languages]="AVAILABLE_LANGUAGES"></ui-selector-language> -->
      <!-- <button *utilsShowIfHasAuthorities="'financial'" mat-icon-button [routerLink]="['/' + PATHS.FINANCIAL + '/' + PATHS.PAYMENT_SUMMARY]" (click)="clearMenuSelectedItem()">
          <mat-icon>account_balance</mat-icon>
        </button> -->

      <!-- <button *utilsShowIfHasAuthorities="'users-credentials'" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>people</mat-icon>
  
          <mat-menu #menu="matMenu" yPosition="below">
            <ng-container *ngFor="let subMenuItem of userWithCredentialsMenuConfig$ | async">
              <button *ngIf="subMenuItem.isVisible | async" mat-menu-item style="width: 20em; width: 15em" (click)="onItemClick(subMenuItem)" [id]="'AT_' + subMenuItem?.label">
                {{ subMenuItem?.label | translate }}
              </button>
            </ng-container>
          </mat-menu>
        </button> -->
      <!-- <feature-profile-menu
          id="AT_profile_menu_btn"
          [userName]="(userLocalData$ | async)?.firstName + ' ' + (userLocalData$ | async)?.lastName"
          [avatar]="(userLocalData$ | async)?.profileImagePath"
          (logout)="onLogout()"
          [menuItems]="profileMenuItems"
        ></feature-profile-menu> -->
    </div>
  </mat-toolbar>
  <!-- [style.marginTop.px]="mobileQuery.matches ? 64 : 0" [hasBackdrop]="mobileQuery.matches"  -->
  <mat-sidenav-container class="example-sidenav-container" autosize>
    <!-- <mat-sidenav
        #snav
        fixedTopGap="64"
        [ngClass]="{ 'is-open': isMenuVisible, 'left-menu': !mobileQuery.matches }"
        [mode]="'over'"
        [opened]="!mobileQuery.matches"
        [fixedInViewport]="mobileQuery.matches"
        [disableClose]="!mobileQuery.matches"
        (mouseenter)="setMenuListVisibility(true)"
        (mouseleave)="setMenuListVisibility(false)"
      >
        <ui-sidenav-menu [isMenuVisible]="isMenuVisible" (closeMenu)="closeMenu()"></ui-sidenav-menu>
      </mat-sidenav> -->
    <!-- [style.marginLeft.rem]="mobileQuery.matches ? 0 : 6" -->
    <mat-sidenav-content>
      <div class="content-container">
        <ui-breadcrumb></ui-breadcrumb>
        <router-outlet></router-outlet>
      </div>
      <div class="bottom-container">
        <ui-bottom-bar></ui-bottom-bar>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
