import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraisalExternalCommunicationProcessesService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalExternalCommunicationProcesses', cache);
  }

  getExternalCommunicationsEvents<T>(page: number, size: number, id: string, body: any): Observable<T> {
    return this.post<typeof body, T>(`${id}/Events`, body, {
      params: this.setParams({
        page,
        size,
        id,
      }),
    });
  }

  getExternalCommunicationsBody(id: number, eventId: number): Observable<string> {
    return this.get<string>(`${id}/Events/${eventId}`, { observe: 'response', responseType: 'text' });
  }

  public markKoAsChecked(id: number, description: string) {
    return this.put<{ id: number; description: string }, void>(`${id}/KoCheck`, { id, description });
  }
}
