import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

/**
 * Check if this environment can access Window object and return window or null if false.
 */
export function getWindow(platformId: any): Window | null {
  return isPlatformBrowser(platformId) ? window : null;
}

/**
 * Provide DOM Window reference or null if the environment is not a Browser.
 */
export const WINDOW = new InjectionToken<Window | null>('hj-window', {
  factory: () => getWindow(inject(PLATFORM_ID)),
});
