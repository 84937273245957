import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PatternsErrorKeys } from '@foxeet/domain';
import { isNilOrEmptyString } from '../functions';
import { Pattern } from '../interfaces';
import { ALPHANUMERIC } from '../forms';

export const checkIfFieldsAreEqual = (fieldA: string, fieldB: string, errorKey = PatternsErrorKeys.NOT_SAME): ValidatorFn => {
  return (group: AbstractControl): ValidationErrors | null => {
    const controlA = group.get(fieldA);
    const controlB = group.get(fieldB);

    const areDirty = controlA?.dirty && controlB?.dirty;
    const areEqual = controlA?.value === controlB?.value;
    const areNotEmpty = !isNilOrEmptyString(controlA?.value) && !isNilOrEmptyString(controlB?.value);

    if (areDirty) {
      if (areNotEmpty && !areEqual) {
        controlB?.setErrors({ ...controlB?.errors, [errorKey]: true });
        return { [errorKey]: true };
      } else {
        [controlA, controlB].forEach((c) => {
          if (c?.hasError(errorKey)) {
            delete c?.errors?.[errorKey];
          }
          c?.setErrors(c?.errors);
          c?.updateValueAndValidity({ onlySelf: true });
        });
      }
    }
    return null;

    /*
    if (areDirty) {
      if (!areEqual && areNotEmpty) {
        controlA.setErrors({ ...controlA.errors, [errorKey]: true });
        controlB.setErrors({ ...controlB.errors, [errorKey]: true });
      } else {
        [controlA, controlB].forEach((c) => {
          if (c.hasError(errorKey)) {
            delete c?.errors?.[errorKey];
          }
          c.setErrors(c.errors);
          c.updateValueAndValidity({ onlySelf: true });
        });
      }
    }

    return !areNotEmpty || areEqual ? null : { [errorKey]: true };*/
  };
};

export function customRegexValidator(regEx: RegExp, errorKey: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let correctValue = true;
    if (!isNilOrEmptyString(control.value)) {
      correctValue = regEx.test(control.value);
    }
    return correctValue ? null : { [errorKey]: { value: control.value } };
  };
}

export const RegexValidator =
  (pattern: Pattern): ValidatorFn =>
  (control: AbstractControl): Record<string, unknown> | null =>
    !isNilOrEmptyString(control.value) && pattern.pattern.test(control.value) ? null : { [pattern.errorKey]: { value: control.value } };

export const alphanumericValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const alphanumeric = ALPHANUMERIC;
    const correct = !isNilOrEmptyString(control.value) && alphanumeric.pattern.test(control.value);
    return !correct ? { alphanumeric: true } : null;
  };
};

export const minLengthArray = (min: number) => {
  return (c: AbstractControl): Record<string, unknown> | null => {
    if (c.value.length >= min) return null;

    return { MinLengthArray: true };
  };
};
