import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ErrorDialogService } from './services/error-dialog.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';

@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [ErrorDialogComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ErrorDialogService,
  ],
})
export class ErrorHandlerModule {}
