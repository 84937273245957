<table mat-table [dataSource]="dataSource" matSort>
  <ng-container *ngFor="let column of tableConfig.displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate }}</th>
    <td mat-cell *matCellDef="let element">{{ element[column] | DecimalFormatterPipe : internationalizationTypes.Currency }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="tableConfig.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: tableConfig.displayedColumns"></tr>
</table>

<mat-paginator *ngIf="!disablePagination" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
