<span class="md-inputfield p-float-label my-4" *ngIf="control" [ngClass]="{ readonly: readonly }">
  <ng-container *ngIf="enableFormat; else notEnableFormat">
    <input
      [ngClass]="{ 'p-disabled': control?.disabled || disabled, 'p-filled': control?.value !== null && control?.value !== undefined }"
      [id]="componentId"
      title="{{ componentId }}"
      type="text"
      pInputText
      utilsNumberFormatterDirective
      [suffix]="suffix"
      [suffixType]="suffixType"
      [min]="min"
      [max]="max"
      [digitsInfo]="digitsInfo"
      [formControl]="control"
      [readonly]="readonly"
      (blur)="blurEvent.next()"
    />
  </ng-container>
  <ng-template #notEnableFormat>
    <input
      [ngClass]="{ 'p-disabled': control?.disabled || disabled, 'p-filled': control?.value !== null && control?.value !== undefined }"
      [id]="componentId"
      title="{{ componentId }}"
      type="number"
      [min]="min"
      [max]="max"
      minMaxRestriction
      [step]="step"
      class="p-inputtext"
      [formControl]="control"
      [readonly]="readonly"
      (blur)="blurEvent.next()"
    />
  </ng-template>
  <label>{{ label | translate }}</label>
  <span *ngIf="hintLabel" class="hintLabelClass">{{ hintLabel | translate }}</span>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
</span>
