import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setTypologies, setTypologiesApi, setSubTypologies, setSubTypologiesApi } from './typology.actions';
import { map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AssetTypologies, AssetUses } from '@foxeet/domain';
import { EnumsService } from '@foxeet/data-access';
import { UseSubTypologies, UseTypologies } from './typology.selectors';

/**
 * @todo Comentar o modificar los filters para que se lancen cuando hayan distintas traducciones de la info
 */
@Injectable()
export class TypologyEffects implements OnDestroy {
  private nTypologies = 0;

  private readonly subs = new Subscription();

  // Para el setTypologies (actions) se va a llamar a la función aquí setTypologies
  setTypologies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setTypologies),
      mergeMap(({ use }) => {
        return this.setTypologies(use);
      }),
    ),
  );

  setSubTypologies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSubTypologies),
      mergeMap(({ typology }) => {
        return this.setSubTypologies(typology);
      }),
    ),
  );

  constructor(private actions$: Actions, private readonly enumsService: EnumsService, private store: Store) {
    this.subs.add(this.store.select(UseTypologies).subscribe((properties: any) => (this.nTypologies = properties.length)));
    this.subs.add(this.store.select(UseSubTypologies).subscribe((properties: any) => (this.nTypologies = properties.length)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // Llamada a back que, con setTypologiesApi (en actions y en reducer), va a cambiar el state
  setTypologies(use: AssetUses) {
    return this.enumsService.getAssetTypologies(use).pipe(
      map((typologies) => {
        return setTypologiesApi({ use, typologies });
      }),
    );
  }
  setSubTypologies(typology: AssetTypologies) {
    return this.enumsService.getAssetSubTypologies(typology).pipe(
      map((subTypologies) => {
        return setSubTypologiesApi({ typology, subTypologies });
      }),
    );
  }
}
