import { AssetSubTypologies, AssetTypologies, AssetUses, IdealistaBuiltType, IdealistaOperation } from '../enums';

export interface IdealistaRequestModel {
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  operation: IdealistaOperation;
  valorationAdoptedTotalSurface: number;
  minValorationTotalAdoptedSurface: number;
  agesince: number;
  ageuntil: number;
  latitude: number;
  longitude: number;
  distance: number;
  propertyType: IdealistaBuiltType;
  bedroomCount: number;
  roomnumberfrom: number;
  roomnumberto: number;
  bathroomCount: number;
  bathnumberfrom: number;
  bathnumberto: number;
  removeduplicates: boolean;
  builttype: IdealistaBuiltType;
  isintopfloor: boolean;
  isinmiddlefloor: boolean;
  isingroundfloor: boolean;
  haslift: boolean;
  hasparkingspace: boolean;
  hasStorageRoom: boolean;
  hasPool: boolean;
  hasGreenArea: boolean;
  hasterrace: boolean;
}
