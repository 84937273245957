enum ImgFlagsAllowed {
  SPAIN = 'flag-spain',
  UK = 'flag-uk',
  PORTUGAL = 'flag-portugal',
}

interface LanguageSelectorItem {
  cultureCodeLanguage: string;
  lang: string;
  label: string;
  img: string;
  flag?: string;
}

interface LanguageFile {
  cultureCodeLanguage: string;
  languageKey: string;
  icon: string;
  translates: any;
}

type LanguageSelectorList = LanguageSelectorItem[];

export { ImgFlagsAllowed, LanguageSelectorItem, LanguageSelectorList, LanguageFile };
