<div class="wrapper">
  <div class="padding header">
    <h2>
      {{ 'change_picture' | translate }}
    </h2>
  </div>

  <ui-image-cropper-customizer
    [aspectRatio]="data?.aspectRatio ?? 1"
    [roundCropper]="data?.roundCropper ?? true"
    [imageCropperOptions]="data?.imageCropperOptions ?? defaultImageCropperOptions"
    [imageEvent]="fileEvent"
    (imageCroppedEmitter)="setCroppedImage($event)"
  ></ui-image-cropper-customizer>

  <div class="padding upload-button row justify-content-end">
    <input #inputFile type="file" name="files[]" (change)="uploadImage($event)" placeholder="Upload file" accept=".jpg,.png,.svg" />
    <ui-async-button
      [asyncButton]="false"
      [buttonClassByType]="'primary'"
      [size]="'md'"
      [label]="'SIMPLE_NOTE_attach' | translate"
      componentId="AT_adjuntar"
      [loadingLabel]="'APPRAISALS_attaching' | translate"
      (clickEvent)="inputFile.click()"
    ></ui-async-button>
  </div>

  <div class="padding footer">
    <div class="d-flex justify-content-between">
      <ui-async-button
        componentId="AT_closeButton"
        [asyncButton]="false"
        [buttonClassByType]="'secondary'"
        [size]="'md'"
        [label]="'close' | translate"
        [loadingLabel]="'closing' | translate"
        (clickEvent)="closeEventTrigger()"
      ></ui-async-button>

      <ui-async-button
        [asyncButton]="false"
        [buttonClassByType]="'primary'"
        [size]="'md'"
        [label]="'save' | translate"
        componentId="AT_save_btn"
        [loadingLabel]="''"
        (clickEvent)="saveProfileImage()"
      ></ui-async-button>
    </div>
  </div>
</div>
