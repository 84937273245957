/**
 * @todo BORRAR CUANDO UTILS BUILDEABLE
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ObjectTS {
  // eslint-disable-next-line @typescript-eslint/ban-types
  export const keys = <T = {}>(v: T): Array<keyof typeof v> => Object.keys(v) as Array<keyof typeof v>;
  export const values = <T>(v: { [s: string]: T } | ArrayLike<T>) => Object.values<T>(v);
  export const getProperty = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];
  export const setProperty = <T, K extends keyof T>(obj: T, key: K, value: T[K]): void => {
    obj[key] = value;
  };
}
import { EnumsModel, OrNullOrUndef } from '@foxeet/domain';

export const isNil = (value?: any): value is null | undefined => [null, undefined].includes(value);

export const isEmptyString = (value: string): boolean => !value.length;

export const isNilOrEmptyString = (value: OrNullOrUndef<string>): boolean => isNil(value) || value === '';

export class FormUtils {
  static fromEnumToKeyValueArray(enumerator: { [x: string]: any }, enumName?: string): EnumsModel[] {
    const keys = Object.keys(enumerator).filter((key) => isNaN(+key));
    return keys.map((key) => ({
      key: FormUtils.isBoolean(enumerator[key]) ? FormUtils.transformBooleanValue(enumerator[key]) : enumerator[key],
      value: key,
      translateKey: `${enumName || key}_${enumerator[key]}`,
    }));
  }

  static isBoolean(value: string) {
    const booleanValues = ['true', 'false', 'True', 'False', 'TRUE', 'FALSE'];
    return booleanValues.includes(value);
  }

  static transformBooleanValue(value: string) {
    const trueValues = ['true', 'True', 'TRUE'];
    return trueValues.includes(value);
  }
}
