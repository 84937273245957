import { AssetCreateModel, AssetUses, OrderRequestElementModel } from '@foxeet/domain';

export const assetCreateModelToOrderRequestElementModel = (cadastralData: AssetCreateModel): OrderRequestElementModel => ({
  // added parentAssetId temporally
  parentId: cadastralData?.parentAssetId,
  postalCode: cadastralData?.assetLocalization?.postalCode,
  streetNumber: cadastralData?.assetLocalization?.streetNumber,
  province: cadastralData?.assetLocalization?.province,
  ineCodProv: cadastralData?.assetLocalization?.ineCodProv,
  locality: cadastralData?.assetLocalization?.locality,
  ineCodMun: cadastralData?.assetLocalization?.ineCodMun,
  streetName: cadastralData?.assetLocalization?.streetName,
  block: cadastralData?.assetLocalization?.block,
  stairs: cadastralData?.assetLocalization?.stairs,
  floorType: cadastralData?.assetLocalization?.floorType,
  gate: cadastralData?.assetLocalization?.gate,
  dgcCodVia: cadastralData?.assetLocalization?.dgcCodVia,
  ineCodVia: cadastralData?.assetLocalization?.ineCodVia,
  use: cadastralData?.assetTypologies?.use,
  typology: cadastralData?.assetTypologies?.typology,
  surface: [AssetUses.Terrain, AssetUses.RusticEstate].includes(cadastralData?.assetTypologies?.use)
    ? cadastralData?.assetSurfaces?.cadastralSurfaces?.totalPlotSurface
    : cadastralData?.assetSurfaces?.cadastralSurfaces?.totalBuiltSurfaceWithCommonSurfaces,
  constructionYear: cadastralData?.buildingInformation?.constructionYear,
  cadastralReference: cadastralData?.assetIdentification?.cadastralReference,
  propertyRegisterGeoSmartaId: cadastralData?.assetIdentification?.propertyRegisterGeoSmartaId,
  registeredPropertyNumber: cadastralData?.assetIdentification?.registeredPropertyNumber,
  volume: cadastralData?.assetIdentification?.volume,
  book: cadastralData?.assetIdentification?.book,
  section: cadastralData?.assetIdentification?.section,
  idufir: cadastralData?.assetIdentification?.idufir,
  inscription: cadastralData?.assetIdentification?.inscription,
  countryThreeLetterISORegionName: cadastralData?.assetLocalization?.countryThreeLetterISORegionName,
  ineCodLocality: cadastralData?.assetLocalization?.ineCodLocality,
  polygonCode: cadastralData?.assetLocalization?.polygonCode,
  plotCode: cadastralData?.assetLocalization?.plotCode,
  fullAddressGeneratedName: cadastralData?.assetLocalization?.fullAddressGeneratedName,
  addressGeneratedStartingByProvince: cadastralData?.assetLocalization?.addressGeneratedStartingByProvince,
});

export const assetCreateModelToComparableModel = (cadastralData: AssetCreateModel) => {
  return {
    // added parentAssetId temporally
    parentId: cadastralData?.parentAssetId,
    postalCode: cadastralData?.assetLocalization?.postalCode,
    streetNumber: cadastralData?.assetLocalization?.streetNumber,
    province: cadastralData?.assetLocalization?.province,
    ineCodProv: cadastralData?.assetLocalization?.ineCodProv,
    locality: cadastralData?.assetLocalization?.locality,
    latitude: cadastralData?.assetLocalization?.latitude,
    longitude: cadastralData?.assetLocalization?.longitude,
    ineCodMun: cadastralData?.assetLocalization?.ineCodMun,
    streetName: cadastralData?.assetLocalization?.streetName,
    block: cadastralData?.assetLocalization?.block,
    stairs: cadastralData?.assetLocalization?.stairs,
    floorType: cadastralData?.assetLocalization?.floorType,
    gate: cadastralData?.assetLocalization?.gate,
    dgcCodVia: cadastralData?.assetLocalization?.dgcCodVia,
    ineCodVia: cadastralData?.assetLocalization?.ineCodVia,
    use: cadastralData?.assetTypologies?.use,
    typology: cadastralData?.assetTypologies?.typology,
    plotTotalSurface: cadastralData?.assetSurfaces?.cadastralSurfaces?.totalPlotSurface,
    totalCadastralSurface: cadastralData?.assetSurfaces?.cadastralSurfaces?.totalBuiltSurfaceWithCommonSurfaces,
    coveredTerraceSurface: cadastralData?.assetSurfaces?.cadastralSurfaces?.totalCoveredTerraceSurface,
    uncoveredTerraceSurface: cadastralData?.assetSurfaces?.cadastralSurfaces?.totalUncoveredTerraceSurface,
    constructionYear: cadastralData?.buildingInformation?.constructionYear,
    cadastralReference: cadastralData?.assetIdentification?.cadastralReference,
    countryThreeLetterISORegionName: cadastralData?.assetLocalization?.countryThreeLetterISORegionName,
    ineCodLocality: cadastralData?.assetLocalization?.ineCodLocality,
    polygonCode: cadastralData?.assetLocalization?.polygonCode,
    plotCode: cadastralData?.assetLocalization?.plotCode,
    fullAddressGeneratedName: cadastralData?.assetLocalization?.fullAddressGeneratedName,
    addressGeneratedStartingByProvince: cadastralData?.assetLocalization?.addressGeneratedStartingByProvince,
  };
};
