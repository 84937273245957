import { AppraisalPurposes, LicenseeTypes, WorkerRoles } from '../enums/backend.enums';
import { WorkflowItemStepDataModel } from './dashboard.model';

export interface ValidationAppraisalFilter {
  purpose?: AppraisalPurposes;
  purposes?: number[];
  addedMaxDateTime?: string;
  addedMinDateTime?: string;
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
  userIdAndWorkerRole?: number;
  licenseeIdAndWorkflowItemLicenseeType?: number;
}

export interface ValidationAppraisalDashboardModel {
  totalInProgressCount: number;
  totalPendingValidation: number;
  totalPendingAssignAppraiser: number;
  totalValidationInProgress: number;
  validatedTotalCount: number;
  validationAppraisalsOk: number;
  validationAppraisalsKo: number;
  workflowItemCount: number;
  workflowItemStepData: WorkflowItemStepDataModel[];
}

export interface ValidationAppraisalElapsedTimesDashboardModel {
  workflowItemStepData: WorkflowItemStepDataModel[];
  totalAverageTime: string;
  assignationAverageTime: string;
  validationAverageTime: string;
}
