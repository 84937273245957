import { UnsuscriptionHandler } from '../components';
import { Subject } from 'rxjs';
import { UrlDataService } from '@foxeet/n-core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cloneObject, dynamicInjector, isNil } from '../functions';
import { takeUntil } from 'rxjs/operators';
import { FilterModel } from '@foxeet/domain';

export const defaultConstFilter: FilterModel = {
  page: 1,
  size: 10,
  activeFilterBarTab: null,
  data: {
    orderFieldName: 'addedDateTime',
    orderFieldAsc: false,
  },
};

export abstract class Filter extends UnsuscriptionHandler {
  // Refactor
  protected _filterName: string;
  public get filterName() {
    return this._filterName;
  }

  protected filter: Subject<FilterModel> = new Subject();

  protected defaultFilter: FilterModel;

  protected currentFilter: FilterModel = { size: 10, page: 0, data: {} };

  protected _urlDataService!: UrlDataService;

  protected _formBuilder!: FormBuilder;

  // refactor
  private _activatedRoute!: ActivatedRoute;

  constructor(filterName: string, defaultFilter: FilterModel = defaultConstFilter) {
    super();
    this._getInstancesFromAngularInjector();
    this._filterName = filterName;
    this.defaultFilter = cloneObject(defaultFilter);

    // Check if filter was setted previously.
    const filter = defaultFilter;
    const urlFilter = this._urlDataService.getDataByQueryParamId(this._filterName) as FilterModel;

    if (urlFilter || filter) {
      this.setCurrentFilter(urlFilter || filter);
    } else {
      this.setCurrentFilter(this.defaultFilter);
    }
  }

  setCurrentFilter(filter: FilterModel) {
    this.currentFilter = cloneObject({ ...filter });
    this.filter.next(this.currentFilter);
  }

  setCurrentFilterData(data: { [key: string]: any }) {
    this.currentFilter.data = data;
    this.filter.next({ ...this.currentFilter });
  }

  private _getInstancesFromAngularInjector() {
    this._activatedRoute = dynamicInjector().get(ActivatedRoute);
    this._urlDataService = dynamicInjector().get(UrlDataService);
    this._formBuilder = dynamicInjector().get(FormBuilder);
  }

  changeFilter(filter: any) {
    this.defaultFilter.size = filter.size;
    const filterDataMerged = { ...this.currentFilter.data, ...filter.data };
    this.currentFilter = cloneObject({ ...this.currentFilter, ...filter });
    this.currentFilter.data = filterDataMerged;
    this.filter.next(this.currentFilter);
    // if (this.updateUrl) {
    //   this.service.updateUrl(this.currentFilter);
    // }
  }

  clearFilter() {
    this.currentFilter = cloneObject({ ...this.defaultFilter });
    // this.urlDataService.saveDataIntoUrlAndNavigate(this.filterName, this.currentFilter);
    this.filter.next(this.defaultFilter);
  }

  setDefaultFilter(filter: FilterModel) {
    this.defaultFilter = cloneObject(filter);
    // if (!this.service.isUrlFilter()) {
    //   this.clearFilter();
    // }
  }

  getCurrentFilter(): FilterModel {
    return { ...this.currentFilter };
  }

  getCurrentFilterData() {
    return this.currentFilter && this.currentFilter.data;
  }

  public getDefaultFilter(): FilterModel {
    return cloneObject(this.defaultFilter);
  }

  filterByQueryParams() {
    // if (this.service.isUrlFilter()) {
    //   this.changeFilter(this.service.getUrlFilter());
    // } else {
    //   //this.clearFilter();
    // }
  }

  /**
   * Don't use it arbitrarily, is only url appearance.
   */
  changeUrlWithoutReload(filter: any) {
    // this.service.updateUrl(filter);
  }

  public setDataFilterProp(key: string, value: any) {
    if (this.currentFilter && this.currentFilter.data) {
      this.currentFilter.data[key] = value;
    }
  }

  // TODO: REVIEW AND TRY TO USE ONLY FORM-SERVICE
  public createFilterform(filterConfig: any) {
    const group = this._formBuilder.group({});
    this.simplifyConfig(filterConfig).forEach((config) => {
      if (Object.keys(config).length) {
        group.addControl(config.controlName, this._formBuilder.control(!isNil(config.defaultValue) ? config.defaultValue : null, config.validators, config.asyncValidators));
      }
    });
    return group;
  }

  public simplifyConfig(bidimensionalArray: any[]) {
    let finalArray: any[] = [];
    bidimensionalArray.forEach((level) => {
      if (!Array.isArray(level)) {
        finalArray.push(level);
      } else {
        finalArray = [...finalArray, ...this.simplifyConfig(level)];
      }
    });
    return finalArray;
  }

  /**
   * Overridable methods
   */
  public filterMap(): any[] {
    return [];
  }
}

export class MobileFilter extends Filter {
  constructor(filterName: string, defaultFilter: FilterModel = defaultConstFilter) {
    super(filterName, defaultFilter);
  }
}

export class WebFilter extends Filter {
  constructor(filterName: string, defaultFilter: FilterModel = defaultConstFilter) {
    super(filterName, defaultFilter);
    this.filter.pipe(takeUntil(this._componentDestroyed)).subscribe(() => {
      this._urlDataService.saveDataIntoUrlAndNavigate(this._filterName, this.currentFilter);
    });
  }
}
