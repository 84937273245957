import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { XPlatform } from '@foxeet/domain';

export type BackConfig = {
  platform: XPlatform;
  baseUrl: BehaviorSubject<string>;
  lang?: BehaviorSubject<string>;
};

export const BACK_CONFIG = new InjectionToken<BackConfig>('Configuración para back, tanto URL como Plataforma');
