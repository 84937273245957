export interface ValidationsCountModel {
  errorCount?: number;
  warningCount?: number;
  infoCount?: number;
}

// @todo FIX

export const hasErrorAlerts = (data: ValidationsCountModel) => !!data.errorCount;

export const hasWarningAlerts = (data: ValidationsCountModel) => !!data.warningCount;

export const hasInfoAlerts = (data: ValidationsCountModel) => !!data.infoCount;

export const existAlerts = (data: ValidationsCountModel): boolean => hasErrorAlerts(data) || hasWarningAlerts(data) || hasInfoAlerts(data);

export const onlyInfoAlert = (data: ValidationsCountModel): boolean => hasInfoAlerts(data) && !hasErrorAlerts(data) && !hasWarningAlerts(data);

export const hasErrorsOrWarnings = (data: ValidationsCountModel) => hasErrorAlerts(data) || hasWarningAlerts(data);
