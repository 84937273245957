import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './domain/utils-chest.config';
import { UtilsReducer } from './redux/utils.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UtilsEffects } from './redux/utils.effects';
import { DataAccessModule } from '@foxeet/data-access';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, UtilsReducer), EffectsModule.forFeature([UtilsEffects]), DataAccessModule],
})
export class UtilsChestModule {}
