import { ImgFlagsAllowed, LanguageSelectorList } from '@foxeet/domain';
import { enLang, esLang, ptLang } from '../assets/i18n';

export const AVAILABLE_LANGUAGES: LanguageSelectorList = [
  {
    cultureCodeLanguage: 'en-GB',
    lang: enLang.languageKey,
    label: 'english',
    img: ImgFlagsAllowed.UK,
  },
  {
    cultureCodeLanguage: 'es-ES',
    lang: esLang.languageKey,
    label: 'spanish',
    img: ImgFlagsAllowed.SPAIN,
  },
  {
    cultureCodeLanguage: 'pt-PT',
    lang: ptLang.languageKey,
    label: 'portuguese',
    img: ImgFlagsAllowed.PORTUGAL,
  },
];
