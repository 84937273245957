export class ArrayUtils {
  /**
   * Recursive search into array.
   * @param prop Prop that contains propValue
   * @param propValue The prop value to match
   * @param elementList ArraList of elements [][] or Object {a: [][], b: []}
   */
  static recursiveSearcher(prop: string, propValue: string, elementList: any[]) {
    const elementObjects = elementList?.filter((element: any) => !Array.isArray(element));
    let elementFound = elementObjects?.find((element: { [x: string]: any }) => element.hasOwnProperty(prop) && element[prop] === propValue);
    const elementArrays = !elementFound && elementList?.filter((element: any) => Array.isArray(element));
    if (!!elementArrays && !!elementArrays.length && !elementFound) {
      for (const o of elementArrays) {
        elementFound = ArrayUtils.recursiveSearcher(prop, propValue, o);
        if (elementFound) break;
      }

      //   for (let i = 0; i < elementArrays.length; i++) {
      //     elementFound = ArrayUtils.recursiveSearcher(prop, propValue, elementArrays[i]);
      //     if (elementFound) break;
      //   }
    }
    return elementFound;
  }

  static allEqual(list: any[]) {
    return list.every((el) => list[0] === el);
  }
}
