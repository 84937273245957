import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { takeUntil } from 'rxjs/operators';
import { DebugModalComponent, DynamicFormModalComponentI } from '../../../general-components';

@Component({
  selector: 'ui-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent extends UnsuscriptionHandler {
  @Input() showVersion = true;
  dynamicFormModel!: DynamicFormModalComponentI;

  version: string;

  counter = 10;

  isShowModalBtnVisible = false;

  environMent!: string;

  isProd = true;

  private _formModalComponentInstance!: MatDialogRef<any>;

  constructor(private _matDialog: MatDialog, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {
    super();
    if (!this._dynamicConfigLoaderService.Options.environment.production) {
      this.isShowModalBtnVisible = true;
      this.environMent = this._dynamicConfigLoaderService.Options.environment.defaultEndpoint;
      this.isProd = this._dynamicConfigLoaderService.Options.environment.defaultEndpoint.includes('api-ivforce');
    }
    this.version = this._dynamicConfigLoaderService?.Options?.environment?.version;
  }

  public counterChange() {
    if (!this.counter--) {
      this.isShowModalBtnVisible = true;
      this.openModal();
    }
  }

  public openModal() {
    this._formModalComponentInstance = this._matDialog.open(DebugModalComponent, { data: this.dynamicFormModel });

    this._formModalComponentInstance
      .afterClosed()
      .pipe(takeUntil(this._componentDestroyed))
      .subscribe(() => {
        this.environMent = this._dynamicConfigLoaderService.Options.environment.defaultEndpoint;
        this.isProd = this._dynamicConfigLoaderService.Options.environment.defaultEndpoint.includes('api-');
      });
  }
}
