import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DetailMenuSection } from '../domain/detail-menu-sections.model';

@Component({
  selector: 'ui-detail-menu-sections',
  templateUrl: './detail-menu-sections.component.html',
  styleUrls: ['./detail-menu-sections.component.scss'],
})
export class DetailMenuSectionsComponent implements OnChanges {
  @Input() sectionList: DetailMenuSection[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sectionList.currentValue) {
      const newList = changes.sectionList.currentValue as DetailMenuSection[];
      if (newList.some((el) => !!el.order)) {
        newList.sort((a, b) => {
          const aOrder = a?.order ?? 0;
          const bOrder = b?.order ?? 0;
          if (aOrder > bOrder) {
            return 1;
          } else if (bOrder > aOrder) {
            return -1;
          }
          return 0;
        });
      }
    } else {
      this.sectionList = [];
    }
  }
}
