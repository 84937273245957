import { Injectable } from '@angular/core';
import { AppraisalFileIndexModel, AppraisalReportAssetFileIndexModel, FileTypes } from '@foxeet/domain';
import { TranslateService } from '@ngx-translate/core';
import { GenericMap } from '../../../components/generic-tables/mappers/generic.map';
import { isNil } from 'lodash';
import { EnumToStringPipe } from '@foxeet/utils-modules';

@Injectable({ providedIn: 'root' })
export class AppraisalDocumentListMap implements GenericMap {
  constructor(private _translateService: TranslateService, private _enumToStringPipe: EnumToStringPipe) {}

  bodyMap = (documents: AppraisalFileIndexModel[] | AppraisalReportAssetFileIndexModel[]) => {
    return !documents
      ? []
      : documents.map((document: AppraisalFileIndexModel | AppraisalReportAssetFileIndexModel) => {
          let vinculationType;
          let vinculation;
          if (!isNil(document.parentRecordId) && !isNil(document.parentVinculationType)) {
            vinculationType = this._enumToStringPipe.transform(document.parentVinculationType, 'AppraisalVinculations');
            vinculation = `${this._translateService.instant(vinculationType)} \n ${document.parentRecordId}`;
          }
          return {
            ...document,
            appraisalDocumentType:
              document.fileType === FileTypes.Pictures
                ? 'UPLOAD_DOCUMENT_visitPhoto'
                : !isNil((document as AppraisalFileIndexModel).appraisalDocumentType)
                ? this._translateService.instant(
                    this._enumToStringPipe.transform((document as AppraisalFileIndexModel).appraisalDocumentType, 'AppraisalImplementationFileDocumentTypes'),
                  )
                : '',
            appraisalReportAssetDocumentType:
              document.fileType === FileTypes.Pictures
                ? 'UPLOAD_DOCUMENT_visitPhoto'
                : !isNil((document as AppraisalReportAssetFileIndexModel).appraisalReportAssetDocumentType)
                ? this._translateService.instant(
                    this._enumToStringPipe.transform((document as AppraisalReportAssetFileIndexModel).appraisalReportAssetDocumentType, 'AppraisalImplementationFileDocumentTypes'),
                  )
                : '',
            sizeInMBString: `${document.sizeInMB} ${this._translateService.instant('APPRAISALS_sizeInMB')}`,
            parentRecordId: vinculation,
            download: 'cloud_download',
            print: document.isPrintable ? 'print' : 'print_disabled',
            delete: 'clear',
          };
        });
  };
}
