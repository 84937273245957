import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setPropertyRegister, setPropertyRegisterAPI } from './utils.actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { propertyRegister } from './utils.selectors';
import { of, Subscription } from 'rxjs';
import { EnumsService } from '@foxeet/data-access';

/**
 * @todo Comentar o modificar los filters para que se lancen cuando hayan distintas traducciones de la info
 */
@Injectable()
export class UtilsEffects implements OnDestroy {
  private nProperties = 0;

  private readonly subs = new Subscription();

  setPropertyRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPropertyRegister),
      filter(() => !this.nProperties),
      mergeMap(() => this.setPropertyRegister()),
    ),
  );

  constructor(private actions$: Actions, private readonly _enumsService: EnumsService, private store: Store) {
    this.subs.add(this.store.select(propertyRegister).subscribe((properties: any) => (this.nProperties = properties.length)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setPropertyRegister() {
    return this._enumsService.getPropertyRegisters().pipe(
      catchError(() => of(null)),
      map((properties) => setPropertyRegisterAPI({ propertyRegister: properties ?? [] })),
    );
  }
}
