import { LocationModel } from '@foxeet/domain';

export const checkIfLocationChange = (previous: LocationModel, current: LocationModel): boolean => {
  const fieldsToCompare = [
    'countryThreeLetterISORegionName',
    'ineCodProv',
    'ineCodMun',
    'province',
    'locality',
    'postalCode',
    'dgcCodVia',
    'ineCodVia',
    'streetName',
    'streetNumber',
  ];
  return !!fieldsToCompare.filter((k) => {
    const prev = (previous as Record<string, any>)[k] ?? null;
    const curr = (current as Record<string, any>)[k] ?? null;
    return prev !== curr;
  }).length;
};
