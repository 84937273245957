<div class="wrapper {{ data?.name }}">
  <div class="header-wrapper">
    <h3 *ngIf="data?.title" class="title" [ngClass]="{ center: data?.alignment === infoAlignment.center }">{{ data?.title | translate }}</h3>
    <button *ngIf="data?.copy" mat-icon-button [matTooltip]="'copy' | translate" [cdkCopyToClipboard]="data?.message" (click)="copyCode()">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <div *ngIf="data?.infoType === infoTypes.simpleMessage && data?.message" class="content">{{ data?.message | translate }}</div>
  <pre
    *ngIf="data?.infoType === infoTypes.innerHTML && data?.message"
    class="content"
    [ngClass]="{ center: data?.alignment === infoAlignment.center }"
    [innerHTML]="data?.message"
  ></pre>
</div>
