import { ConnectionRejectionReason, ConnectionRequestStates, LicenseeUserConnectionRequestErrors } from '../enums';
import { LicenseeModel } from './license.model';
import { UserModel } from './user.model';

// TODO:
export type LicenseeUser = Record<string, unknown>;

export interface LicenseeConnectionRequestModel {
  addedDateTime: any;
  addedUserId: number;
  description: string;
  id: number;
  rejectDescription: string;
  rejectionReason?: ConnectionRejectionReason;
  requestState: ConnectionRequestStates;
}
export interface LicenseeUserConnectionRequest extends LicenseeConnectionRequestModel {
  licenseeId: number;
  licensee: LicenseeModel;
  userId: number;
  user: UserModel;
  licenseeUserId?: number;
  licenseeUse: LicenseeUser;
  isRequestedByUser: boolean;
  addedUser: UserModel;
}

export interface LicenseeRelationshipConnectionRequestModel extends LicenseeConnectionRequestModel {
  requestedLicenseeId: number;
  requesterLicenseeId: number;
  sharingId: string;
}

export interface LicenseeConnectionRequestIndexModel {
  id: number;
  tableFullName: string;
  tableProfileImagePath: string;
  requestState: ConnectionRequestStates;
  description: string;
  rejectDescription: string;
  rejectionReason: any; // TODO: ConnectionRejectionReason
  addedDateTime: string;
  addedUserId: number;
  connectionDateTime: string;
}

export interface LicenseeUserConnectionRequestIndexModel extends LicenseeConnectionRequestIndexModel {
  licenseeId: number;
  userId: number;
  isRequestedByUser: boolean;
  userFullName: string;
  userSharingId: string;
  userProfileImagePath: string;
  licenseeFullName: string;
  licenseeSharingId: string;
  licenseeProfileImagePath: string;
  tableIsLicenseeConnection: boolean;
}

export interface LicenseeRelationshipConnectionRequestIndexModel extends LicenseeConnectionRequestIndexModel {
  requesterLicenseeId: number;
  requestedLicenseeId: number;
  isRequestedByCurrentLicensee: boolean;
  requesterLicenseeProfileImagePath: string;
  requestedLicenseeProfileImagePath: string;
  requestedLicenseeSharingId: string;
  requesterLicenseeSharingId: string;
}

export interface LicenseeUserConnectionRequestModel extends LicenseeConnectionRequestModel {
  isRequestedByUser: boolean;
  licenseeId: number;
  sharingId: string;
  userId: number;
}
export interface LicenseeUserConnectionRequestEditModel extends Partial<LicenseeConnectionRequestModel> {
  licenseeId: number;
  sharingId: string;
  userId: number;
}

export interface LicenseeRelationshipConnectionRequestEditModel extends Partial<LicenseeConnectionRequestModel> {
  requestedLicenseeId?: number;
  sharingId: string;
  requesterLicenseeId: number;
}

export interface LicenseeConnectionRequestFilterModel {
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  id: number;
  ids: number[];
  addedMaxDateTime: string;
  addedMinDateTime: string;
  licenseeId: number;
}

export interface LicenseeUserConnectionRequestFilterModel extends LicenseeConnectionRequestFilterModel {
  userId: number;
  userIds: number[];
  licenseeIds: number[];
  licenseeIdOrs: number[];
  licenseeIdOrUserId_UserId: number;
  licenseeIdOrUserId_LicenseeId: number;
}

export interface LicenseeRelationshipConnectionRequestFilterModel extends LicenseeConnectionRequestFilterModel {
  requestState: number;
  requestStates: number[];
  nameContains: string;
}

export interface LicenseeUserConnectionRequestCreatedModel extends LicenseeConnectionRequestModel {
  isRequestedByUser: boolean;
  licenseeId: number;
  sharingId: string;
  userId: number;
  error: LicenseeUserConnectionRequestErrors;
}
export interface CheckCancelModel {
  message: string;
  isCancelledByUser: boolean;
  hasPendingRecords: boolean;
  appraisalIds: number[];
}

export interface LicenseeUserConnectionRequestItemModel {
  id: number;
  name: string;
  description: string;
  profileImagePath: string;
  isRequestedByUser: boolean;
}

export interface LicenseeRelationshipConnectionRequestItemModel {
  id: number;
  name: string;
  description: string;
  profileImagePath: string;
}

// FRONT Model
export interface LicenseeUserConnectionRequestTable extends LicenseeUserConnectionRequestIndexModel {
  item: LicenseeUserConnectionRequestIndexModel;
  isRequestedByMe?: boolean;
  name?: string;
  profileImagePath?: string;
  image?: string;
  titleAndSubtitleConfig?: any;
  connectionType?: string;
  actionsConfig?: any;
  clickable?: any;
  clickEvent?: any;
}

export interface LicenseeRelationshipConnectionRequestTable extends LicenseeRelationshipConnectionRequestIndexModel {
  item: LicenseeRelationshipConnectionRequestIndexModel;
  isRequestedByMe?: boolean;
  name?: string;
  profileImagePath?: string;
  image?: string;
  titleAndSubtitleConfig?: any;
  connectionType?: string;
  actionsConfig?: any;
  clickable?: any;
  clickEvent?: any;
}
