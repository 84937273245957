import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AuthoritiesMiddlewareService } from './authorities.service';
import { AccountService, EnumsService } from '@foxeet/data-access';

@Injectable({ providedIn: 'root' })
export class AccountCanLoad implements CanLoad {
  constructor(
    private _authService: AuthService,
    private _accountService: AccountService,
    private _enumsService: EnumsService,
    private _dynamicConfigLoaderService: DynamicConfigLoaderService,
    private _authoritiesMiddlewareService: AuthoritiesMiddlewareService,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const isLocalhost = location?.hostname === 'localhost';
    const authoritiesOrigin = isLocalhost ? of({ body: this._dynamicConfigLoaderService.Options.authorities }) : this._enumsService.getAuthorities();

    return forkJoin({
      authoritiesRes: authoritiesOrigin,
      licensesRes: this._accountService.getLicenses(),
    }).pipe(
      take(1),
      catchError((err) => {
        this._authService.logOut();
        throw err;
      }),
      map(({ authoritiesRes, licensesRes }): boolean => {
        this._authoritiesMiddlewareService.setAuthoritiesObject(authoritiesRes?.body);
        this._authService.setLicenseList(licensesRes);
        return true;
      }),
    );
  }
}
