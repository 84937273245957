import { AssetTypologies, AssetUses, OfferTypes, ValidationTypes } from '../enums/backend.enums';
import { AssetSurfaceSelectorModel } from './asset-surface-selector.model';

export interface ValuationExtraDataModel {
  use: AssetUses;
  typology?: AssetTypologies;
  longitude?: number;
  latitude?: number; // (o se pueden agrupar en coordinates es lo mismo)
  validationType?: ValidationTypes;
  legalMaximumMethodParentData?: ValorationLegalMaximumMethodParentAssetModel;
  offerType?: OfferTypes;
  hasCurrentCostMethod?: boolean; // M. Coste cuando es futuro.
  appraisalWithEco8052003?: boolean;
  isFutureValoration?: boolean;
}

export interface ValuationWarningModel {
  title: string;
  message: string;
}

export interface ValuationInitialValuesModel<T> {
  surfaces: AssetSurfaceSelectorModel; // (Quitando errors)
  data: T; // (depende de valorationMethod devolverá el modelo adecuado relleno con los datos iniciales)
  errors: string[]; // tipo de error ?
  warning?: ValuationWarningModel; // --> si este campo es distinto de null y no tengo errores muestro modal advertencia
  extraData?: ValuationExtraDataModel;
}

export interface ValorationLegalMaximumMethodParentAssetModel {
  isParent: boolean;
  parentHasLegalMaximumMethod: boolean;
  parentValorationLegalMaximumValue?: number;
  parentValorationLegalMaximumUnitValue?: number;
}
