<div class="custom-container">
  <ng-container *ngFor="let section of sectionList">
    <a
      *ngIf="(section.isVisible !== undefined ? (section.isVisible | async) : true) && section.isAccordion !== true"
      [id]="'AT_' + section.id"
      class="section"
      [routerLink]="[section.link]"
      [routerLinkActive]="['link-active']"
    >
      <mat-icon
        *ngIf="section.icon as leftIcon"
        class="icon hide-text"
        [ngClass]="{ success: leftIcon?.color === 'success', danger: leftIcon?.color === 'danger', primary: leftIcon?.color === 'primary', disabled: (section.isDisabled | async) }"
        [matTooltip]="leftIcon.tooltip || '' | translate"
        >{{ leftIcon?.name }}
        <span *ngIf="leftIcon.badge" class="badge"></span>
      </mat-icon>

      <ng-container *ngIf="section?.label">
        <span *uiPlanBadge="{ authorityRoot: section?.authorityRoute, label: 'Comfort/Premium' }" [ngClass]="{ disabled: (section.isDisabled | async) }">{{
          section.label | translate
        }}</span>
      </ng-container>

      <div class="d-flex flex-grow-1 flex-row-reverse" *ngIf="section.rightIcon as rightIcon">
        <mat-icon
          class="icon hide-text icon-right"
          [ngClass]="{
            success: rightIcon?.color === 'success',
            danger: rightIcon?.color === 'danger',
            primary: rightIcon?.color === 'primary',
            disabled: (section.isDisabled | async)
          }"
          [matTooltip]="rightIcon.tooltip || '' | translate"
          >{{ rightIcon?.name }}<span class="badge" *ngIf="rightIcon.badge"></span
        ></mat-icon>
      </div>
    </a>

    <a class="accordion-section" [id]="'AT_' + section.id" *ngIf="(section?.isVisible !== undefined ? (section?.isVisible | async) : true) && section?.isAccordion">
      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z" #headerExpansionPanel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="label" *ngIf="section?.label">{{ section.label | translate }}</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-content">
            <div *ngIf="section?.accordionContent | async as accordionContent">
              <p *ngFor="let content of accordionContent; let idx = index" [class.bold]="idx === 0">{{ content | translate | capitalize }}</p>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </a>

    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let item of section?.accordionItems" matMenuContent>
        <button [id]="'AT_' + item.id" *ngIf="item?.isVisible !== undefined ? (item?.isVisible | async) : true" mat-menu-item (click)="item?.callback()">
          <span>{{ item.label | translate }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
</div>
