import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { CacheService } from './cache.service';
import { ApiBaseService2 } from './api-base.service';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { Observable } from 'rxjs';

export abstract class WorkflowService extends ApiBaseService2 {
  protected constructor(http: HttpClient, apiBranch: string, cache?: CacheService) {
    super(http, apiBranch, cache);
  }

  listAllByFilter<T>(body: any, params: HttpParams = new HttpParams()): Observable<T> {
    body = removeNullsAndUndefined(body);
    return this.http.post<T>(`${this.apiBranch}/ListAll`, body, { params });
  }

  listPageByFilter<T>(page: string | number, pagesize: string | number, body: { [prop: string]: unknown }) {
    body = removeNullsAndUndefined(body);
    return this.http.post<T>(`${this.apiBranch}/ListPaged`, body, { params: this.setParams({ page, pagesize }) });
  }

  listPageByFilterExternal<T>(page: string | number, pagesize: string | number, body: { [prop: string]: unknown }) {
    body = removeNullsAndUndefined(body);
    return this.http.post<T>(`${this.apiBranch}/ListPaged`, body, { params: this.setParams({ page, pagesize }) });
  }

  getExcelSummary(body: { [prop: string]: unknown }) {
    // let body = filter.getCurrentFilter().data;
    body = removeNullsAndUndefined(body);
    return this.http.post(`${this.setUrl()}/DownloadExcelSummary`, body, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  putImageProfileById = (
    id: number,
    image: Blob,
    body: { Id: number; ProfileImageFile: string; ProfileImageWidth: number; ProfileImageHeight: number; FileName: string },
    params: HttpParams = new HttpParams(),
  ): Observable<HttpEvent<string>> => {
    const formData = new FormData();
    // TODO: Review Id.
    formData.append('Id', `${id}`);
    if (body?.FileName) {
      formData.append('ProfileImageFile', image, body.FileName);
    } else {
      formData.append('ProfileImageFile', image);
    }
    // TODO: Review width.
    formData.append('ProfileImageWidth', `${(body && body.ProfileImageWidth) || 150}`);
    formData.append('ProfileImageHeight', `${(body && body.ProfileImageHeight) || 150}`);

    return this.http.put(`${this.apiBranch}/${id}/ImageProfile`, formData, {
      observe: 'events',
      responseType: 'text',
      params,
    });
  };
}
