import {
  AssetConditionalsWarningsObservationsEditModel,
  AssetConditionalsWarningsObservationsItemModel,
  ConditionalSectionTypes,
  CondWarnObserTypes,
  ItemModel,
  ObservationSectionTypes,
  WarningSectionTypes,
} from '@foxeet/domain';

export const mapItemsToBack = <T>(sectionList: ItemModel<T>[], condWarnObserType: CondWarnObserTypes): AssetConditionalsWarningsObservationsEditModel[] => {
  return sectionList
    ? sectionList.reduce(
        (prev: AssetConditionalsWarningsObservationsEditModel[], curr) => [
          ...prev,
          ...curr.itemList.map((subel): AssetConditionalsWarningsObservationsEditModel => {
            const mappedSubel: AssetConditionalsWarningsObservationsEditModel = {
              id: subel.id,
              description: subel.description,
              condWarnObserType: condWarnObserType, // 0 si es condicionante, 1 si es advertencia y 2 si es observacion
              conditionalType: condWarnObserType === CondWarnObserTypes.Conditional ? subel.type : null,
              warningType: condWarnObserType === CondWarnObserTypes.Warning ? subel.type : null,
              observationType: condWarnObserType === CondWarnObserTypes.Observation ? subel.type : null,
            };
            return mappedSubel;
          }),
        ],
        [],
      )
    : [];
};

export const sectionListMapper = (sectionList: AssetConditionalsWarningsObservationsItemModel) => {
  return [
    ...mapItemsToBack<ConditionalSectionTypes>(sectionList.conditions, CondWarnObserTypes.Conditional),
    ...mapItemsToBack<WarningSectionTypes>(sectionList.warnings, CondWarnObserTypes.Warning),
    ...mapItemsToBack<ObservationSectionTypes>(sectionList.observations, CondWarnObserTypes.Observation),
  ];
};
