import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class QualityControlDocumentsService extends WorkflowService {
  constructor(http: HttpClient, cache?: CacheService) {
    super(http, 'ValidationAppraisalDocuments', cache);
  }

  // TODO: change
  removeAppraisalDocument(fileId: number) {
    return this.delete(`${fileId}`);
  }
}
