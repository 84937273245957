<div *ngIf="hideIfDatasourceIsEmpty && !dataSource?.data?.length" class="no-files">{{ 'APPRAISALS_thereAreNoItems' | translate }}</div>

<div class="generic-table" [hidden]="hideIfDatasourceIsEmpty && !dataSource?.data?.length">
  <div class="generic-table__header" *ngIf="title">
    <h3 class="generic-table__title">
      {{ title | translate }}
    </h3>
    <span>
      <ng-content select="[headerButtons]"></ng-content>
    </span>
  </div>

  <div utilsResponsiveTable style="position: relative">
    <table
      mat-table
      matSort
      id="{{ tableId }}"
      [dataSource]="dataSource"
      [matSortActive]="filter.getCurrentFilterData().orderFieldName"
      [matSortDirection]="filter.getCurrentFilterData().orderFieldAsc ? 'asc' : 'desc'"
      matSortDisableClear
      *ngIf="tableConfig"
    >
      <ng-container *ngFor="let column of tableConfig?.columns; let i = index" [matColumnDef]="column?.columnId">
        <ng-container [ngSwitch]="column?.cellType">
          <ng-container *ngSwitchCase="TableCellType.ENTRY_COMPONENT">
            <mat-header-cell class="{{ column?.alignment }}" *matHeaderCellDef>{{ column?.name | translate : column?.internationalizationType | capitalize }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">
              <ng-container
                utilsDinamicallyComponent
                [entryComponent]="column?.entryComponent"
                [data]="row"
                [contentType]="column?.entryComponentContentTypes"
                [header]="column"
              ></ng-container>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.PIPE">
            <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">{{
              column?.name | translate : column?.internationalizationType | capitalize
            }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">{{
              row[column?.columnId] | dynamicPipe : column?.pipe : column?.keysPipe : setDynamicRowValues(column, row) | translate : column?.internationalizationType
            }}</mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{
                tableConfig?.footer[column?.columnId] | dynamicPipe : column?.pipe : column?.keysPipe | translate : column?.internationalizationType
              }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.LABEL">
            <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">{{
              column?.name | translate : column?.internationalizationType | capitalize
            }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">{{ row[column?.columnId] }}</mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.BOOLEAN">
            <mat-header-cell class="{{ column?.alignment }}" *matHeaderCellDef>{{ column?.name | translate : column?.internationalizationType | capitalize }}</mat-header-cell>
            <mat-cell class="{{ column?.alignment }}" *matCellDef="let row">
              <!-- padding: 0.35em 1em; -->
              <fa-icon *ngIf="row[column?.columnId]" class="success" [icon]="faCheck" size="md"></fa-icon>
              <fa-icon *ngIf="!row[column?.columnId]" class="error" [icon]="faTimes" size="md"></fa-icon>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.MAT_ICON">
            <mat-header-cell class="{{ column?.alignment }}" *matHeaderCellDef>{{ column?.name | translate : column?.internationalizationType | capitalize }}</mat-header-cell>
            <mat-cell class="{{ column?.alignment }}" *matCellDef="let row">
              <mat-icon>{{ row[column?.columnId] }}</mat-icon>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <!-- Checkbox Column -->
          <ng-container *ngSwitchCase="TableCellType.CHECKBOX" matColumnDef="checkbox">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="{{ column?.alignment }}">
              <mat-checkbox
                *ngIf="showSelectAll"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>

            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row?.id) : null"
                [checked]="selection.isSelected(row?.id)"
                [disabled]="row?.disabled"
              >
              </mat-checkbox>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <!-- Action Button Column -->
          <ng-container *ngSwitchCase="TableCellType.ACTION_BUTTON">
            <mat-header-cell class="{{ column?.alignment }}" *matHeaderCellDef style="max-width: 5rem">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>

            <mat-cell class="{{ column?.alignment }}" *matCellDef="let row; let j = index" style="max-width: 5rem">
              <a
                matSuffix
                mat-icon-button
                (click)="column?.callback($event, row)"
                *ngIf="!column?.hideField || !row[column?.hideField]"
                [id]="'AT_' + (column?.id ?? 'action') + '_' + j"
              >
                <mat-icon [ngClass]="{ danger: column?.color === '#851827' }" [ngStyle]="{ color: column?.color }">{{ column?.icon || row[column?.columnId] }}</mat-icon>
              </a>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <!-- Action Button Column -->
          <ng-container *ngSwitchCase="TableCellType.ACTION_BUTTON_SPAN">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>

            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">
              <button mat-stroked-button (click)="column?.callback($event, row)">
                {{ row[column?.columnId] }}
              </button>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <!-- Options Menu -->
          <ng-container *ngSwitchCase="TableCellType.OPTIONS_MENU">
            <mat-header-cell class="{{ column?.alignment }}" *matHeaderCellDef style="max-width: 5rem">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>
            <mat-cell class="{{ column?.alignment }}" *matCellDef="let row" style="max-width: 5rem" (click)="$event.stopPropagation()">
              <button
                *ngIf="!row?.hideMenuOptions"
                id="{{ tableId }}_options_{{ i }}"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{
                  item: { column: this.column, row: this.row }
                }"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.CHIPS_LIST">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">
              <mat-chip *ngFor="let chip of row[column?.columnId]">{{ chip | translate }} </mat-chip>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.SPAN_TEXT_TRUNCATED">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="{{ column?.alignment }}">
              <span
                class="table-span-truncated-cell"
                [matTooltip]="row[column?.columnId]"
                [matTooltipPosition]="column?.truncatedTooltipPosition"
                matTooltipHideDelay="column?.truncatedTooltipDelay"
                >{{ row[column?.columnId] }}</span
              >
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchCase="TableCellType.LINK">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
            </mat-header-cell>

            <mat-cell *matCellDef="let row" class="{{ column?.alignment }} link-cell-container">
              <a
                *ngIf="row?.clickable && row?.link"
                [routerLink]="row?.link"
                [queryParams]="row?.queryParams"
                class="tag-full-row"
                [matTooltip]="'LINK_tooltip' | translate"
                [matTooltipPosition]="'right'"
              >
                <span>{{ row[column?.columnId] }}</span>
                <mat-icon class="ml-2" name="link">link</mat-icon>
              </a>
              <span *ngIf="!row?.clickable || !row?.link">{{ row[column?.columnId] }}</span>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sortable" class="{{ column?.alignment }}">
              {{ column?.name | translate : column?.internationalizationType | capitalize }}
              <mat-icon *ngIf="column?.tooltip" class="success" [matTooltip]="column?.tooltip.label | translate" matTooltipPosition="above">info</mat-icon>
            </mat-header-cell>

            <mat-cell *matCellDef="let row" class="{{ column?.alignment }} link-cell-container">
              <span>{{ row[column?.columnId] }}</span>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="{{ column?.alignment }}">
              <ng-container *ngIf="tableConfig?.footer && tableConfig?.footer[column?.columnId] !== undefined">{{ tableConfig?.footer[column?.columnId] }}</ng-container>
            </mat-footer-cell>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column -->
      <ng-container [matColumnDef]="'expandedDetail'">
        <mat-cell *matCellDef="let detail">
          <ng-container *ngIf="!tableConfig?.expandableRowComponent"> No component defined {{ detail?.element | json }} </ng-container>
          <ng-container *ngIf="tableConfig?.expandableRowComponent">
            <ng-container utilsDinamicallyComponent [entryComponent]="tableConfig?.expandableRowComponent" [data]="detail?.element" [header]="'QUE_ES_ESTO'"></ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tableConfig?.displayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: tableConfig?.displayedColumns; when: !isExpansionDetailRow"
        [ngClass]="{ clickableRow: row?.clickable, disabledRow: row?.disabled }"
        (click)="row?.clickable && onRowClick(row)"
        [class.expanded]="isExpanded(row)"
      >
      </mat-row>

      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
        [@detailExpand]="isExpanded(row?.element) ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
      >
      </mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell">{{ noContentLabel | translate }}</td>
      </tr>

      <ng-container *ngIf="tableConfig?.footer">
        <mat-footer-row *matFooterRowDef="tableConfig?.displayedColumns"></mat-footer-row>
      </ng-container>
    </table>

    <mat-paginator
      *ngIf="isPaginated"
      [length]="dataSource.totalCount"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="filter.getCurrentFilter().size"
      [pageIndex]="filter.getCurrentFilter().page - 1"
      showFirstLastButtons
    ></mat-paginator>

    <div *ngIf="dataSource.loadingSubject | async" class="spinner-container">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let option of item.column?.options">
      <button mat-menu-item (click)="clickedSelectedOption(option, item.row, item.index)" *ngIf="!item.row[option.hideField] && !option.isHidden">
        <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
        <span>{{ option.label | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
