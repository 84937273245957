import { AssetUses, CountryConfigurationEnumModel, CultureAndLanguage, CurrencyEnumModel } from '@foxeet/domain';
import { createAction, props } from '@ngrx/store';
import { FEATURE_NAME } from '../domain/chests-culture.config';

const featureTITLE = `[${FEATURE_NAME}] `;

export const setCulture = createAction(featureTITLE + 'setCulture', props<{ culture: string }>());
export const setLanguage = createAction(featureTITLE + 'setLanguage', props<{ language: string }>());
export const setLanguageAndCulture = createAction(featureTITLE + 'setLanguageAndCulture', props<Partial<CultureAndLanguage>>());

export const setCountryConfigByIso = createAction(featureTITLE + 'setCountryConfigByIso', props<{ countryISO: string }>());
export const setMeassurementCultureByAsset = createAction(featureTITLE + 'setMeassurementCultureByAsset', props<{ use: AssetUses }>());

export const getCountryConfigurations = createAction(featureTITLE + 'getCountryConfigurations');
export const setCountryConfigurations = createAction(featureTITLE + 'setCountryConfigurations', props<{ configs: CountryConfigurationEnumModel[] | null }>());

export const getCurrencies = createAction(featureTITLE + 'getCurrencies');
export const setCurrencies = createAction(featureTITLE + 'setCurrencies', props<{ configs: CurrencyEnumModel[] | null }>());

export const setReportCurrency = createAction(featureTITLE + 'setReportCurrency', props<{ culture: string }>());
