import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CultureSelectors } from '@foxeet/chests/culture';
import { CurrencyEnumModel, EnumsModel } from '@foxeet/domain';
import { ASYNC_BUTTON_STATE } from '../../../async-button';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { DebugModalComponent } from '../debug-modal/debug-modal.component';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';

@Component({
  selector: 'ui-internationalization-modal',
  templateUrl: './internationalization-modal.component.html',
  styleUrls: ['./internationalization-modal.component.scss'],
})
export class InternationalizationModalComponent extends UnsuscriptionHandler {
  public formGroup: FormGroup;

  public stateButton: BehaviorSubject<ASYNC_BUTTON_STATE> = new BehaviorSubject<ASYNC_BUTTON_STATE>(ASYNC_BUTTON_STATE.IDLE);

  public currencies: CurrencyEnumModel[] = [];
  public languages: EnumsModel[] = [];

  public showWarning = false;

  constructor(
    public dialogRef: MatDialogRef<DebugModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { languages: EnumsModel[]; currencies: CurrencyEnumModel[]; form: FormGroup },
    private readonly store: Store,
    private _dCLS: DynamicConfigLoaderService,
  ) {
    super();

    console.log(this._dCLS.Options.languagesConfig?.availableLanguages);
    this.languages =
      data.languages &&
      data.languages.map((el) => {
        const av = this._dCLS.Options.languagesConfig?.availableLanguages.find((l) => l.lang === el.value);
        return {
          ...el,
          cultureCodeLanguage: av.cultureCodeLanguage,
          img: av.img,
        };
      });
    this.currencies = data.currencies;

    this.formGroup = new FormGroup({
      cultureCodeCurrency: new FormControl(data.form.get('cultureCodeCurrency')?.value),
      cultureCodeLanguage: new FormControl(data.form.get('cultureCodeLanguage')?.value),
    });

    this.formGroup.get('cultureCodeCurrency')?.valueChanges.subscribe((value) => {
      if (value) {
        this.showWarning = true;
      }
    });
  }

  closeEventTrigger() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.formGroup.getRawValue());
  }

  selectCurrency(currency: CurrencyEnumModel) {
    this.formGroup.get('cultureCodeCurrency')?.setValue(currency.cultureCodeCurrency);
  }

  selectLanguage(lang: any) {
    this.formGroup.get('cultureCodeLanguage')?.setValue(lang.cultureCodeLanguage);
  }
}
