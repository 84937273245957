import { NgModule } from '@angular/core';
import { FormDropdownModule } from './form-dropdown/web/form-dropdown.module';
import { FormValidationMessagesModule } from './form-validation-messages/web/form-validation-messages.module';
import { FormInputCalendarModule } from './form-input-calendar/web/form-input-calendar.module';
import { FormInputCheckboxModule } from './form-input-checkbox/web/form-input-checkbox.module';
import { FormInputChipsModule } from './form-input-chips/web/form-input-chips.module';
import { FormInputMaskModule } from './form-input-mask/web/form-input-mask.module';
import { FormInputMultiselectModule } from './form-input-multiselect/web/form-input-multiselect.module';
import { FormInputNumberModule } from './form-input-number/web/form-input-number.module';
import { FormInputPasswordModule } from './form-input-password/web/form-input-password.module';
import { FormInputSwitchModule } from './form-input-switch/web/form-input-switch.module';
import { FormInputTextModule } from './form-input-text/web/form-input-text.module';
import { FormInputTextAreaModule } from './form-input-text-area/web/form-input-textarea.module';
import { FormInputTimeModule } from './form-input-time/web/form-input-time.module';
import { FormInputTripleSwitchModule } from './form-input-triple-switch/web/form-input-triple-switch.module';

const Modules = [
  FormDropdownModule,
  FormValidationMessagesModule,
  FormInputCalendarModule,
  FormInputCheckboxModule,
  FormInputChipsModule,
  FormInputMaskModule,
  FormInputMultiselectModule,
  FormInputNumberModule,
  FormInputPasswordModule,
  FormInputSwitchModule,
  FormInputTextModule,
  FormInputTextAreaModule,
  FormInputTimeModule,
  FormInputTripleSwitchModule,
];

@NgModule({
  imports: Modules,
  exports: Modules,
})
export class FormInputsModule {}
