import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import { BillingDataEditModel, CustomerModel } from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class CustomerService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Customers', cache);
  }

  customerUsersListPagedByCustomerId<T>(customerId: CustomerModel['id'], page: number, size: number, body: any): Observable<HttpResponse<T>> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    return this.http.post<T>(`${this.apiBranch}/${customerId}/Users/ListPaged`, body, { observe: 'response', params });
  }

  isCreated(nationalDocumentNumber: string): Observable<boolean> {
    const params = new HttpParams().set('nif', `${nationalDocumentNumber}`);
    return this.get<boolean>('IsCreated', { params });
  }

  getBillingData(id: string): Observable<BillingDataEditModel> {
    return this.get<BillingDataEditModel>(`${id}/BillingData`);
  }
}
