import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from '../../../base-components';

@Component({
  selector: 'ui-form-input-switch',
  templateUrl: 'form-input-switch.component.html',
  styleUrls: ['form-input-switch.component.scss'],
})
export class FormInputSwitchComponent extends BaseFormComponent<boolean> implements OnInit {
  @Output() changes = new EventEmitter();
}
