import { createReducer, on } from '@ngrx/store';
import { initialStateType, MarketStudyState } from './market-study.state';
import { setMarketStudyApi } from './market-study.actions';

export const MarketStudyReducer = createReducer<MarketStudyState>(
  initialStateType,
  on(setMarketStudyApi, (state, action) => {
    const newstate = {
      marketStudies: [...action.marketStudies],
    };

    return newstate;
  }),
);
