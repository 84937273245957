import { PATHS, SUB_PATHS } from '@foxeet/n-core';
import { BehaviorSubject } from 'rxjs';

export enum SideMenuItemType {
  SECTION,
  ITEM,
}

export interface MenuSubItem {
  id: string;
  type: SideMenuItemType;
  label: string;
  isVisible: BehaviorSubject<boolean>;
  link: string;
  icon: string;
  parentId: string;
  authoritiesRoute?: string;
}

export interface MenuItem {
  id: string;
  label: string;
  type: SideMenuItemType;
  isVisible: BehaviorSubject<boolean>;
  link?: string;
  icon?: string;
  subMenuId?: string;
  parentId?: string;
  subMenu?: MenuItem[];
  subItems?: MenuSubItem[];
  authoritiesRoute?: string;
  disabled?: boolean;
  showChip?: boolean;
  chipLabel?: string;
}

export interface SideMenuConfig {
  [prop: string]: MenuItem[];
}

// TODO: pass object with role permissions ?
export const mobileMenuConfig: MenuItem[] = [
  {
    id: 'order_requests',
    label: 'requests',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'move_to_inbox',
    link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}`,
    subMenuId: 'requests',
    parentId: 'orders',
    authoritiesRoute: 'orders.requests',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'orders',
        authoritiesRoute: 'orders.requests.pages.list',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'orders',
        authoritiesRoute: 'orders.requests.pages.add',
      },
    ],
  },
  {
    id: 'order_appraisals',
    label: 'valuations',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'assignment',
    link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}`,
    subMenuId: 'appraisals',
    parentId: 'orders',
    authoritiesRoute: 'orders.appraisals',
    subItems: [
      {
        id: 'dashboard',
        type: SideMenuItemType.ITEM,
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${PATHS.DASHBOARD}`,
        label: 'dashboard',
        isVisible: new BehaviorSubject<boolean>(true),
        icon: 'dashboard',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.dashboard',
      },
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.LIST}`,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        icon: 'list',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.list',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.add',
      },
      {
        id: 'massive',
        type: SideMenuItemType.ITEM,
        label: 'massive',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.MASSIVE}`,
        icon: 'cloud_upload',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.massive',
      },
      {
        id: 'portfolios',
        type: SideMenuItemType.ITEM,
        label: 'MENU_ASSESSMENTS_portfolios',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.PORTFOLIOS}`,
        icon: 'card_travel',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.portfolios',
      },
      {
        id: 'incidents',
        type: SideMenuItemType.ITEM,
        label: 'incidents',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.INCIDENTS}`,
        icon: 'warning',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.incidents',
      },
      {
        id: 'claims',
        type: SideMenuItemType.ITEM,
        label: 'claims',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.CLAIMS}`,
        icon: 'feedback',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals.pages.claims',
      },
    ],
  },
  {
    id: 'order_quality_control',
    label: 'quality-control',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'verified_user',
    link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}`,
    subMenuId: 'quality_control',
    parentId: 'orders',
    authoritiesRoute: 'orders.quality-control',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.LIST}`,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        icon: 'list',
        parentId: 'orders',
        authoritiesRoute: 'orders.quality-control.pages.list',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.ADD}`,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        icon: 'add_circle_outline',
        parentId: 'orders',
        authoritiesRoute: 'orders.quality-control.pages.add',
      },
      {
        id: 'massive',
        type: SideMenuItemType.ITEM,
        label: 'massive',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.MASSIVE}`,
        icon: 'cloud_upload',
        parentId: 'orders',
        authoritiesRoute: 'orders.quality-control.pages.massive',
      },
    ],
  },
  {
    id: 'order_simple_notes',
    label: 'simple-notes',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'description',
    link: `/${PATHS.ORDERS}/${PATHS.SIMPLE_NOTES}`,
    subMenuId: 'simple_notes',
    parentId: 'orders',
    authoritiesRoute: 'orders.simple-notes',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.SIMPLE_NOTES}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'orders',
        authoritiesRoute: 'orders.simple-notes.pages.list',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.SIMPLE_NOTES}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'orders',
        authoritiesRoute: 'orders.simple-notes.pages.list',
      },
    ],
  },
  {
    id: 'entities',
    label: 'entitiesLabel',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.ITEM,
    icon: 'perm_contact_calendar',
    link: `/${PATHS.ENTITIES}`,
    authoritiesRoute: 'entities',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ENTITIES}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'entities',
        authoritiesRoute: 'entities.pages.list',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ENTITIES}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'entities',
        authoritiesRoute: 'entities.pages.add',
      },
    ],
  },
];

export const topMenuConfig: MenuItem[] = [
  {
    id: 'requests',
    label: 'MENU_ORDERS_requests',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.SECTION,
    link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}`,
    subMenuId: 'requests',
    authoritiesRoute: 'orders.requests',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'orders',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ORDERS}/${PATHS.REQUESTS}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'orders',
      },
    ],
  },
  {
    id: 'orders',
    label: 'orders',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.SECTION,
    authoritiesRoute: 'orders',
    subMenu: [
      {
        id: 'order_appraisals',
        label: 'valuations',
        isVisible: new BehaviorSubject<boolean>(true),
        type: SideMenuItemType.ITEM,
        icon: 'assignment',
        link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}`,
        subMenuId: 'appraisals',
        parentId: 'orders',
        authoritiesRoute: 'orders.appraisals',
        subItems: [
          {
            id: 'dashboard',
            type: SideMenuItemType.ITEM,
            link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${PATHS.DASHBOARD}`,
            label: 'dashboard',
            isVisible: new BehaviorSubject<boolean>(true),
            icon: 'dashboard',
            parentId: 'orders',
          },
          {
            id: 'list',
            type: SideMenuItemType.ITEM,
            link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.LIST}`,
            label: 'list',
            isVisible: new BehaviorSubject<boolean>(true),
            icon: 'list',
            parentId: 'orders',
          },
          {
            id: 'add',
            type: SideMenuItemType.ITEM,
            label: 'add',
            isVisible: new BehaviorSubject<boolean>(true),
            link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.ADD}`,
            icon: 'add_circle_outline',
            parentId: 'orders',
          },
          {
            id: 'incidents',
            type: SideMenuItemType.ITEM,
            label: 'incidents',
            isVisible: new BehaviorSubject<boolean>(true),
            link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.INCIDENTS}`,
            icon: 'warning',
            parentId: 'orders',
          },
          {
            id: 'claims',
            type: SideMenuItemType.ITEM,
            label: 'claims',
            isVisible: new BehaviorSubject<boolean>(true),
            link: `/${PATHS.ORDERS}/${PATHS.APPRAISALS}/${SUB_PATHS.CLAIMS}`,
            icon: 'feedback',
            parentId: 'orders',
          },
        ],
      },
      {
        id: 'order_quality_control',
        label: 'quality-control',
        isVisible: new BehaviorSubject<boolean>(true),
        type: SideMenuItemType.ITEM,
        icon: 'verified_user',
        link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}`,
        subMenuId: 'quality_control',
        parentId: 'orders',
        authoritiesRoute: 'orders.quality-control',
        subItems: [
          {
            id: 'list',
            type: SideMenuItemType.ITEM,
            link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.LIST}`,
            label: 'list',
            isVisible: new BehaviorSubject<boolean>(true),
            icon: 'list',
            parentId: 'orders',
          },
          {
            id: 'add',
            type: SideMenuItemType.ITEM,
            link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.ADD}`,
            label: 'add',
            isVisible: new BehaviorSubject<boolean>(true),
            icon: 'add_circle_outline',
            parentId: 'orders',
          },
          {
            id: 'claims',
            type: SideMenuItemType.ITEM,
            link: `/${PATHS.ORDERS}/${PATHS.QUALITY_CONTROL}/${SUB_PATHS.CLAIMS}`,
            label: 'claims',
            isVisible: new BehaviorSubject<boolean>(true),
            icon: 'feedback',
            parentId: 'orders',
          },
        ],
      },
      {
        id: 'other_orders',
        label: 'other-works',
        isVisible: new BehaviorSubject<boolean>(true),
        type: SideMenuItemType.ITEM,
        icon: 'description',
        link: `/${PATHS.ORDERS}/${PATHS.MANAGEMENT_ORDERS}`,
        subMenuId: 'other_orders',
        parentId: 'orders',
        authoritiesRoute: 'orders.management-orders', // Cambiar por el nuevo enrutado con authorities
        subItems: [
          {
            id: 'list',
            type: SideMenuItemType.ITEM,
            label: 'list',
            isVisible: new BehaviorSubject<boolean>(true),
            link: `/${PATHS.ORDERS}/${PATHS.MANAGEMENT_ORDERS}/${SUB_PATHS.LIST}`,
            icon: 'list',
            parentId: 'orders',
          },
          {
            id: 'add',
            type: SideMenuItemType.ITEM,
            label: 'add',
            isVisible: new BehaviorSubject<boolean>(true),
            link: `/${PATHS.ORDERS}/${PATHS.MANAGEMENT_ORDERS}/${SUB_PATHS.ADD}`,
            icon: 'add_circle_outline',
            parentId: 'orders',
          },
        ],
      },
      {
        id: 'avm',
        label: 'avm',
        isVisible: new BehaviorSubject<boolean>(true),
        type: SideMenuItemType.ITEM,
        icon: 'description',
        link: ``,
        subMenuId: 'avm',
        parentId: 'orders',
        authoritiesRoute: 'orders.simple-notes', // Cambiar por el nuevo enrutado con authorities
        disabled: true,
        showChip: true,
        chipLabel: 'soon',
      },
    ],
  },
  {
    id: 'entities',
    label: 'entitiesLabel',
    isVisible: new BehaviorSubject<boolean>(true),
    type: SideMenuItemType.SECTION,
    link: `${PATHS.ENTITIES}`,
    subMenuId: 'entities',
    authoritiesRoute: 'entities',
    subItems: [
      {
        id: 'list',
        type: SideMenuItemType.ITEM,
        label: 'list',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ENTITIES}/${SUB_PATHS.LIST}`,
        icon: 'list',
        parentId: 'entities',
      },
      {
        id: 'add',
        type: SideMenuItemType.ITEM,
        label: 'add',
        isVisible: new BehaviorSubject<boolean>(true),
        link: `/${PATHS.ENTITIES}/${SUB_PATHS.ADD}`,
        icon: 'add_circle_outline',
        parentId: 'entities',
      },
    ],
  },
];
