import { Component, Input, OnInit } from '@angular/core';

export interface ActionButton {
  icon?: string;
  label?: string;
  class?: string;
  data?: any;
  callback: (data?) => any;
}

@Component({
  selector: 'core-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
})
export class ImageContainerComponent implements OnInit {
  @Input() data: any;

  imgSrc: string;
  constructor() {}

  ngOnInit() {
    this.imgSrc = this.data[this.data?.image];
  }
}
