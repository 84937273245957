import { LoginPlatforms } from '../enums';

export interface IWorkflowStepModel {
  order: number;
  value: number;
  name: string;
  nameTranslateKey: string;
  nextStepName: string;
  nextStepNameTranslateKey: string;
  parentValue: number;
  stepWithIncidents: boolean;
  stepWithIncidentsNotResolved: boolean;
  totalIncidents: number;
  totalIncidentsNotResolved: number;
  stepWithClaims: boolean;
  stepWithClaimsNotResolved: boolean;
  totalClaims: number;
  totalClaimsNotResolved: number;
  isCurrentStep: boolean;
  // TODO: ONLY FOR TASAFY, CARE WITH MODEL GENERATOR OF SWAGGER
  platforms: LoginPlatforms[];
}

export interface WorkflowstepItem {
  id?: number;
  topLabel?: string;
  bottomLabel?: string;
  isDone: boolean;
  withWarning: boolean;
  withErrors: boolean;
  isCurrentStep?: boolean;
  value?: string | number;
  isMobileStep?: boolean;
  icon?: string;
  iconClass?: string;
  iconTooltip?: string;
  averageTime?: string;
  callback?: (...args: any[]) => void;
}
