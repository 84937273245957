import { UsersService } from '@foxeet/data-access';
import { LicenseeUserRoles, UserIndexModel } from '@foxeet/domain';
import { dynamicInjector } from './dynamic-injector';
import { ObjectTS } from './javascript.functions';

export class LicenseeUsersUtils {
  static getMapLicenseeUserRoles(entitiesIds: number[], roles: LicenseeUserRoles[]) {
    return entitiesIds && roles.reduce((acc, curr) => ({ ...acc, [curr]: entitiesIds }), {});
  }

  static mergeLicensees(...licensees: { [key: number]: number[] }[]): { [key: number]: number[] } {
    const licenseeIds: { [key: number]: number[] } = {};
    licensees.forEach(
      (licensee) =>
        licensee &&
        ObjectTS.keys(licensee).forEach((key) => {
          licenseeIds[key] = licenseeIds[key] ? [...licenseeIds[key], ...licensee[key]] : licensee[key];
        }),
    );

    return licenseeIds;
  }

  static technicalManagerFunc = (filter: Record<string, string>) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter, licenseeUserRoleTypes: [LicenseeUserRoles.TechnicalManager] };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };

  static externalValidatorsFunc = (filter: Record<string, string>) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter, licenseeUserRoleTypes: [LicenseeUserRoles.ExternalTechnicalManager] };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };

  static processManagersFunc = (filter) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter, licenseeUserRoleTypes: [LicenseeUserRoles.ProcessManager] };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };

  static externalWorkersFunc = (filter) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter, licenseeUserRoleTypes: [LicenseeUserRoles.Appraiser] };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };

  static commercialManagersFunc = (filter) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter, licenseeUserRoleTypes: [LicenseeUserRoles.CommercialManager] };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };

  static allUsers = (filter) => {
    const usersService: UsersService = dynamicInjector().get(UsersService);
    const newFilter = { ...filter };
    return usersService.listAllByFilter<UserIndexModel[]>(newFilter);
  };
}
