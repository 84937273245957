<div class="d-flex align-items-center">
  <div
    class="full-width"
    [ngClass]="{
      'disabled-item': (authorityRoute | editableIfHasAuthorities) === false
    }"
  >
    <!-- You can use this as Diretive uiPlanBadge or as wrapper component with ng-content -->
    <ng-container *ngIf="template; else content">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </div>
  <span *ngIf="(authorityRoute | editableIfHasAuthorities) === false" class="badge">{{ label }}</span>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
