import { Injectable } from '@angular/core';
import { OrderRequestInvoiceManualModel } from '@foxeet/domain';
import { ApiBaseService2 } from '../services/api-base.service';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../services/cache.service';

// TODO: Review models Appraisal (?)
@Injectable()
export class RequestInvoicesService extends ApiBaseService2 {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'OrderRequestInvoices', cache);
  }

  public createInvoiceManually(data: OrderRequestInvoiceManualModel, file: File) {
    const formData = new FormData();
    formData.append(`InvoiceFile`, file);
    formData.append(`TotalWithoutTaxes`, `${data.totalWithoutTaxes}`);
    formData.append(`InvoiceDateTime`, data.invoiceDateTime.toISOString());
    formData.append(`InvoiceNumberId`, `${data.invoiceNumberId}`);
    formData.append(`OrderRequestId`, `${data.orderRequestId}`);
    data.appraisalIds.forEach((el, idx) => formData.append(`AppraisalIds[${idx}]`, `${el}`));
    return this.postMultipartFile('CreateInvoiceManually', formData); // TODO: Check what does /Create return
  }
}
